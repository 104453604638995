import { Record } from 'immutable'
import { ERROR } from '~/data/messages'
import { Location } from '~/features/locationSearch'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, messageCreator } from '~/utils/data'
import { formatAddress } from '~/utils/format'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot, patientCleared } from './common/shared'

const PATIENT_INFO = 'patientInfo'

export const PatientInfo = Record({
  id: null,
  caretaker1Email: null,
  caretaker1Name: null,
  caretaker1Phone: null,
  caretaker1Relationship: null,
  caretaker2Email: null,
  caretaker2Name: null,
  caretaker2Phone: null,
  caretaker2Relationship: null,
  caretaker3Email: null,
  caretaker3Name: null,
  caretaker3Phone: null,
  caretaker3Relationship: null,
  caseManagerId: null,
  caseManagerName: null,
  contactID: null,
  copay: null,
  cssName: null,
  currentLocationAddress: null,
  daysSinceLastAssessment: null,
  dob: null,
  ethnicity: null,
  gender: null,
  hasPreferredEmailOrPhone: null,
  healthPlanSpecificProgram: null,
  hpCareManagerEmail: null,
  hpCareManagerName: null,
  insurance: null,
  insuranceNumber: null,
  internalSubstatus: null,
  internetAccessAvailable: null,
  language: null,
  lastAssessmentDate: null,
  locationType: null,
  maritalStatus: null,
  market: null,
  mktHotline: null,
  mktPhone: null,
  mailingDate: null,
  mailingType: null,
  name: null,
  appId: null,
  appName: null,
  pcpPhone: null,
  phone: null,
  email: null,
  preferredVideoApplications: null,
  preferredVideoApplicationsOther: null,
  previousStatus: null,
  primaryCarePhysician: null,
  primaryChannel: null,
  primaryDiagnosis: null,
  programEnrolled: null,
  referralFacilitator: null,
  referralType: null,
  referringEntity: null,
  referringPhysician: null,
  secondaryDiagnosis: null,
  status: null,
  street: null,
  subStatus: null,
  videoDevicesAvailable: null,
  videoDevicesAvailableOther: null,
  virtualCapable: null,
  visitInstructions: null,
  location: Location(),
})

const transformPatientInfo = data => {
  const {
    patientID,
    npId,
    npName,
    street,
    apartment,
    city,
    state,
    statecode: stateCode,
    zip: postalCode,
    latitude,
    longitude,
    ...info
  } = data[0]

  const location = Location({
    street,
    apartment,
    city,
    state,
    stateCode,
    postalCode,
    latitude,
    longitude,
  })
  const address = formatAddress(location)

  return PatientInfo({
    ...info,
    id: patientID,
    appId: npId,
    appName: npName,
    location: location.set('address', address),
  })
}

export const fetchPatientInfo = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PATIENT_INFO',
  requestParams: ['patientId'],
  operation: (patientId, cancelToken) =>
    AspireAPI.get('v1/patientdemographics', {
      params: {
        patientIDs: patientId,
      },
      cancelToken,
    }),
  transform: transformPatientInfo,
  messages: {
    failed: 'There was an issue fetching patient info',
  },
})

export const savePatientAddress = Request({
  typePrefix: rootKey,
  typeBase: 'SAVE_PATIENT_ADDRESS',
  requestParams: ['patientId', 'location'],
  operation: (patientId, location) =>
    AspireAPI.put(`v1/patientdemographics/${patientId}`, {
      latitude: location.latitude,
      longitude: location.longitude,
      street: location.street,
      apartment: location.apartment,
      city: location.city,
      state: location.state,
      state_code: location.stateCode,
      zip: location.postalCode,
      exact: location.exact,
    }),
  messages: {
    succeeded: 'Patient address successfully saved',
    failed: "There was an issue saving this patient's address",
  },
})

export const differentTerritorySnackbarOpened = messageCreator(
  `${rootKey}/DIFFERENT_TERRITORY_SNACKBAR_OPENED`,
  "New address is outside assigned APP's territory - Contact supervisor before scheduling",
  ERROR
)

const initState = PatientInfo()
export default createReducer(PATIENT_INFO, initState, {
  [patientCleared]: () => initState,
  [fetchPatientInfo.REQUESTED]: () => initState,
  [fetchPatientInfo.SUCCEEDED]: (_state, { payload }) => payload,
  [savePatientAddress.SUCCEEDED]: (
    state,
    {
      meta: {
        request: { payload },
      },
    }
  ) => state.set('location', payload.location),
})

export const getPatientInfo = pipe(getRoot, get(PATIENT_INFO))
