import React from 'react'
import { useSelector } from 'react-redux'
import { Section } from '~/components/text'
import { Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getHealthplanAlerts } from '../../../data/healthplanAlerts'
import { getPatientAlerts } from '../../../data/patientAlerts'
import SchedulingAlerts from './SchedulingAlerts'

const getSuffix = day => {
  switch (day) {
    case 1:
    case 21:
    case 31:
      return 'st'
    case 2:
    case 22:
      return 'nd'
    case 3:
    case 23:
      return 'rd'
    default:
      return 'th'
  }
}

const getNeverVisitedBlurb = invoiceDay =>
  `Patient is not in compliance with the health plan contract. The patient must be seen by the ${invoiceDay}${getSuffix(
    invoiceDay
  )}.`
const getLastVisitedBlurb = invoiceDay =>
  `Patient must be seen by ${invoiceDay}${getSuffix(
    invoiceDay
  )} per health plan contract.`

const useStyles = makeStyles({
  root: {
    minHeight: 24,
  },
})

const Alerts = () => {
  const classes = useStyles()

  const patientAlerts = useSelector(getPatientAlerts)
  const healthplanAlerts = useSelector(getHealthplanAlerts)

  return (
    <React.Fragment>
      <Section title="Patient Alerts">
        {patientAlerts.count() > 0 ? (
          patientAlerts.map((alert, i) => (
            <React.Fragment key={`patientAlert-${i}`}>
              <Typography className={classes.root} variant="body2">
                {alert.description}
              </Typography>
              <Divider />
            </React.Fragment>
          ))
        ) : (
          <Typography variant="caption">No Patient Alerts</Typography>
        )}
      </Section>

      <Section title="Healthplan Alerts">
        {healthplanAlerts.count() > 0 ? (
          healthplanAlerts.map((alert, i) => (
            <React.Fragment key={`healthplanAlert-${i}`}>
              <Typography className={classes.root} variant="body2">
                {alert.previousVisited
                  ? getLastVisitedBlurb(alert.invoiceDay)
                  : getNeverVisitedBlurb(alert.invoiceDay)}
              </Typography>
              <Divider />
            </React.Fragment>
          ))
        ) : (
          <Typography variant="caption">No Healthplan Alerts</Typography>
        )}
      </Section>

      <SchedulingAlerts />
    </React.Fragment>
  )
}

export default Alerts
