import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'
import { TripValidate } from './interfaces'

// KEY
const key = 'ValidateTrip'
const ISVALIDTRIP = 'IsValidTrip'

// CREATORS
const creator = scopedCreator(key)

export const resetTripValidation = creator('RESET_TRIP_VALIDATION')
export const setValidTrip = creator('SET_VALID_TRIP')
export const setInvalidTrip = creator('SET_INVALID_TRIP')

export const TripValidateRec = Record({
  createTripOverrideAuthorized: null,
  distanceInRange: null,
  editTripOverrideAuthorized: null,
  patientCovered: null,
  benefitConfigured: null,
  tripMaxDistance: null,
})

// TRANSFORMER
const mapTripValidation = (result: TripValidate) =>
  TripValidateRec({
    createTripOverrideAuthorized: result?.createTripOverrideAuthorized,
    distanceInRange: result?.distanceInRange,
    editTripOverrideAuthorized: result?.editTripOverrideAuthorized,
    benefitConfigured: result?.benefitConfigured,
    patientCovered: result?.patientCovered,
    tripMaxDistance: result?.tripMaxDistance,
  })

// REQUEST
export const TripValidation = Request({
  typePrefix: key,
  typeBase: 'VALIDATETRIP',
  requestParams: ['validateTrip'],
  operation: (validateTrip: any) =>
    AspireAPI.post(`transportation/trips/validate_trip`, {
      ...validateTrip,
    }),
  transform: mapTripValidation,
  messages: { failed: 'Failed to Validate Trip' },
})

// REDUCER
export const validateTrip = createReducer(key, null, {
  [TripValidation.REQUESTED]: () => null,
  [TripValidation.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  [TripValidation.FAILED]: () => null,
  // @ts-expect-error can we deal with the toString being automatically called?
  [resetTripValidation]: () => null,
})

export const isValidTrip = createReducer(ISVALIDTRIP, false, {
  [TripValidation.REQUESTED]: () => false,
  [TripValidation.FAILED]: () => false,
  // @ts-expect-error can we deal with the toString being automatically called?
  [resetTripValidation]: () => false,
  // @ts-expect-error can we deal with the toString being automatically called?
  [setValidTrip]: () => true,
  // @ts-expect-error can we deal with the toString being automatically called?
  [setInvalidTrip]: () => false,
})
// SELECTORS
export const getTripValidation = pipe(getRoot, get(key))

export const getIsTripValid = pipe(getRoot, get(ISVALIDTRIP))
