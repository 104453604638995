import { entered } from 'redux-routable'
import { of } from 'rxjs'
import { filter, mergeMap } from 'rxjs/operators'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
// @ts-expect-error no export
import { marketsRequested } from '~/data/markets'
import { fetchHealthConcerns } from '../data/healthConcern'
import { fetchCarePlanGoals } from '../data/careplanGoals'
import { fetchPermissionSets } from '../data/permissionSets'
import { fetchPermissions } from '../data/permissions'
import { fetchRoles } from '../data/roles'
import router from '../router'

export default (action$: any) =>
  action$.pipe(
    filter(entered(router)),
    mergeMap(() =>
      of(
        fetchDistinctFieldValues([
          'time_zone',
          'user_role',
          'aspire_role',
          'app_graduation_type',
          'user_audit_reason',
        ]),
        fetchHealthConcerns.requested(),
        fetchCarePlanGoals.requested(),
        fetchPermissions.requested(),
        fetchPermissionSets.requested(),
        fetchRoles.requested(),
        marketsRequested()
      )
    )
  )
