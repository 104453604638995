const UNSUCCESSFUL_VISIT = 'Unsuccessful Visit'
const RESCHEDULE_PRIOR_TO_VISIT = 'Reschedule Prior To Visit'
const PATIENT_DRIVEN = 'Patient Driven'
const ASPIRE_DRIVEN = 'Aspire Driven'
const CAREGIVER_REFUSES_SERVICE = 'Caregiver Refuses Service'
const REFUSES_SERVICE_REQUESTS_DISCHARGE =
  'Refuses the Service - Requests Discharge'
const PT_HOME_DECLINED_VISIT = 'PT Home - Declined Visit'
const OTHER = 'Other'

const conditions = {
  alwaysTrue: () => true,
  alwaysFalse: () => false,

  unsuccessfulVisit: state => state.rescheduleType === UNSUCCESSFUL_VISIT,
  priorToVisit: state => state.rescheduleType === RESCHEDULE_PRIOR_TO_VISIT,

  patientDriven: state => state.rescheduledBy === PATIENT_DRIVEN,
  aspireDriven: state => state.rescheduledBy === ASPIRE_DRIVEN,

  unsuccessfulPatientDriven: state =>
    conditions.unsuccessfulVisit(state) && conditions.patientDriven(state),
  priorPatientDriven: state =>
    conditions.priorToVisit(state) && conditions.patientDriven(state),
  priorAspireDriven: state =>
    conditions.priorToVisit(state) && conditions.aspireDriven(state),

  unsuccessfulRefusal: state =>
    conditions.unsuccessfulPatientDriven(state) &&
    state.rescheduleReason === CAREGIVER_REFUSES_SERVICE,
  priorRefusal: state =>
    conditions.priorPatientDriven(state) &&
    state.rescheduleReason === REFUSES_SERVICE_REQUESTS_DISCHARGE,

  anyType: state =>
    conditions.unsuccessfulVisit(state) || conditions.priorToVisit(state),
  anyDriven: state =>
    conditions.unsuccessfulPatientDriven(state) ||
    conditions.priorAspireDriven(state) ||
    conditions.priorPatientDriven(state),
  anyRefusal: state =>
    conditions.priorRefusal(state) || conditions.unsuccessfulRefusal(state),
  defaultRefusal: state =>
    conditions.priorRefusal(state) || conditions.unsuccessfulRefusal(state),
  declinedVisitRefusal: state =>
    conditions.unsuccessfulPatientDriven(state) &&
    state.rescheduleReason === PT_HOME_DECLINED_VISIT,

  otherRescheduleReason: state =>
    conditions.anyDriven(state) && state.rescheduleReason === OTHER,
  otherRefusedServiceReason: state =>
    conditions.anyRefusal(state) && state.refusedServiceReason === OTHER,
}

export default conditions
