import { entered } from 'redux-routable'
import { of } from 'rxjs'
import { filter, map, mergeMap } from 'rxjs/operators'
import { fetchDemographicsOptions } from '~/data/demographicsOptions'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
import { fetchPatientCareTeam } from '~/features/careTeamManagement'
import { patientIdSelected } from '~/features/careTeamManagement/data/currentPatientId'
import { fetchLastLogCommunication } from '~/features/caremoreLogCommunication/data/lastLogCommunication'
import { fetchEpisodesOfCare } from '~/features/episodesOfCare/data'
import { fetchInsuranceCoverages } from '~/features/insuranceManagement'
import { fetchNextTargetedVisitDates } from '~/features/nextTargetedVisitDate'
import { fetchAlerts } from '~/features/patientAlerts'
import { fetchPatient } from '~/features/patientInfo'
import { switchTo } from '~/utils/operators'
import { fetchAccessAuthorization } from '../data/accessAuthorization'
import { getPatientId } from '../data/patientId'
import router from '../router'

export default (action$, state$) =>
  action$.pipe(
    filter(entered(router)),
    switchTo(state$),
    map(getPatientId),
    mergeMap(patientId =>
      of(
        fetchPatient.requested(patientId),
        fetchInsuranceCoverages.requested(patientId),
        fetchAlerts.requested(patientId),
        patientIdSelected(patientId),
        fetchAccessAuthorization.requested(patientId),
        fetchEpisodesOfCare.requested({ patientId }),
        fetchPatientCareTeam.requested(patientId),
        fetchNextTargetedVisitDates.requested(patientId),
        fetchLastLogCommunication.requested(patientId),
        fetchDemographicsOptions.requested(),
        fetchDistinctFieldValues([
          'are_there_any_drug_allergies',
          'aspire_program_enrolled',
          'care_preferences',
          'contact_current_location',
          'contact_location_type',
          'device_setup_offered',
          'device_setup_complete',
          'ethnicity',
          'gender',
          'gender_identity',
          'hospice_eligible',
          'hotspotter_plan',
          'internet_access_available',
          'language',
          'referral_mailing_type',
          'marital_status',
          'market',
          'patient_diagnosis',
          'patient_internal_substatus',
          'patient_status',
          'person_salutation',
          'physician_specialty',
          'primary_care_giver',
          'preferred_pronoun',
          'preferred_video_applications',
          'provider_prognosis',
          'referral_facilitator',
          'referral_source_type',
          'referred_patient_substatus',
          'reinstatement_reason',
          'salutation',
          'sex',
          'sexual_orientation',
          'title',
          'us_state',
          'video_apt_interest',
          'video_devices_available',
          'yes_no',
        ])
      )
    )
  )
