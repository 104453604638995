import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PATIENT_RECORD_DEMOGRAPHICS } from '~/apps/patientRecord/router'
import { OpenLink } from '~/components/links'
import { useAction, useMountEffect } from '~/hooks'
import { localToUtc, utcToLocal } from '~/utils/dates'
import { DATE_PICKER_DATETIME_FORMAT } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import {
  Button,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getTimeZone } from '../../../data/calendar'
import {
  deleteEvent,
  descriptionChanged,
  encounterTypeChanged,
  fetchPatientInfo,
  getEncounterTypes,
  getServiceLocations,
  newEventCreated,
  newEventOpened,
  patientInfoCleared,
  serviceLocationChanged,
  visitDurationChanged,
  visitStartChanged,
} from '../../../data/events'
import {
  getPatientInfo,
  getPatientVirtualCapableError,
} from '../../../data/events/patientInfo'
import { fetchServiceLocations } from '../../../data/events/serviceLocations'
import {
  BaseField,
  CreatedModifiedField,
  DateTimeField,
  DropDownField,
  LocationField,
  NumberField,
  PatientSearchField,
  TextField,
  TextLongField,
} from '../../EventFields'
import VisitDefaults from '../Visit/defaults'

const useStyles = makeStyles(({ spacing }) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  left: {
    display: 'flex',
    marginRight: spacing(1),
    justifyContent: 'space-between',
    width: '8%',
  },
}))

const VisitPlaceholderEventDetail = ({
  event,
  editing,
  creating,
  noMaxVisits,
  noVisitsRemaining,
}) => {
  const classes = useStyles()
  const timeZone = useSelector(getTimeZone)
  const encounterTypes = useSelector(getEncounterTypes)

  const changeEncounterType = useAction(encounterTypeChanged)
  const fetchPatientInfoRequested = useAction(fetchPatientInfo.requested)
  const onDescriptionChange = useAction(descriptionChanged)
  const onVisitStartChanged = useAction(visitStartChanged)
  const onVisitDurationChanged = useAction(visitDurationChanged)
  const openNewEvent = useAction(newEventOpened)
  const createNewEvent = useAction(newEventCreated)
  const deleteEventRequested = useAction(deleteEvent.requested)
  const clearPatientInfo = useAction(patientInfoCleared)
  const fetchServiceLocationsRequested = useAction(
    fetchServiceLocations.requested
  )
  const onServiceLocationChanged = useAction(serviceLocationChanged)
  const serviceLocations = useSelector(getServiceLocations)
  const patientInfo = useSelector(getPatientInfo)

  const duration = event.duration

  const defaultDuration = encounterTypes.getIn([
    event.encounterType,
    'defaultVisitDuration',
  ])

  useMountEffect(
    () => {
      if (event.patientId) {
        fetchPatientInfoRequested(event.patientId, event.ownerId)
        fetchServiceLocationsRequested(event.patientId)
      }
    },
    () => {
      clearPatientInfo()
    }
  )

  useEffect(() => {
    onDurationChange(duration)
  }, [duration])

  const start = utcToLocal(event.start, timeZone).format(
    DATE_PICKER_DATETIME_FORMAT
  )
  const end = utcToLocal(event.end, timeZone).format(
    DATE_PICKER_DATETIME_FORMAT
  )
  const isEditing = editing && !event.cancelled

  const [startDatetime, setStartDatetime] = useState(start)
  const [endDatetime, setEndDatetime] = useState(end)

  const onBlurStart = useCallback(
    localStart => {
      if (localStart) {
        const localEnd = moment(localStart).add(event.duration, 'minutes')
        const startNew = localToUtc(localStart, timeZone)
        const endNew = localToUtc(localEnd, timeZone)
        const startDate = moment(localStart).format('YYYY-MM-DD')
        const endDate = moment(localEnd).format('YYYY-MM-DD')

        setStartDatetime(localStart.format(DATE_PICKER_DATETIME_FORMAT))
        setEndDatetime(localEnd.format(DATE_PICKER_DATETIME_FORMAT))
        onVisitStartChanged(startNew, startDate, endNew, endDate)
      }
    },
    [event.duration, timeZone, onVisitStartChanged]
  )

  const onScheduleVisit = () => {
    // Delete placeholder
    deleteEventRequested(event.id)

    // Open Schedule dialog
    openNewEvent()
    createNewEvent({
      ...VisitDefaults,
      ownerId: event.ownerId,
      ownerName: event.ownerName,
      whatId: event.whatId,
      patientId: event.patientId,
      patientName: event.patientName,
      description: event.description,
      encounterType: event.encounterType,
      visitType: event.visitType,
      start: event.start,
      end: event.end,
      duration: event.duration,
      patientContactId: event.patientContactId,
      patientContactInfo: event.patientContactInfo,
      location: event.location,
    })
  }

  const onDurationChange = useCallback(
    duration => {
      const localEnd = moment(start).add(duration, 'minutes')
      const endNew = localToUtc(localEnd, timeZone)
      const endDate = localEnd.format('YYYY-MM-DD')

      setEndDatetime(localEnd.format(DATE_PICKER_DATETIME_FORMAT))
      onVisitDurationChanged(duration, endNew, endDate)
    },
    [start, timeZone, onVisitDurationChanged]
  )

  const durationDefault = defaultDuration ? (
    <Button
      onClick={() => {
        onDurationChange(defaultDuration)
      }}
    >{`Default: ${defaultDuration}`}</Button>
  ) : (
    'minutes'
  )
  const patientId = patientInfo.id

  return (
    <React.Fragment>
      {!creating && (
        <BaseField label="Schedule">
          <Button
            size="small"
            color="primary"
            variant="contained"
            disabled={!event.patientId || (!noMaxVisits && noVisitsRemaining)}
            onClick={onScheduleVisit}
          >
            Schedule APP Visit and Delete Placeholder
          </Button>
        </BaseField>
      )}
      <PatientSearchField event={event} editing={isEditing} />
      <BaseField label="Virtual Capable">
        <div className={classes.row}>
          <div className={classes.left}>
            <Typography variant="subtitle1">
              {patientInfo.virtualCapable ? 'Yes' : 'No'}
            </Typography>
            {!patientInfo.virtualCapable && (
              <Tooltip title={getPatientVirtualCapableError(patientInfo)}>
                <Icon color="error" fontSize="small">
                  info
                </Icon>
              </Tooltip>
            )}
          </div>
          <div>
            {!patientInfo.virtualCapable && (
              <Tooltip title="Update Virtual Capability Fields">
                <IconButton
                  size="small"
                  component={OpenLink}
                  route={PATIENT_RECORD_DEMOGRAPHICS}
                  params={{ patientId }}
                >
                  <Icon>link</Icon>
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
      </BaseField>
      <TextLongField
        label="Notes"
        value={event.description}
        onChange={onDescriptionChange}
        editing={isEditing}
      />
      {isEditing && creating ? (
        <DropDownField
          editing
          label="Encounter Type"
          error={!event.encounterType}
          value={event.encounterType}
          values={encounterTypes}
          getValue={encounterType => encounterType.type}
          getLabel={encounterType => encounterType.label}
          onChange={changeEncounterType}
          allowNone={false}
        />
      ) : (
        <TextField label="Encounter Type" value={event.encounterTypeLabel} />
      )}
      <NumberField
        label="Duration"
        value={event.duration}
        onChange={onDurationChange}
        editing={isEditing}
        after={durationDefault}
      />
      <DateTimeField
        label="Start"
        value={startDatetime}
        onBlur={onBlurStart}
        onChange={(v, _e) => setStartDatetime(v)}
        editing={isEditing}
      />
      <DateTimeField label="End" value={endDatetime} editing={false} />
      {patientInfo.isCaseMgmt ? (
        <DropDownField
          label="Service Location"
          values={serviceLocations}
          editing={isEditing}
          onChange={onServiceLocationChanged}
          error={!event.serviceLocationId}
          value={event.serviceLocationId}
          getValue={serviceLocation => serviceLocation.id}
          getLabel={serviceLocation => serviceLocation.name}
          allowNone={false}
        />
      ) : (
        <LocationField value={event.location} editing={isEditing} />
      )}
      <CreatedModifiedField event={event} />
    </React.Fragment>
  )
}

VisitPlaceholderEventDetail.propTypes = {
  event: PropTypes.record.isRequired,
  editing: PropTypes.bool,
  creating: PropTypes.bool,
  noMaxVisits: PropTypes.bool,
  noVisitsRemaining: PropTypes.bool,
}

export default VisitPlaceholderEventDetail
