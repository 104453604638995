// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getAdmissionsReconciliation } from './root'

// CONSTANTS
export const DISCARD_ODS_ADMISSION_ID_SET =
  'reconciliation/DISCARD_ODS_ADMISSION_ID_SET'
export const DISCARD_ODS_ADMISSION_ID_CLEARED =
  'reconciliation/DISCARD_ODS_ADMISSION_ID_CLEARED'
export const DISCARD_SAVED = 'reconciliation/DISCARD_SAVED'
export const DISCARD_SAVE_FAILED = 'reconciliation/DISCARD_SAVE_FAILED'

// ACTION CREATORS
export const discardOdsAdmissionIdSet = (odsAdmissionId: any) => ({
  type: DISCARD_ODS_ADMISSION_ID_SET,
  odsAdmissionId,
})
export const discardOdsAdmissionIdCleared = () => ({
  type: DISCARD_ODS_ADMISSION_ID_CLEARED,
})
export const discardSaved = () => ({ type: DISCARD_SAVED })
export const discardSaveFailed = () => ({ type: DISCARD_SAVE_FAILED })

// KEY
export const key = 'discardOdsAdmissionId'

// REDUCERS
export default createReducer(key, null, {
  // @ts-expect-error ts-migrate(6133) FIXME: 'state' is declared but its value is never read.
  [DISCARD_ODS_ADMISSION_ID_SET]: (state: any, { odsAdmissionId }: any) =>
    odsAdmissionId,
  [DISCARD_ODS_ADMISSION_ID_CLEARED]: () => null,
})

// SELECTORS
export const getDiscardOdsAdmissionId = (state: any) =>
  getAdmissionsReconciliation(state).get(key)
