import {
  CAREMORE_AT_HOME,
  COMMERCIAL_PALLIATIVE_CARE,
  C_SNP_AT_HOME,
  HBPC_HYBRID,
  HIGH_RISK_COMPLEX_CARE,
  HOME_BASED_PALLIATIVE_CARE,
  inTopFive,
  I_SNP,
  TELEPHONIC,
} from '~/utils/programInfo'

export const programEnrolledToNTVDRole = (
  programEnrolled: string
): string[] => {
  switch (true) {
    case programEnrolled == TELEPHONIC:
      return ['pcc_sw']
    case programEnrolled == COMMERCIAL_PALLIATIVE_CARE:
      return ['app', 'pcc_rn', 'pcc_sw']
    case programEnrolled == HIGH_RISK_COMPLEX_CARE:
      return ['app', 'pcc_rn']
    case programEnrolled == HOME_BASED_PALLIATIVE_CARE:
      return ['app', 'chw']
    case inTopFive(programEnrolled):
      return ['app', 'pcc_rn', 'chw', 'pcc_sw']
    case programEnrolled == HBPC_HYBRID:
      return ['app', 'pcc_sw', 'chw']
    case programEnrolled == CAREMORE_AT_HOME:
    case programEnrolled == C_SNP_AT_HOME:
    case programEnrolled == I_SNP:
      return [
        'app',
        'cd',
        'field_sw',
        'hospice_nss',
        'md',
        'nss',
        'bh_sw',
        'bh_apc',
      ]
    default:
      return []
  }
}
