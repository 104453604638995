import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { ActionDialog } from '~/components/dialogs'
import { AddNonPhysician, AddPhysician } from '~/features/contacts'
import { values as reportTypes } from '~/fieldValues/stakeholder_report_type'
import { formatAddress } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { addStakeholder } from '../../data/stakeholders'
import {
  suggestedStakeholderSearchWordChanged,
  suggestedStakeholdersCleared,
} from '../../data/suggestedStakeholders'
import StakeholderGrid from './StakeholderGrid'

const styles = ({ spacing }) => ({
  searchContainer: {
    display: 'flex',
    marginBottom: spacing(1),
  },
  textField: {
    flex: 1,
    marginRight: spacing(2),
  },
  radioGroup: {
    display: 'inline-block',
  },
  stakeholderContainer: {
    display: 'flex',
    marginTop: spacing(1),
  },
  flexContainer: {
    display: 'flex',
  },
  flex: {
    flex: 1,
  },
  reportTypes: {
    width: 400,
  },
  divider: {
    margin: [spacing(1), 0],
  },
})

class AddStakeholder extends React.PureComponent {
  state = {
    addPhysicianOpen: false,
    addNonPhysicianOpen: false,
    textFieldValue: '',
    radioValue: 'physician',
    selectedStakeholder: null,
    selectedReportTypes: [],
  }

  onChangeRadio = event => {
    const { textFieldValue } = this.state
    const {
      suggestedStakeholderSearchWordChanged,
      suggestedStakeholdersCleared,
    } = this.props
    const newRadioValue = event.target.value

    if (textFieldValue) {
      suggestedStakeholderSearchWordChanged(textFieldValue, newRadioValue)
    }

    suggestedStakeholdersCleared()
    this.setState({ radioValue: newRadioValue })
  }

  onFetchSuggestedStakeholders = event => {
    const {
      suggestedStakeholderSearchWordChanged,
      suggestedStakeholdersCleared,
    } = this.props
    const newTextFieldValue = event.target.value

    if (newTextFieldValue && newTextFieldValue.trim()) {
      suggestedStakeholderSearchWordChanged(
        newTextFieldValue,
        this.state.radioValue
      )
    } else {
      suggestedStakeholdersCleared()
    }

    this.setState({ textFieldValue: newTextFieldValue })
  }

  onSetStakeholder = selectedStakeholder => {
    this.setState({ selectedStakeholder, selectedReportTypes: ['MD Report'] })
  }

  onSetReportTypes = event => {
    this.setState({ selectedReportTypes: event.target.value })
  }

  onAdd = () => {
    const { selectedStakeholder, selectedReportTypes } = this.state
    const { patientId, addStakeholder } = this.props

    addStakeholder(patientId, selectedStakeholder.id, selectedReportTypes)

    this.onClose()
  }

  onClose = () => {
    const { suggestedStakeholdersCleared, onClose } = this.props

    this.setState({
      textFieldValue: '',
      radioValue: 'physician',
      selectedStakeholder: null,
      selectedReportTypes: [],
    })
    suggestedStakeholdersCleared()
    onClose()
  }

  onOpenAddPhysician = () => this.setState({ addPhysicianOpen: true })
  onCloseAddPhysician = () => this.setState({ addPhysicianOpen: false })

  onOpenAddNonPhysician = () => this.setState({ addNonPhysicianOpen: true })
  onCloseAddNonPhysician = () => this.setState({ addNonPhysicianOpen: false })

  renderTitle = () => {
    const { classes } = this.props
    const { radioValue } = this.state

    return (
      <div className={classes.flexContainer}>
        <Typography variant="h6" className={classes.flex}>
          Add Stakeholder
        </Typography>

        {radioValue === 'physician' ? (
          <Button variant="outlined" onClick={this.onOpenAddPhysician}>
            Add New Physician
          </Button>
        ) : (
          <Button variant="outlined" onClick={this.onOpenAddNonPhysician}>
            Add New Non Physician
          </Button>
        )}
      </div>
    )
  }

  render() {
    const {
      addPhysicianOpen,
      addNonPhysicianOpen,
      textFieldValue,
      radioValue,
      selectedStakeholder,
      selectedReportTypes,
    } = this.state
    const { classes, open } = this.props

    return (
      <ActionDialog
        open={open}
        title={this.renderTitle()}
        mode="add"
        onAdd={this.onAdd}
        onClose={this.onClose}
        disableAction={!selectedStakeholder || selectedReportTypes.length === 0}
        maxWidth="lg"
        fullWidth
      >
        <div className={classes.searchContainer}>
          <TextField
            className={classes.textField}
            label="Search Stakeholders"
            value={textFieldValue}
            onChange={this.onFetchSuggestedStakeholders}
          />

          <RadioGroup
            className={classes.radioGroup}
            value={radioValue}
            onChange={this.onChangeRadio}
          >
            <FormControlLabel
              value="physician"
              control={<Radio />}
              label="Physician"
            />
            <FormControlLabel
              value="non_physician"
              control={<Radio />}
              label="Non-Physician"
            />
          </RadioGroup>
        </div>

        <Divider className={classes.divider} />

        {selectedStakeholder && (
          <div className={classes.stakeholderContainer}>
            <div className={classes.flex}>
              <Typography variant="subtitle1" color="primary">
                {selectedStakeholder.practiceName}
              </Typography>
              <div className={classes.flexContainer}>
                <Typography
                  className={classes.flex}
                  variant="subtitle1"
                  color="primary"
                >
                  {selectedStakeholder.title
                    ? `${selectedStakeholder.name} - ${selectedStakeholder.title}`
                    : selectedStakeholder.name}
                </Typography>
                <Typography
                  className={classes.flex}
                  variant="subtitle1"
                  color="primary"
                >
                  {selectedStakeholder.phone}
                </Typography>
              </div>
              <Typography variant="subtitle1" color="primary">
                {formatAddress(selectedStakeholder)}
              </Typography>
              {selectedStakeholder.physicianNotes && (
                <Typography variant="subtitle1" color="primary">
                  Notes: {selectedStakeholder.physicianNotes}
                </Typography>
              )}
            </div>
            <div>
              <TextField
                select
                className={classes.reportTypes}
                label="Report Types"
                SelectProps={{
                  multiple: true,
                  onChange: this.onSetReportTypes,
                  value: selectedReportTypes,
                }}
              >
                {reportTypes.map(({ value, label }) => (
                  <MenuItem key={value} value={label}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        )}

        <StakeholderGrid onSelect={this.onSetStakeholder} />

        <AddPhysician
          open={addPhysicianOpen}
          onClose={this.onCloseAddPhysician}
        />

        <AddNonPhysician
          open={addNonPhysicianOpen}
          onClose={this.onCloseAddNonPhysician}
        />
      </ActionDialog>
    )
  }
}

AddStakeholder.propTypes = {
  classes: PropTypes.object.isRequired,
  patientId: PropTypes.string.isRequired,
  reportTypes: PropTypes.list,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  suggestedStakeholderSearchWordChanged: PropTypes.func.isRequired,
  suggestedStakeholdersCleared: PropTypes.func.isRequired,
  addStakeholder: PropTypes.func.isRequired,
}

export default compose(
  connect(null, {
    suggestedStakeholderSearchWordChanged,
    suggestedStakeholdersCleared,
    addStakeholder: addStakeholder.requested,
  }),
  withStyles(styles)
)(AddStakeholder)
