export const DISCHARGE_REASONS = [
  'Alternative HP Program Candidate',
  'Annual Assessment Completed',
  'Aspire Assessed - Patient Too Healthy',
  'Deceased',
  'Extended Length of Stay - Hospital',
  'Extended Length of Stay - Skilled Nursing Facility',
  'Financial Considerations',
  'Home Safety Risk',
  'Hospice',
  'Ineligible Insurance',
  'Long Term Care (LTC) - Custodial Care',
  'Out Of Service Area',
  'Outreach Cycle Complete',
  'PCP Declined - Other',
  'PCP Declined - Patient too healthy',
  'Patient / Family Declined - Apprehension or Skepticism',
  'Patient / Family Declined - Claims to be too healthy',
  'Patient / Family Declined - Patient Unavailable for Visit',
  'Patient / Family Declined - Satisfied with Current Care',
  'Patient / Family Declined - Too Many Providers',
  'Similar Health Plan Program',
  'Transition to Another Aspire Internal Program',
  'Unable to Contact - No Answer',
  'Unable to Contact - Wrong/Disconnected Number',
]

export const RECOMMEND_DISCHARGE_SPECIFIC_REASONS = [
  'Patient / Family Declined - Safety Concerns',
  'Temporary Geographic Hold',
  'Virtual Care',
]

export const TOP_5_DISCHARGE_REASONS = [
  'Deceased',
  'Hospice',
  'Ineligible Insurance',
]

export const REASONS_WITH_POTENTIAL = [
  'Patient / Family Declined - Apprehension or Skepticism',
  'Patient / Family Declined - Patient Unavailable for Visit',
  'Patient / Family Declined - Satisfied with Current Care',
  'Patient / Family Declined - Too Many Providers',
  'PCP Declined - Other',
  'PCP Declined - Patient too healthy',
  'Similar Health Plan Program',
]

export const REASON_FOR_CHOOSING_HOSPICE = [
  'Patient/Family Preference',
  'Physician Preference',
  'Facility Preference',
  'Continuation of Care',
  "Outside Other Provider's Service Area",
  'Aspire Preference',
]

export const DISCHARGE_DECISION_OPTIONS = ['Keep on Service', 'Discharge']
export const KEEP_ON_SERVICE = ['Keep on Service']
