import { Record } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import rootKey from '../key'
import { addContactDialogClosed } from './patientContactDialog'

const key = 'PatientContactData'

const creator = scopedCreator(key)
export const selectedContact: any = creator('SELECTED_CONTACT')

export const contact = Record({
  id: null,
  patientId: '',
  type: '',
  name: '',
  relationship: '',
  telehealthOsUserId: '',
  notes: '',
  preferred: null,
  decisionMaker: null,
  scheduling: null,
  phones: null,
  emails: null,
  addresses: null,
  active: null,
  emailAsked: null,
  delete: null,
  modifiedById: '',
  modifiedAt: '',
  createdById: '',
  createdAt: '',
})
export const currentContact = createReducer(key, contact, {
  [selectedContact]: (_state: any, { payload }: any) => payload,
  [addContactDialogClosed]: () => contact,
})

export const getCurrentContact = getIn([rootKey, key])
