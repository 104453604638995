import { List, Map, Record } from 'immutable'
import { get as getIn, isEmpty } from 'lodash'
import moment from 'moment'
import { createSelector } from 'reselect'
import { calcFormData } from '~/components/JsonForm'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into, payload, scopedCreator } from '~/utils/data'
import { compose } from '~/utils/functionalHelpers'
import { CANCELLED, COMPLETED, NEW, STARTED } from '../constants'
import TASKS from '../key'
import { roleMapping } from '../utils/role'
import { determineStatus, newOrInProgress } from '../utils/status'
import { getRoot } from './common'
import { createNewTask } from './createNewTask'
import { getToggles } from './toggles'
import { getUserId } from './userId'

// Key
const key = 'tasks'
const typePrefix = `${TASKS}/${key}`
const creator = scopedCreator(typePrefix)

// Actions
export const taskCollapsed = creator('TASK_COLLAPSED', ['id', 'type'])
export const taskExpanded = creator('TASK_EXPANDED', ['id', 'type'])

// Update User Action Form
export const taskFormUpdated = creator('TASK_FORM_UPDATED', [
  'id',
  'formData',
  'errored',
])

export const taskFormErrored = creator('TASK_FORM_ERRORED', ['id'])

export const taskSaved = creator('TASK_SAVED', ['id'])
export const taskSubmitted = creator('TASK_SUBMITTED', ['id'])
export const taskUpdated = creator('TASK_UPDATED', ['task'])

export const careTeamMemberTasksFetchRequested = creator([
  'CARE_TEAM_MEMBER_TASKS_FETCH_REQUESTED',
])

export const ownerTasksFetchRequested = creator('OWNER_TASKS_FETCH_REQUESTED', [
  'userId',
])

// Record
const TaskHistory = Record({
  dueOn: null,
  modifiedAt: null,
  modifiedById: null,
  modifiedByName: null,
  ownerId: null,
  ownerName: 'Unknown',
  ownerRole: 'Employee',
  status: null,
})

// Record
export const Form = Record({
  id: null,
  context: {},
  errored: false,
  saved: false,
  formData: {},
  schema: {},
  tags: {},
  uiSchema: {},
  type: null,
})

// Record
export const User = Record({
  id: null,
  name: null,
  role: null,
})

export const Patient = Record({
  id: null,
  name: null,
  marketName: null,
  patientScore: null,
})

export const Task = Record({
  cancellationReason: null,
  cancellationReasonOther: null,
  cancelledAt: null,
  careTeamMemberId: null,
  completedOn: null,
  context: {},
  createdAt: null,
  description: null,
  dueOn: null,
  healthplanLabel: null,
  id: null,
  modifiedAt: null,
  ownerRole: null,
  priority: null,
  program: null,
  startOn: null,
  status: null,
  taskDefinitionKey: null,
  title: null,
  history: List(),

  // Associations
  completedBy: null,
  completedUser: User(),

  cancelledBy: null,
  cancelledUser: User(),
  formId: null,
  form: null,

  modifiedBy: null,
  modifiedUser: User(),

  ownerId: null,
  ownerUser: User(),

  patientId: null,
  patient: Patient(),

  requestedBy: null,
  requestedUser: User(),
})

// Requests
export const cancelTask = Request({
  typePrefix,
  typeBase: 'CANCEL_TASK',
  requestParams: ['id'],
  operation: id =>
    AspireAPI.put(`/tasks/${id}/`, {
      status: CANCELLED,
    }),
  messages: {
    failed: 'Could not cancel task.',
    succeeded: 'Task cancelled.',
  },
})

export const reassignTask = Request({
  typePrefix,
  typeBase: 'REASSIGN_TASK',
  requestParams: ['id', 'ownerId', 'ownerRole'],
  operation: (id, ownerId, ownerRole) =>
    AspireAPI.put(`/tasks/${id}/`, {
      ownerId,
      ownerRole,
    }),
  messages: {
    failed: 'Could not reassign task.',
    succeeded: 'Task reassigned.',
  },
})

export const snoozeTask = Request({
  typePrefix,
  typeBase: 'SNOOZE_TASK',
  requestParams: ['id', 'dueOn'],
  operation: (id, dueOn) => AspireAPI.put(`tasks/${id}`, { dueOn }),
  messages: { failed: 'Could not snooze task.', succeeded: 'Task snoozed.' },
})

export const completeTask = Request({
  typePrefix,
  typeBase: 'COMPLETE_TASK',
  requestParams: ['data'],
  operation: ({ id, ...data }) => AspireAPI.put(`tasks/${id}/complete`, data),
  messages: {
    failed: e =>
      Object.values(e.response.data.message || {}).join('') ||
      'Could not complete task.',
    succeeded: 'Task completed.',
  },
})

// THERE ARE LINKS HARD CODED IN THE DATABASE WITH A REFERENCE TO TASKS
// WITH THE ID PREFIXED BY UA - THESE SHOULD NOW BE DISREGARDED
const removePrefix = id => {
  if (Number.isInteger(id)) return id
  return id.replace('UA', '')
}

export const fetchTask = Request({
  typePrefix,
  typeBase: 'FETCH_TASK',
  requestParams: ['id'],
  operation: id => AspireAPI.get(`tasks/${removePrefix(id)}`),
  messages: { failed: 'Could not fetch task.' },
})

export const fetchTasks = Request({
  typePrefix,
  typeBase: 'FETCH_TASKS',
  requestParams: ['params'],
  operation: params => AspireAPI.get('tasks', { params: params }),
  messages: { failed: 'Could not fetch tasks.' },
})

export const createTaskForm = Request({
  typePrefix,
  typeBase: 'CREATE_TASK_FORM',
  requestParams: ['id'],
  operation: id => AspireAPI.get(`tasks/${id}/form`),
  messages: { failed: 'Could not create task form' },
})

export const saveTask = Request({
  typePrefix,
  typeBase: 'SAVE_TASK',
  requestParams: ['data'],
  operation: ({ id, form }) =>
    AspireAPI.put(`tasks/${id}`, { formId: form.id, formData: form.formData }),
  messages: {
    failed: 'Could not save task.',
    succeeded: 'Task saved.',
  },
})

// PERFORM ACTION FROM TASK FORM
const createOperation = action => (formData, formContext) =>
  AspireAPI.post(
    `tasks/${getIn(formContext, ['taskDetails', 'id'])}/${action}`,
    { params: { ...formData } }
  )

export const logCallFromTask = Request({
  typePrefix: 'formAction',
  typeBase: 'LOG_CALL',
  requestParams: ['params', 'formContext'],
  operation: createOperation('log_call'),
  messages: {
    failed: e => getIn(e, 'response.data.message', 'Could not save call'),
  },
})

export const sendNotificationFromTask = Request({
  typePrefix: 'formAction',
  typeBase: 'SEND_NOTIFCATION',
  requestParams: ['params', 'formContext'],
  operation: createOperation('send_notification'),
  messages: { failed: 'Failed to send user notification' },
})

export const saveNoteFromTask = Request({
  typePrefix: 'formAction',
  typeBase: 'SAVE_NOTE',
  requestParams: ['params', 'formContext'],
  operation: createOperation('save_note'),
  messages: { failed: 'Failed to save task note' },
})

// Reducer
// Transform object into array of arrays and reject pairs with null values to preserve Task defaults
const transformHistory = history =>
  Array.isArray(history)
    ? List(
        history.map(h =>
          TaskHistory({
            dueOn: h.dueOn,
            modifiedAt: h.modifiedAt,
            modifiedById: getIn(h, ['modifiedUser', 'id']),
            modifiedByName: getIn(h, ['modifiedUser', 'name']),
            ownerId: getIn(h, ['ownerUser', 'id']),
            ownerName: getIn(h, ['ownerUser', 'name']),
            ownerRole: roleMapping(getIn(h, ['ownerUser', 'role'])),
            status: determineStatus(h.status),
          })
        )
      )
    : List()

const filterNullValues = obj =>
  Object.entries(obj).filter(([_key, value]) => value !== null)

const transform = ({
  completedUser,
  cancelledUser,
  modifiedUser,
  ownerUser,
  form,
  patient,
  requestedUser,
  ...task
}) => ({
  ...task,
  history: transformHistory(task.history),
  completedUser: User(completedUser),
  cancelledUser: User(cancelledUser),
  modifiedUser: User(modifiedUser),
  ownerUser: User(ownerUser),
  requestedUser: User(requestedUser),
  program: getIn(patient, ['aspire', 'program_enrolled']),
  patient: Patient({
    ...patient,
    name: getIn(patient, ['demographics', 'name']),
    marketName: getIn(patient, ['market', 'name']),
    patientScore: getIn(patient, ['score', 'score']),
  }),
  ...(!isEmpty(form) && {
    form: Form({
      ...form,
      formData: calcFormData({
        ...form,
        formData: form.data,
      }),
    }),
  }),
})

const createTask = compose(Task, filterNullValues, transform)

const setTask = (state, action) =>
  compose(
    t =>
      state.update(t.id, Task(), preVal =>
        t.set('careTeamMemberId', preVal.careTeamMemberId)
      ),
    createTask,
    payload
  )(action)

const setTasks = (state, action) =>
  compose(
    t =>
      state.mergeDeepWith(
        (oldVal, newVal) => (newVal === null ? oldVal : newVal),
        t
      ),
    into(createTask, 'id'),
    payload
  )(action)

// Using .sets instead of merge because of a bug/issue
// with formData becoming nested
const update = (state, { payload: { errored, formData, id } }) =>
  state.setIn([id, 'status'], STARTED).updateIn([id, 'form'], f =>
    f
      .set('errored', errored)
      .set(
        'formData',
        calcFormData({
          context: f.context,
          formData,
          schema: f.schema,
          tags: f.tags,
        })
      )
      .set('saved', false)
  )

// Optimistically set `saved` to `true`
const setSaved = (state, action) =>
  state.setIn([action.payload.data.id, 'form', 'saved'], true)

// Pessimistically reset
const resetSaved = (state, action) =>
  state.setIn([action.meta.request.payload.data.id, 'form', 'saved'], false)

// Optimistically set `status` to 'Completed'
const complete = (state, { payload: { id } }) =>
  state.setIn([id, 'status'], COMPLETED)

// Pessimistically reset
const revertToInProgress = (state, action) =>
  state.setIn([action.meta.request.payload.data.id, 'status'], STARTED)

export default createReducer(key, Map(), {
  [completeTask.FAILED]: revertToInProgress,
  [fetchTask.SUCCEEDED]: setTask,
  [fetchTasks.SUCCEEDED]: setTasks,
  [createTaskForm.SUCCEEDED]: setTask,
  [createNewTask.SUCCEEDED]: setTask,
  [saveTask.FAILED]: resetSaved,
  [saveTask.REQUESTED]: setSaved,
  [taskFormUpdated]: update,
  [taskFormErrored]: (state, { payload: { id } }) =>
    state.setIn([id, 'form', 'errored'], true),
  [taskSubmitted]: complete,
})

// Selector
const toArray = map => map.toArray()

const sortByStatus = state => state.toIndexedSeq().sortBy(newOrInProgress)

const filterByAssigned = (tasks, userId) =>
  tasks.filter(task => task.ownerId == userId)

const selectorCreator = filter =>
  createSelector([getTasksByToggle, getUserId], (tasks, userId) =>
    compose(toArray, sortByStatus, tasks => tasks.filter(filter(userId)))(tasks)
  )

const getTasks = compose(get(key), getRoot)

export const getTaskById = id => compose(get(removePrefix(id)), getTasks)

const getTasksByToggle = state => {
  const allTasks = getTasks(state)
  const toggleState = getToggles(state)
  return allTasks.filter(t => {
    const taskStatus = t.status.toLowerCase()
    const isStatusOnToggleState = toggleState.has(taskStatus)
    return isStatusOnToggleState ? toggleState[taskStatus] : true
  })
}

export const getPatientTasksAsArray = createSelector(
  [getTasks],
  compose(toArray, sortByStatus)
)

export const getRequestedTasksAsArray = selectorCreator(userId => task =>
  task.requestedBy === userId
)

export const getCareTeamMemberPatientTasksAsArray = selectorCreator(
  userId => task => task.careTeamMemberId === userId
)

export const getAssignedTasksAsArray = selectorCreator(userId => task =>
  task.ownerId === userId
)

export const getAssignedTasksAsList = createSelector(
  [getTasks, getUserId],
  compose(tasks => tasks.toList(), sortByStatus, filterByAssigned)
)

// Count only 'New' Tasks for Navigation badge
const countNewTasks = tasks => tasks.count(task => task.status === NEW)

export const getNewTasksCount = createSelector(
  [getTasks, getUserId],
  compose(countNewTasks, filterByAssigned)
)

// Count only 'Due' Tasks for Navigation badge
export const filterDueTasks = task =>
  ![COMPLETED, CANCELLED].includes(task.status) &&
  moment().isSameOrAfter(moment.utc(task.dueOn).local(), 'day')

const countDueTasks = tasks => tasks.count(filterDueTasks)

export const getDueTasksCount = createSelector(
  [getTasks, getUserId],
  compose(countDueTasks, filterByAssigned)
)
