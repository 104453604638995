import { List, fromJS } from 'immutable'
import { isEmpty } from 'lodash'
import { createStructuredSelector } from 'reselect'
import { getTaggedValue } from '~/components/JsonForm'
import { getFieldValues } from '~/data/fieldValues'
import { areRequestsPending } from '~/data/pending'
import { getRoute } from '~/data/route'
import { getUserId } from '~/data/session'
import { getPatientLastEncounterId } from '~/features/patientInfo/data/patient'
import { formatName } from '~/utils/format'
import { ASSESSMENT_FORM, ASSESSMENT_LEGACY } from '../../router'
import {
  cancelAssessment,
  createGetSection,
  getAssessmentAddendumsById,
  getAssessmentById,
  getAssessmentSectionsById,
  getEncounterReviewFormDataById,
  signAssessment,
} from '../assessments'
import { getCurrentAssessmentId } from '../currentAssessmentId'
import { OPEN_STATUSES, SIGNED_STATUSES } from './shared'

export const panelAvailable = state =>
  [ASSESSMENT_FORM, ASSESSMENT_LEGACY].includes(getRoute(state))

export const getCurrentAssessment = state =>
  getAssessmentById(state, getCurrentAssessmentId(state))

export const getCurrentAssessmentSections = state =>
  getAssessmentSectionsById(state, getCurrentAssessmentId(state))

export const getCurrentAssessmentAddendums = state =>
  getAssessmentAddendumsById(state, getCurrentAssessmentId(state))

export const getCurrentAssessmentEncounterReviewFormData = state =>
  getEncounterReviewFormDataById(state, getCurrentAssessmentId(state))

const isBusy = state =>
  areRequestsPending(state, [signAssessment, cancelAssessment])

const currentAssessmentSelector = (defaultVal, selector) => (
  state,
  ...rest
) => {
  const assessment = getCurrentAssessment(state)
  return assessment ? selector(assessment, ...rest, state) : defaultVal
}

const canModifySelector = statuses =>
  currentAssessmentSelector(false, ({ status, providerId }, state) => {
    const open = statuses.includes(status)
    const isProvider = getUserId(state) === providerId
    const busy = isBusy(state)

    return open && isProvider && !busy
  })

export const canEdit = canModifySelector(OPEN_STATUSES)

export const canAddAddendum = canModifySelector(SIGNED_STATUSES)

const cancellableAssessmentTypes = [
  'home_based_social_work_follow_up',
  'sw_care_management',
  'rn',
  'caremore_sw_care_management',
  'caremore_chw_check_in',
  'caremore_cm_toc',
  'caremore_toc_questionnaire',
  'caremore_er_questionnaire',
  'caremore_hra',
  'caremore_complex_nurse_assessment',
]

export const canCancel = currentAssessmentSelector(
  false,
  ({ type }) =>
    Boolean(type) && cancellableAssessmentTypes.some(i => type.includes(i))
)

export const canDownload = currentAssessmentSelector(false, ({ status }) =>
  SIGNED_STATUSES.includes(status)
)

export const hasAddendums = currentAssessmentSelector(
  false,
  ({ addendums }) => !(fromJS(addendums) || List()).isEmpty()
)

export const areAddendumsVisible = state =>
  hasAddendums(state) || canAddAddendum(state)

export const hasEncounterReview = currentAssessmentSelector(
  false,
  ({ encounterReviewFormData }) => !isEmpty(encounterReviewFormData)
)

export const getCancellationReasons = getFieldValues(
  'assessment_cancellation_reason'
)

export const getCurrentAssessmentFormDataByTag = currentAssessmentSelector(
  undefined,
  (a, tag, options) => getTaggedValue(a, tag, options)
)

export const getFormTitleInfo = currentAssessmentSelector(
  {},
  createStructuredSelector({
    status: a => a.status,
    dateOfService: a => a.dateOfService,
    providerName: a => a.providerName,
    typeLabel: a => a.typeLabel,
  })
)

export const getContext = currentAssessmentSelector(null, a => a.context)

export const getEnsurePhysicianReviewAvailable = currentAssessmentSelector(
  'nothing',
  a => a.ensurePhysicianReviewAvailable
)

export const getAssessmentPatientName = currentAssessmentSelector({}, a =>
  formatName({
    salutation: getTaggedValue(a, 'salutation'),
    firstName: getTaggedValue(a, 'firstName'),
    middleName: getTaggedValue(a, 'middleName'),
    lastName: getTaggedValue(a, 'lastName'),
    suffix: getTaggedValue(a, 'suffix'),
  })
)

export const createGetCurrentAssessmentSection = () =>
  currentAssessmentSelector(undefined, createGetSection())

export const getPreviousAssessment = state => {
  const id = getPatientLastEncounterId(state)
  return getAssessmentById(state, id)
}
