import { combineReducers } from 'redux-immutable'
import carePlan from './data/carePlan'
import caseManagerId from './data/caseManagerId'
import census from './data/census'
import { interventions } from './data/intervention'
import key from './key'

const reducer = combineReducers({
  [carePlan.key]: carePlan,
  [caseManagerId.key]: caseManagerId,
  [census.key]: census,
  [interventions.key]: interventions,
})

reducer.key = key

export default reducer
