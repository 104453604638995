export const ADD_TO_MY_LIST = 'add_to_my_list'
export const ADMIN_TOOLS = 'admin_tools'
export const ADMIN_TOOLS_PERMISSIONS = 'admin_tools:permissions'
export const ADMIN_TOOLS_USERS = 'admin_tools:users'
export const ADMIN_TOOLS_USERS_ASPIRE = 'admin_tools:users_aspire'
export const ADMIN_TOOLS_USERS_CLINICIAN = 'admin_tools:users_clinician'
export const ADMIN_TOOLS_USERS_CONTRACTS = 'admin_tools:users_contracts'
export const ADMIN_TOOLS_USERS_GENERAL = 'admin_tools:users_general'
export const ADMIN_TOOLS_ICD10_PROBLEM_TYPES = 'admin_tools:icd10_problem_type'
export const ADMIN_TOOLS_USERS_PERMISSIONS = 'admin_tools:users_permissions'
export const ADMISSIONS = 'admissions'
export const APP_DASH = 'app_dash'
export const APP_DASH_CANCEL_VISIT = 'app_dash:cancel_visit'
export const APP_DASH_VIEW_ALL_MARKETS = 'app_dash:view_all'
export const APP_DASH_VIEW_AS = 'app_dash:view_as'
export const APP_DASH_VIEW_AS_APP = 'app_dash:view_as_app'
export const APP_DASH_VIEW_AS_MD = 'app_dash:view_as_md'
export const APP_DASH_VIEW_OTHER_METRICS = 'app_dash:view_as_metrics'
export const ASSESSMENT = 'assessment'
export const ASSESSMENT_MANUAL_CREATION = 'assessment_manual_creation' // Used for manual creation on patient record. Meant for testing
export const CARE_CENTER_DASH = 'care_center_dash'
export const CASE_MANAGER_DASHBOARD = 'case_manager_dash'
export const CD_DASH = 'cd_dash'
export const CD_DASH_VIEW_AS = 'cd_dash:view_as'
export const CMRN_CALL = 'cmrn_call'
export const CODER_DASH = 'encounter_coding:dashboard'
export const COMMUNITY_HW_DASH = 'community_hw_dashboard'
export const COMMUNITY_HW_DASH_VIEW_AS = 'community_hw_dashboard:view_as'
export const CONTACT_MANAGEMENT = 'contact_management'
export const CSS_DASHBOARD = 'css_dashboard'
export const CSS_DASHBOARD_APP_SCHEDULING = 'css_dashboard:app_scheduling'
export const DISCHARGE = 'discharge'
export const DISCHARGE_RECOMMENDATION = 'discharge_recommendation'
export const EPISODES_OF_CARE = 'episodes_of_care'
export const GENERAL_ORDERS_ACCESS = 'orders'
export const HIGH_RISK_HUDDLE = 'high_risk_huddle'
export const IMO_SEARCH = 'assessment:imo_search'
export const INBOUND_CALL = 'inbound_call'
export const IOC_DASH = 'oc_dash'
export const MD_TOOLBOX = 'md_toolbox'
export const METRICS = 'metrics'
export const NURSING_DASH = 'nursing_dashboard'
export const NURSING_DASH_VIEW_AS = 'nursing_dashboard:view_as'
export const ON_CALL = 'on_call'
export const OPEN_ENCOUNTERS = 'open_encounter'
export const PATIENT_CONTACTS = 'patient_contacts'
export const PATIENT_RECORD_ADDRESS_OVERRIDE = 'patient_record:address_override'
export const PATIENT_RECORD_QA = 'patient_record:qa'
export const PES_DASHBOARD = 'pes_dashboard'
export const PHARMACIST_DASH = 'pharmacist_dashboard'
export const PHARMACIST_DASH_VIEW_AS = 'pharmacist_dashboard:view_as'
export const PHYSICIAN_DASH = 'physician_dash'
export const POLYGONATOR = 'polygonator'
export const PRESCRIBING_LOCATIONS = 'prescribing_locations'
export const RPM_DASH = 'rpm_dash'
export const RPM_DASH_VIEW_AS_APPROVER = 'rpm_dash:view_as_approver'
export const REFERRAL_MANAGEMENT = 'referral_management'
export const REFERRAL_MANAGEMENT_VIEW_AS = 'referral_management'
export const REINSTATED_DISCHARGE = 'reinstated_discharge'
export const REPORTS_DASHBOARD = 'reports_dashboard'
export const RN_ASSESSMENT = 'rn_assessment'
export const SW_ASSESSMENT = 'sw_assessment'
export const SW_DASH = 'sw_dashboard'
export const SW_DASH_VIEW_AS = 'sw_dashboard:view_as'
export const TRIAGE_CALL = 'triage_call'
export const TRANSPORTATION_DASH_APP = 'transportation_dash'
export const TRANSPORTATION_TRIPS = 'transportation:trips'
export const TRANSPORTATION_PATIENT_INFO = 'transportation:patient_info'
export const TRANSPORTATION_PATIENT_TIMELINE = 'transportation:patient_timeline'
export const TRANSPORTATION_PATIENT_SEARCH = 'transportation:patient_search'
export const TRANSPORTATION_TRIPS_OVERRIDE = 'transportation_trips:override'
export const TRANSPORTATION_USER_ACTIVITY = 'transportation:user_activity'
export const TRANSPORTATION_TRIP_REPORT = 'transportation:trip_report'
export const TRANSPORTATION_BENEFITS = 'transportation:benefits'
export const TRANSPORTATION_TRIP_LOCKS = 'transportation:trip_locks'
