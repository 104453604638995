import { List, fromJS, Set as iSet } from 'immutable'
import {
  Category,
  Diagnosis,
  Modifier,
  ModifierGroup,
  Problem,
} from './../../types/types'

const updateInProblem = (
  state: any,
  problemId: string,
  key: string,
  value: any
) => {
  return state.updateIn(['selectedProblems'], (problems: Problem[]) => {
    const problemIndex = getProblemIndexById(problems, problemId)
    return List(problems).update(problemIndex, (problem: Problem) =>
      fromJS(problem).mergeIn([key], value)
    )
  })
}

export const getSecondaryICD10Code = (problem: Problem, icd10Code: string) => {
  const order: any[] | undefined = [
    ...fromJS(problem).entries(),
  ].find((value: any[]): boolean =>
    typeof fromJS(problem).get(value[0]) === 'string'
      ? isIcd10CodeMatching(fromJS(problem).get(value[0]), icd10Code)
      : false
  )

  if (order) {
    return order[0]?.charAt(order[0].length - 1) === 'e'
      ? '0'
      : order[0]?.charAt(order[0].length - 1)
  }
  return null
}

export const isIcd10CodeMatching = (
  icd10Code1: string,
  icd10Code2: string
): boolean => icd10Code1.replace('.', '') === icd10Code2.replace('.', '')

export const getProblemIndexById = (problems: Problem[], id: string) =>
  problems.findIndex((problem: Problem) => problem.id === id)

export const getCombinations = (modifierCombinations: any) => {
  const combinations: string[] = []
  Object.values(modifierCombinations).map(({ allowedModifiers }: any) =>
    combinations.push(...allowedModifiers)
  )
  return [...new Set(combinations)]
}

export const getModifiersIntersection = (problem: Problem) => {
  if (List(problem.modifierGroups).size > 1) {
    const { enabledModifiers } = List(problem.modifierGroups).reduce(
      (accum: any, modifierGroup) => {
        if (iSet.isSet(accum)) {
          return accum.intersect(
            fromJS(modifierGroup?.enabledModifiers).toSet()
          )
        }
        if (accum.enabledModifiers.size === 0) {
          return accum.enabledModifiers.concat(
            fromJS(modifierGroup)?.get('enabledModifiers')
          )
        }
        return {
          enabledModifiers: fromJS(accum.enabledModifiers)
            .toSet()
            .intersect(fromJS(modifierGroup?.enabledModifiers).toSet())
            .toArray(),
        }
      }
    )
    return enabledModifiers
  }
  return fromJS(problem.modifierGroups).first().get('enabledModifiers')
}

export const updateProblemWorkflow = (
  state: any,
  { id, modifierGroups, modifierCombinations }: any
) =>
  state.updateIn(['selectedProblems'], (problems: Problem[]) =>
    List(problems).update(
      getProblemIndexById(problems, id),
      (problem: Problem) =>
        fromJS(problem).merge({
          modifierGroups: modifierGroups,
          modifierCombinations: modifierCombinations,
          enabledModifiers: getCombinations(modifierCombinations),
        })
    )
  )

export const updateProblemCategory = (state: any, categories: any) =>
  state.updateIn(['selectedProblems'], (problems: Problem[]) =>
    problems.map((problem: Problem) =>
      categories.length > 0
        ? isIcd10CodeMatching(problem.icd10Code, categories[0].icd10Code)
          ? fromJS(problem).updateIn(['categories'], () =>
              List(
                categories.map(
                  ({
                    category,
                    problemCategoryId,
                    problemType,
                    problemTypeId,
                    subcategory,
                    icd10Code,
                  }: Category) => ({
                    category,
                    problemCategoryId,
                    problemType,
                    problemTypeId,
                    subcategory,
                    diagnoses: List(),
                    icd10Code,
                  })
                )
              )
            )
          : problem
        : fromJS(problem).setIn(['error'], true)
    )
  )

export const updateProblemDiagnosis = (state: any, diagnoses: any) =>
  state.updateIn(['selectedProblems'], (problems: Problem[]) =>
    problems.map((problem: Problem) =>
      fromJS(problem).updateIn(['diagnoses'], (diagnosesAct: any) =>
        diagnosesAct.concat(
          diagnoses.filter(
            (diagnosis: Diagnosis) =>
              isIcd10CodeMatching(problem.icd10Code, diagnosis.icd10Code) &&
              !diagnosis.isDeleted
          )
        )
      )
    )
  )
export const updateSecondaryProblemDiagnosis = (state: any, diagnoses: any) =>
  state.updateIn(['selectedProblems'], (problems: Problem[]) => {
    const problemId = state.get('problemSelected')
    const problemIndex = getProblemIndexById(problems, problemId)
    return List(problems).update(problemIndex, (problem: Problem) =>
      fromJS(problem).updateIn(['diagnoses'], (diagnosesAct: any) =>
        diagnosesAct.concat(
          diagnoses.filter((diagnosis: Diagnosis) => {
            const order = getSecondaryICD10Code(problem, diagnosis.icd10Code)
            return order && order != '0'
              ? isIcd10CodeMatching(
                  fromJS(problem).get(`secondaryICDCode${order}`),
                  diagnosis.icd10Code
                ) && !diagnosis.isDeleted
              : false
          })
        )
      )
    )
  })

export const updateModifiersInModifierGroup = (
  state: any,
  allowedModifiers: [],
  problemId: string,
  modifierGroupType: string
) =>
  state.updateIn(['selectedProblems'], (problems: Problem[]) => {
    const problemIndex = getProblemIndexById(problems, problemId)
    const problem = List(problems).get(problemIndex)
    const modifierGroupIndex = List(problem.modifierGroups).findIndex(
      modifierGroup => modifierGroup?.type === modifierGroupType
    )
    return List(problems).update(problemIndex, (problem: Problem) =>
      fromJS(problem).merge({
        modifierGroups: fromJS(
          problem.modifierGroups
        ).update(modifierGroupIndex, (modifierGroup: ModifierGroup) =>
          fromJS(modifierGroup).updateIn(
            ['enabledModifiers'],
            () => allowedModifiers
          )
        ),
      })
    )
  })

export const updateEnabledModifiers = (state: any, problemId: string) =>
  state.updateIn(['selectedProblems'], (problems: Problem[]) => {
    const problemIndex = getProblemIndexById(problems, problemId)
    return List(problems).update(problemIndex, (problem: Problem) =>
      fromJS(problem).merge({
        enabledModifiers: getModifiersIntersection(
          List(problems).get(problemIndex)
        ),
      })
    )
  })

export const updateEnabledModifier = (state: any, problemId: string) =>
  state.updateIn(['selectedProblems'], (problems: Problem[]) => {
    const problemIndex = getProblemIndexById(problems, problemId)
    return List(problems).update(problemIndex, (problem: Problem) => {
      const enabledModifiers: string[] = problem.enabledModifiers
      return fromJS(problem).merge({
        modifierGroups: fromJS(problem.modifierGroups).update(
          (modifierGroups: ModifierGroup[]) =>
            modifierGroups.map(modifierGroup =>
              fromJS(modifierGroup).merge({
                modifiers: modifierGroup.modifiers.map((modifier: Modifier) =>
                  fromJS(modifier).merge({
                    disabled: !enabledModifiers.includes(modifier?.code)
                      ? true
                      : false,
                  })
                ),
              })
            )
        ),
      })
    })
  })

export const updateProblemCategoriesStatus = (state: any) =>
  state.updateIn(['selectedProblems'], (problems: Problem[]) =>
    problems.map((problem: Problem) =>
      fromJS(problem).mergeIn(['categoriesLoaded'], true)
    )
  )
export const updateSecondaryProblemCategoriesStatus = (
  state: any,
  payload: any
) =>
  payload.icd10Code
    ? state.updateIn(['selectedProblems'], (problems: Problem[]) => {
        const problemId = state.get('problemSelected')
        const problemIndex = getProblemIndexById(problems, problemId)
        return List(problems).update(problemIndex, (problem: Problem) => {
          const order = getSecondaryICD10Code(problem, payload.icd10Code)
          return fromJS(problem).mergeIn([`secondaryCat${order}Loaded`], true)
        })
      })
    : state

export const updateSecondaryProblemDiagnosisStatus = (state: any) =>
  state.updateIn(['selectedProblems'], (problems: Problem[]) =>
    problems.map((problem: Problem) =>
      fromJS(problem).updateIn(
        ['diagnosesLoaded'],
        (diagnosesLoaded: number) => diagnosesLoaded + 1
      )
    )
  )

export const updateWorkflowLoaded = (state: any) =>
  updateInProblem(state, state.get('problemSelected'), 'workflowLoaded', true)

export const updateModifierSelected = (state: any, problemId: string) =>
  updateInProblem(state, problemId, 'modifierSelected', true)

export const updateSecondaryProblemCategory = (state: any, categories: any) =>
  state.updateIn(['selectedProblems'], (problems: Problem[]) => {
    const problemId = state.get('problemSelected')
    const problemIndex = getProblemIndexById(problems, problemId)
    return List(problems).update(problemIndex, (problem: Problem) => {
      if (categories.length > 0 && fromJS(categories[0]).size > 0) {
        const order = getSecondaryICD10Code(problem, categories[0].icd10Code)
        return order
          ? fromJS(problem).updateIn(
              [`secondaryICDCodeCategories${order}`],
              (categoriesAct: any) =>
                categoriesAct.concat(
                  List(
                    categories.map(
                      ({
                        category,
                        problemCategoryId,
                        problemType,
                        problemTypeId,
                        subcategory,
                        icd10Code,
                      }: Category) => ({
                        category,
                        problemCategoryId,
                        problemType,
                        problemTypeId,
                        subcategory,
                        diagnoses: List(),
                        icd10Code,
                      })
                    )
                  )
                )
            )
          : problem
      }
      return fromJS(problem).mergeIn(['secondaryCategoriesError'], true)
    })
  })

export const updateSecondaryProblemCategories = (state: any) =>
  state.updateIn(['selectedProblems'], (problems: Problem[]) => {
    const problemId = state.get('problemSelected')
    const problemIndex = getProblemIndexById(problems, problemId)
    return List(problems).update(problemIndex, (problem: Problem) =>
      fromJS(problem).updateIn(['categories'], (categoriesAct: any) =>
        categoriesAct.concat(
          problem.secondaryICDCodeCategories1,
          problem.secondaryICDCodeCategories2,
          problem.secondaryICDCodeCategories3,
          problem.secondaryICDCodeCategories4
        )
      )
    )
  })
