import React from 'react'
import { FieldValue } from '~/data/fieldValues'
import PropTypes from '~/utils/propTypes'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

class EditStakeholderRow extends React.PureComponent {
  onChange = event => {
    this.props.onChange(event.target.value)
  }

  render() {
    const { className, items, value } = this.props

    return (
      <Select
        multiple
        className={className}
        onChange={this.onChange}
        value={value}
      >
        {items.toIndexedSeq().map(({ value, label }) => (
          <MenuItem key={value} value={label}>
            {label}
          </MenuItem>
        ))}
      </Select>
    )
  }
}

EditStakeholderRow.propTypes = {
  className: PropTypes.string.isRequired,
  items: PropTypes.mapOf(FieldValue),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
}

export default EditStakeholderRow
