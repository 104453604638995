import { List, Map, Record } from 'immutable'
import { clone, get as getIn, setWith } from 'lodash'
import { createSelector, createStructuredSelector } from 'reselect'
import { calcFormData } from '~/components/JsonForm'
import { WARNING } from '~/data/messages'
import { getPatientId } from '~/features/patientInfo'
import AspireAPI from '~/resources/aspire'
import Request, { flattenErrors } from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into, messageCreator, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import {
  FORM_DATA_CHANGED_BY_TAG,
  SECTION_CHANGED,
  getAssessment,
} from './common/shared'

const key = 'assessments'

export const Assessment = Record({
  id: null,
  eventId: null,
  patientId: null,
  patientName: null,
  status: null,
  checkedIn: null,
  checkedOut: null,
  schema: null,
  uiSchema: null,
  tags: null,
  formData: null,
  context: null,
  type: null,
  typeLabel: null,
  providerId: null,
  providerName: null,
  providerRole: null,
  dateOfService: null,
  scratchNotes: null,
  modifiedAt: null,
  ensurePhysicianReviewAvailable: null,
  local: false,
  addendums: List(),
  telehealthOsAppointmentLink: null,
  encounterReviewFormData: {},
})

export const Addendum = Record({
  id: null,
  text: null,
  createdAt: null,
  createdBy: null,
})

const creator = scopedCreator(rootKey)
export const currentAssessmentFetched = creator('CURRENT_ASSESSMENT_FETCHED', [
  'assessment',
])
export const assessmentFetched = creator('ASSESSMENT_FETCHED', ['assessment'])
export const assessmentScratchNotesChanged = creator(
  'ASSESSMENT_SCRATCH_NOTES_CHANGED',
  ['assessmentId', 'notes']
)
export const checkInWarningSnackbarOpened = messageCreator(
  `${key}/CHECK_IN_WARNING_SNACKBAR_OPENED`,
  'You have not yet checked into this encounter',
  WARNING
)

const transformAddendum = ({ id, text, createdAt, createdUser }) =>
  Addendum({
    id,
    text,
    createdAt,
    createdBy: createdUser.name,
  })

export const transformAssessments = ({
  assessmentStatus,
  encounterType,
  patient,
  provider,
  ...rest
}) =>
  Assessment({
    ...rest,
    status: assessmentStatus,
    type: encounterType.type,
    typeLabel: encounterType.label,
    patientName: getIn(patient, ['demographics', 'name']),
    providerId: provider.id,
    providerName: provider.name,
    providerRole: provider.role,
  })

export const transformAssessment = ({
  assessmentStatus,
  checkInAt,
  checkOutAt,
  encounterType = {},
  patient,
  provider = {},
  scratchNotes,
  addendums,
  form = {},
  ...rest
}) =>
  Assessment({
    ...rest,
    status: assessmentStatus,
    checkedIn: !!checkInAt,
    checkedOut: !!checkOutAt,
    schema: form.schema,
    uiSchema: form.uiSchema,
    tags: form.tags,
    formData: form.data,
    context: form.context,
    modifiedAt: form.modifiedAt || rest.modifiedAt,
    type: encounterType.type,
    typeLabel: encounterType.label,
    patientName: getIn(patient, ['demographics', 'name']),
    providerId: provider.id,
    providerName: provider.name,
    providerRole: provider.role,
    scratchNotes: getIn(scratchNotes, 'notes'),
    addendums: List(addendums || []).map(transformAddendum),
  })

const initializeAssessment = assessment =>
  assessment.set(
    'formData',
    calcFormData({
      formData: assessment.formData,
      schema: assessment.schema,
      context: assessment.context,
      tags: assessment.tags,
    })
  )

export const fetchAssessment = Request({
  typePrefix: key,
  typeBase: 'FETCH_ASSESSMENT',
  requestParams: ['id', 'current'],
  operation: id => AspireAPI.get(`encounters/${id}`),
  transform: pipe(transformAssessment, initializeAssessment),
  messages: { failed: 'There was a problem fetching the assessment' },
})

export const fetchAssessments = Request({
  typePrefix: key,
  typeBase: 'FETCH_ASSESSMENTS',
  requestParams: ['params'],
  operation: params => AspireAPI.get('encounters', { params }),
  messages: { failed: 'There was a problem fetching the assessments' },
})

export const startAssessment = Request({
  typePrefix: key,
  typeBase: 'START_ASSESSMENT',
  requestParams: ['eventId'],
  operation: eventId => AspireAPI.post(`encounters`, { eventId }),
  transform: transformAssessment,
  messages: {
    failed: e =>
      e.response.data?.message || [
        ' There was a problem starting the assessment.',
      ],
  },
})

export const saveAssessment = Request({
  typePrefix: key,
  typeBase: 'SAVE_ASSESSMENT',
  requestParams: ['assessment'],
  operation: ({ id, formData, scratchNotes }) =>
    AspireAPI.put(`encounters/${id}/assessment`, {
      data: formData,
      notes: scratchNotes,
    }),
  messages: {
    failed: e =>
      flattenErrors(getIn(e, 'response.data.message')) ||
      'There was a problem saving the assessment',
  },
})

export const signAssessment = Request({
  typePrefix: key,
  typeBase: 'SIGN_ASSESSMENT',
  requestParams: ['assessment', 'ensurePhysicianReview', 'manifest'],
  operation: ({ id }, ensurePhysicianReview, formData) =>
    AspireAPI.post(`encounters/${id}/assessment/sign`, {
      manifestFormData: formData,
      ensurePhysicianReview,
    }),
  messages: {
    failed: e =>
      flattenErrors(getIn(e, 'response.data.message')) ||
      'There was a problem signing the assessment',
  },
})

export const cancelAssessment = Request({
  typePrefix: key,
  typeBase: 'CANCEL_ASSESSMENT',
  requestParams: ['id', 'reason', 'reasonOther'],
  operation: (id, reason, reasonOther) =>
    AspireAPI.post(`encounters/${id}/assessment/cancel`, {
      reason,
      reasonOther,
    }),
  messages: { failed: 'There was a problem cancelling the assessment' },
})

export const createAssessment = Request({
  typePrefix: key,
  typeBase: 'CREATE_ASSESSMENT',
  requestParams: ['patientId', 'providerId', 'type'],
  operation: (patientId, providerId, type) =>
    AspireAPI.post('encounters', { patientId, providerId, type }),
  messages: {
    failed: 'Failed to create assessment',
  },
})

export const addAddendum = Request({
  typePrefix: key,
  typeBase: 'ADD_ADDENDUM',
  requestParams: ['encounterId', 'text'],
  operation: (encounterId, text) =>
    AspireAPI.post(`encounters/${encounterId}/addendum`, { text }),
  transform: transformAddendum,
  messages: { failed: 'There was a problem adding an addendum' },
})

export const downloadDocument = Request({
  typePrefix: key,
  typeBase: 'DOWNLOAD_DOCUMENT',
  requestParams: ['encounterId'],
  operation: encounterId =>
    AspireAPI.get(`media/encounters/${encounterId}/pdf`, {
      responseType: 'blob',
    }),
  messages: {
    failed: 'There was a problem downloading the encounter document',
  },
})

export const downloadDocumentSlums = Request({
  typePrefix: key,
  typeBase: 'DOWNLOAD_DOCUMENT_SLUMS',
  requestParams: ['id', 'encounterId', 'patientName'],
  operation: (id, encounterId) =>
    AspireAPI.get(`media/encounters/${id}/${encounterId}/get_slums_pdf`, {
      responseType: 'blob',
    }),
  messages: {
    failed: 'There was a problem downloading the encounter document',
  },
})

export const createAdhocThosVisit = Request({
  typePrefix: key,
  typeBase: 'CREATE_ADHOC_THOS_VISIT',
  requestParams: ['encounterId'],
  operation: encounterId =>
    AspireAPI.post(`telehealth_os/create_virtual_appt`, { encounterId }),
  messages: {
    succeeded: 'Successfully created virtual visit',
    failed: e =>
      flattenErrors(getIn(e, 'response.data.message')) ||
      'There was a problem creating the virtual visit',
  },
})
export const downloadMedicationList = Request({
  typePrefix: key,
  typeBase: 'DOWNLOAD_MEDICATION_LIST',
  requestParams: ['id', 'patientName'],
  operation: id =>
    AspireAPI.get(`media/patient/${id}/get_medication_list_pdf`, {
      responseType: 'blob',
    }),
  messages: {
    failed: 'There was a problem downloading the Medication List',
  },
})

export const switchEncounter = Request({
  typePrefix: key,
  typeBase: 'SWITCH_ENCOUNTER',
  requestParams: [
    'encounterId',
    'patientId',
    'eventId',
    'cancellationType',
    'cancellationMotivation',
    'cancellationReason',
    'cancellationNotes',
    'newEncounterType',
  ],
  operation: (
    encounterId,
    patientId,
    eventId,
    cancellationType,
    cancellationMotivation,
    cancellationReason,
    cancellationNotes,
    newEncounterType
  ) =>
    AspireAPI.post(`encounters/${encounterId}/assessment/switch_encounter`, {
      patientId,
      eventId,
      cancellationType,
      cancellationMotivation,
      cancellationReason,
      cancellationNotes,
      newEncounterType,
    }),
  messages: {
    failed: 'There was a problem switching encounter types',
  },
})

const changeFormData = (assessment, path, value) => {
  const { schema, formData, context, tags } = assessment
  const changed = path ? setWith(clone(formData), path, value, clone) : value
  const newFormData = calcFormData({ formData: changed, schema, context, tags })

  return assessment.set('formData', newFormData)
}

export default createReducer(key, Map(), {
  [currentAssessmentFetched]: (state, { payload: { assessment } }) =>
    state.set(assessment.id, assessment),
  [assessmentFetched]: (state, { payload: { assessment } }) =>
    state.set(assessment.id, assessment),
  [signAssessment.REQUESTED]: (state, { payload: { assessment } }) =>
    state.setIn([assessment.id, 'status'], 'Signing in Progress'),
  [signAssessment.SUCCEEDED]: (state, { payload }) =>
    state.setIn([payload.id, 'status'], payload.assessmentStatus),
  [cancelAssessment.SUCCEEDED]: (state, { payload }) =>
    state.setIn([payload.id, 'status'], payload.assessmentStatus),
  [SECTION_CHANGED]: (state, { payload: { assessmentId, section, info } }) =>
    state.update(assessmentId, assessment =>
      changeFormData(assessment, [section], info.formData)
    ),
  [FORM_DATA_CHANGED_BY_TAG]: (
    state,
    { payload: { assessmentId, tag, value } }
  ) =>
    state.update(assessmentId, assessment =>
      changeFormData(assessment, assessment.tags[tag].data, value)
    ),
  [assessmentScratchNotesChanged]: (
    state,
    { payload: { assessmentId, notes } }
  ) => state.setIn([assessmentId, 'scratchNotes'], notes),
  [addAddendum.SUCCEEDED]: (state, { payload, meta }) =>
    state.updateIn([meta.request.payload.encounterId, 'addendums'], addendums =>
      addendums.push(payload)
    ),
  [startAssessment.SUCCEEDED]: (state, { payload }) =>
    state.set(payload.id, payload),
  [switchEncounter.SUCCEEDED]: (state, { payload }) =>
    state.set(payload.id, payload),
  [fetchAssessments.SUCCEEDED]: (state, { payload }) =>
    state.merge(into(transformAssessments, 'id')(payload)),
  [createAdhocThosVisit.SUCCEEDED]: (
    state,
    { payload: { encounter_id, telehealth_os_appointment_link } }
  ) =>
    state.setIn(
      [encounter_id, 'telehealthOsAppointmentLink'],
      telehealth_os_appointment_link
    ),
})

const defaultAssessment = Assessment()

export const getAllAssessments = pipe(getAssessment, get(key))

const getAssessmentsByProviderId = createSelector(
  [getAllAssessments, (_state, id) => id],
  (assessments, id) => assessments.filter(({ providerId }) => providerId == id)
)

export const getWorklistAssessmentByProviderId = createSelector(
  [getAssessmentsByProviderId],
  assessments => {
    const today = new Date()
    return assessments
      .filter(({ status, dateOfService: dos }) => {
        const dateOfService = new Date(dos)
        return (
          ['created', 'in_progress'].includes(status) ||
          (status == 'not_started' && dateOfService <= today)
        )
      })
      .toList()
      .sortBy(get('dateOfService'))
  }
)

export const getWorklistAssessmentLengthFromUserSelector = selector => state => {
  const userId = selector(state)
  return getWorklistAssessmentByProviderId(state, userId).size
}

export const getPatientAssessmentsArray = createSelector(
  [getAllAssessments, getPatientId],
  (assessments, patientId) =>
    assessments
      .filter(assessment => assessment.patientId == patientId)
      .toIndexedSeq()
      .toJS()
)

export const getAssessmentById = (state, id) =>
  getAllAssessments(state).get(id, defaultAssessment)

export const getAssessmentSectionsById = createSelector(
  getAssessmentById,
  assessment => getIn(assessment, ['uiSchema', 'ui:order'], [])
)

export const getAssessmentAddendumsById = createSelector(
  getAssessmentById,
  get('addendums', defaultAssessment.addendums)
)

export const getEncounterReviewFormDataById = createSelector(
  getAssessmentById,
  get('encounterReviewFormData', defaultAssessment.encounterReviewFormData)
)

export const createGetSection = () =>
  createStructuredSelector({
    schema: ({ schema }, section) => schema.properties[section],
    uiSchema: ({ uiSchema }, section) => uiSchema[section],
    formData: ({ formData }, section) => formData[section],
  })
