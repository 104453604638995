import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosError,
  AxiosResponse,
} from 'axios'
import UnauthenticatedRequestError from '~/errors/UnauthenticatedRequestError'
import keycloak from '~/utils/security/keycloak'
// @ts-expect-error (2307) FIXME: Cannot find module '~/utils/telemetry' or i... Remove this comment to see the full error message
import telemetry from '~/utils/telemetry'
import { tryCatch, chain, fromEither, TaskEither } from 'fp-ts/TaskEither'
import { flow, pipe } from 'fp-ts/function'
import {
  Program,
  summon,
  Ctor,
  Ctor_,
  AType,
  EType,
  AsOpaque,
  Variant,
  AOfMorphADT,
  tagged,
} from '~/utils/type'
import * as E from 'fp-ts/lib/Either'
import * as t from 'io-ts'
import * as O from 'fp-ts/lib/Option'
import { Option } from 'fp-ts/Option'
import { PathReporter } from 'io-ts/PathReporter'
import { DatumEither, squash } from '@nll/datum/DatumEither'

export type Resource<Data> = TaskEither<RequestError, Data>

interface ConfigWithParams<T> extends AxiosRequestConfig {
  body?: T
}

interface IResource {
  interceptors: AxiosInstance['interceptors']

  get: <Data>(
    uri: string,
    decoder: t.Decoder<unknown, Data>,
    opts?: AxiosRequestConfig
  ) => Resource<Data>

  post: <Data, P = undefined>(
    uri: string,
    decoder: t.Decoder<unknown, Data>,
    body?: P,
    opts?: AxiosRequestConfig
  ) => Resource<Data>

  put: <Data, P = undefined>(
    uri: string,
    decoder: t.Decoder<unknown, Data>,
    body?: P,
    opts?: AxiosRequestConfig
  ) => Resource<Data>

  patch: <Data, P>(
    uri: string,
    decoder: t.Decoder<unknown, Data>,
    body?: P,
    opts?: AxiosRequestConfig
  ) => Resource<Data>

  delete: <Data>(
    uri: string,
    decoder: t.Decoder<unknown, Data>,
    opts?: AxiosRequestConfig
  ) => Resource<Data>
}

/**
 * Logs error.
 *
 * @private
 * @returns {Promise<Error>}
 */
const logError = <T>(error: T): Promise<T> => {
  telemetry.error(error)
  return Promise.reject<T>(error)
}

/**
 * Injects standard headers into a request, including the user's id and
 * authorization token.
 *
 * @private
 * @param {RequestConfig} requestConfig
 * @returns {RequestConfig} the request configuration with headers added.
 */
const injectHeaders = (
  requestConfig: AxiosRequestConfig
): Promise<AxiosRequestConfig> =>
  Promise.all([keycloak.user, keycloak.token]).then(([user, token]) => {
    requestConfig.headers['userId'] = user ? user.id : ''
    requestConfig.headers['Authorization'] = `Bearer ${token}`
    return requestConfig
  })

/**
 * Checks whether the current authorization token is still valid.
 *
 * @private
 * @returns {Promise} a promise that resolves/rejects to indicate the validity
 * of the current authorization token.
 */
const validateToken = (): Promise<string | void | undefined> =>
  keycloak.refreshToken()

// This is definitely NOT strict fp... but it's Javascript so I can do what I want eh?
const reportDecodeErrors = (e: t.Errors): string[] => {
  const report = PathReporter.report(E.left(e))
  console.error(report) // eslint-disable-line no-console
  return report
}

interface ServerResponse<T> {
  status: string
  data: T
}

export const ServerErrorCtor = <Tag extends string, Raw, Message>(
  k: Tag,
  message: Program<Raw, Message>
) =>
  summon(F =>
    F.interface(
      {
        type: F.tag(k),
        status: F.string(),
        message: message(F),
      },
      `ServerError: ${k as string}`
    )
  )

const VarseError_ = ServerErrorCtor('VarseError', F => F.array(F.string()))
interface VarseError extends AType<typeof VarseError_> {}
interface VarseErrorRaw extends EType<typeof VarseError_> {}
const VarseError = AsOpaque<VarseErrorRaw, VarseError>()(VarseError_)

const StringError_ = ServerErrorCtor('StringError', F => F.string())
interface StringError extends AType<typeof StringError_> {}
interface StringErrorRaw extends EType<typeof StringError_> {}
const StringError = AsOpaque<StringErrorRaw, StringError>()(StringError_)

// type ChangesetError = Map<string, string[]> // Not sure how cast_assoc's are handled this might be nested Maps in reality...
const ChangesetError_ = ServerErrorCtor('ChangesetError', F =>
  F.strMap(F.array(F.string()))
)
interface ChangesetError extends AType<typeof ChangesetError_> {}
interface ChangesetErrorRaw extends EType<typeof ChangesetError_> {}
const ChangesetError = AsOpaque<ChangesetErrorRaw, ChangesetError>()(
  ChangesetError_
)

export const ServerErrorMessage = Variant({
  StringError,
  ChangesetError,
  VarseError,
})
export type ServerErrorMessage = AOfMorphADT<typeof ServerErrorMessage>

const ServerError_ = Ctor('ServerError', ServerErrorMessage)
export interface ServerError extends AType<typeof ServerError_> {}
export interface ServerErrorRaw extends EType<typeof ServerError_> {}
export const ServerError = AsOpaque<ServerErrorRaw, ServerError>()(ServerError_)

const DecodeError_ = Ctor_('DecodeError')
export interface DecodeError extends AType<typeof DecodeError_> {}
export interface DecodeErrorRaw extends EType<typeof DecodeError_> {}
export const DecodeError = AsOpaque<DecodeErrorRaw, DecodeError>()(DecodeError_)

const FailedRequest_ = Ctor_('FailedRequest')
export interface FailedRequest extends AType<typeof FailedRequest_> {}
export interface FailedRequestRaw extends EType<typeof FailedRequest_> {}
export const FailedRequest = AsOpaque<FailedRequestRaw, FailedRequest>()(
  FailedRequest_
)

export const RequestError = Variant({ FailedRequest, DecodeError, ServerError })
export type RequestError = AOfMorphADT<typeof RequestError>

export const createResource = (baseURL_: string): IResource => {
  const baseURL: string = /\/$/.test(baseURL_) ? baseURL_ : baseURL_ + '/'
  const resource: AxiosInstance = axios.create({ baseURL })

  const interceptors: AxiosInstance['interceptors'] = resource.interceptors

  interceptors.request.use(injectHeaders)

  /**
   * Extracts the response body from the axios response wrapper so that it is
   * consistent with how existing applications consume responses.
   *
   * @private
   * @returns {Data}
   */
  // const transformResponse = <Data>(res: AxiosResponse<Data>): Data => res.data
  // UNSAFE: transform response incorrectly type checks but it doesn't align with the signature interceptors expects.
  // interceptors.response.use(transformResponse, logError)
  // plus we pull data out in the body of the request function so this is unneeded anyways....
  interceptors.response.use(undefined, logError)

  /**
   * The central method through which all requests are dispatched. Ensures that
   * the session is stil valid before initiating network requests.
   *
   * @private
   * @param {string} method - HTTP verb to use
   * @param {string} uri - endpoint to hit on the configured resource
   * @param {function} decoder - function to turn JSON into a type safe object.
   * @param {object} [opts] - optional request configuration
   * @returns {Promise} the result of the network request
   */
  const request = <Data, P = undefined>(
    method: 'get' | 'delete' | 'put' | 'post' | 'patch',
    uri: string,
    decoder: t.Decoder<unknown, Data>,
    opts?: ConfigWithParams<P>
  ): Resource<Data> =>
    pipe(
      tryCatch<RequestError, AxiosResponse<ServerResponse<unknown>>>(
        () =>
          validateToken()
            .catch<never>(() => {
              throw new UnauthenticatedRequestError(
                'Attempted to make an unauthenticated request'
              )
            })
            .then(() => {
              switch (method) {
                case 'get':
                  return resource.get(uri, opts)
                case 'delete':
                  return resource.delete(uri, opts)
                case 'patch':
                  return resource.patch(uri, opts?.body, opts)
                case 'post':
                  return resource.post(uri, opts?.body, opts)
                case 'put':
                  return resource.patch(uri, opts?.body, opts)
              }
            }),
        (reason: unknown): RequestError =>
          pipe(
            (reason as AxiosError<unknown>).response?.data,
            O.fromNullable,
            O.fold(
              () => RequestError.of.FailedRequest({}),
              flow(
                ServerErrorMessage.type.decode,
                E.fold(
                  flow(reportDecodeErrors, _ =>
                    RequestError.of.DecodeError({})
                  ),
                  payload => RequestError.of.ServerError({ payload })
                )
              )
            )
          )
      ),
      chain<RequestError, AxiosResponse<ServerResponse<unknown>>, Data>(
        flow(
          res => res.data.data,
          decoder.decode,
          E.mapLeft(
            flow(reportDecodeErrors, _ => RequestError.of.DecodeError({}))
          ),
          fromEither
        )
      )
    )

  return {
    get: <Data>(
      uri: string,
      decoder: t.Decoder<unknown, Data>,
      opts?: AxiosRequestConfig
    ): Resource<Data> => request('get', uri, decoder, opts),

    post: <Data, Params = undefined>(
      uri: string,
      decoder: t.Decoder<unknown, Data>,
      body: Params,
      opts?: AxiosRequestConfig
    ): Resource<Data> => request('post', uri, decoder, { ...opts, body }),

    put: <Data, Params = undefined>(
      uri: string,
      decoder: t.Decoder<unknown, Data>,
      body: Params,
      opts?: AxiosRequestConfig
    ): Resource<Data> => request('put', uri, decoder, { ...opts, body }),

    patch: <Data, Params = undefined>(
      uri: string,
      decoder: t.Decoder<unknown, Data>,
      body: Params,
      opts?: AxiosRequestConfig
    ): Resource<Data> => request('patch', uri, decoder, { ...opts, body }),

    delete: <Data>(
      uri: string,
      decoder: t.Decoder<unknown, Data>,
      opts?: AxiosRequestConfig
    ): Resource<Data> => request('delete', uri, decoder, opts),

    interceptors,
  }
}

const Initial = summon(F =>
  F.interface(
    {
      _tag: F.stringLiteral('Initial'),
    },
    'Initial'
  )
)

const Pending = summon(F =>
  F.interface(
    {
      _tag: F.stringLiteral('Pending'),
    },
    'Pending'
  )
)

function Refresh<E, A>(A: Program<E, A>) {
  return summon(F =>
    F.interface(
      {
        _tag: F.stringLiteral('Refresh'),
        value: A(F),
      },
      'Refresh'
    )
  )
}
function Replete<E, A>(A: Program<E, A>) {
  return summon(F =>
    F.interface(
      {
        _tag: F.stringLiteral('Replete'),
        value: A(F),
      },
      'Replete'
    )
  )
}

function Datum<E, A>(A: Program<E, A>) {
  return tagged('_tag')({
    Initial,
    Pending,
    Replete: Replete(A),
    Refresh: Refresh(A),
  })
}

export type Async<Data> = DatumEither<RequestError, Data>
export function Async<E, A>(A: Program<E, A>) {
  return Datum(F => F.either(RequestError(F), A(F)))
}
// Async helpers... these should be in @nll/Datum, maybe I'll make a PR?
export const getRight = <Data>(data: DatumEither<any, Data>): Option<Data> =>
  squash(
    () => O.none,
    () => O.none,
    (d: Data) => O.some(d)
  )(data)

export const getOrElse = <Data>(def: Data) => (
  data: DatumEither<any, Data>
): Data =>
  squash(
    () => def,
    () => def,
    (d: Data) => d
  )(data)
