import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
// @ts-expect-error no export
import { getUsers } from '~/data/users'
import { TextField } from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'

// Shim type until we can get users out of ImmutableJS
type User = {
  label: string
  roleName: string
  value: string
}

type Props = {
  label?: string
  disabled?: boolean
  error?: boolean
  includeRole?: boolean
  userId?: string
  onUserSelected?: (userId: string, user: User) => void
  onUserCleared?: () => void
  userFilter?: (...args: any[]) => boolean
  userMapper?: (user: any) => any
}

const filterOptions = createFilterOptions<User>({
  limit: 100,
  trim: true,
})

const UserSelector = ({
  label = 'User Lookup',
  disabled = false,
  error = false,
  includeRole = false,
  userId,
  userFilter = () => true,
  userMapper = user => user,
  onUserSelected = () => {},
  onUserCleared = () => {},
}: Props) => {
  const users: any = useSelector(getUsers)

  const [selectedUser, setSelectedUser] = useState<User | null>(null)

  const suggestions: User[] = useMemo(
    () =>
      users
        .toIndexedSeq()
        .toArray()
        .filter(userFilter)
        .filter(({ label }: any) => label)
        .map(userMapper),
    [users, userFilter, userMapper]
  )

  const onChange = (_e: ChangeEvent<unknown>, value: User | null) => {
    const userId = value?.value

    if (!userId) {
      setSelectedUser(null)
      onUserCleared()
    } else if (userId && userId !== selectedUser?.value) {
      const user = users.get(userId)

      onUserSelected?.(userId, user)
      setSelectedUser(user)
    }
  }

  useEffect(() => {
    if (userId) {
      const user = users.get(userId)
      setSelectedUser(user)
    }
  }, [users, userId])

  return (
    <Autocomplete
      filterOptions={filterOptions}
      options={suggestions}
      getOptionLabel={option =>
        includeRole ? `${option.label} - ${option.roleName}` : option.label
      }
      onChange={onChange}
      value={selectedUser}
      fullWidth
      blurOnSelect
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          disabled={disabled}
          error={error}
        />
      )}
    />
  )
}

export default UserSelector
