import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAction } from '~/hooks'
import {
  Button,
  Grid,
  Icon,
  IconButton,
  NativeSelect,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  addCareTeamToPod,
  getCareTeamDetailsById,
  removeCareTeamToPod,
  selectedCareTeamId,
} from '../data/careTeam'
import { fetchCareTeamPods, getCareTeamPods } from '../data/careTeamPods'

const useStyles = makeStyles(({ palette }) => ({
  button: {
    padding: '4px',
  },
  container: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  icon: {
    color: palette.secondary.main,
    fontSize: '0.75em',
  },
}))

export const CareTeamPodManager = ({ careTeamDetails }: any) => {
  const classes = useStyles()
  const setSelectedCareTeamId = useAction(selectedCareTeamId)
  const careTeamPodsListAction = useAction(fetchCareTeamPods.requested)
  const addCareTeamPod = useAction(addCareTeamToPod.requested)
  const deleteCareTeamToPod = useAction(removeCareTeamToPod.requested)
  const podList: any = useSelector(getCareTeamPods)
  const currentCareTeamDetail: any = useSelector(getCareTeamDetailsById)
  const [selectedPodId, setSelectedPodId] = useState<string>('')

  const handleChangePodSelection = (e: any) => {
    setSelectedPodId(e.target?.value)
  }
  const TYPE = [
    'telephonic',
    'five_percent',
    'palliative',
    'case_management',
    'caremore_at_home',
  ]
  const selectedPodList: any = []

  const verifyInvalidFields = (careTeamDetails: any) => {
    if (!careTeamDetails.market) return 'Market'
    if (!TYPE.includes(careTeamDetails.type)) return 'Assessment Type'
    if (!careTeamDetails.label) {
      return 'Label'
    }
    return ''
  }

  const prepareCareTeamPods = (careTeamDetails: any) =>
    careTeamDetails.pods.map((pod: any) =>
      selectedPodList.push(
        podList.find((podlist: any) => podlist.id == pod.podId)?.name
      )
    )

  currentCareTeamDetail && currentCareTeamDetail.id === careTeamDetails.id
    ? prepareCareTeamPods(currentCareTeamDetail)
    : prepareCareTeamPods(careTeamDetails)

  const requestJoinPod = (careTeamId: number) => {
    const podId = podList.find((pod: any) => pod.name == selectedPodId)?.id
    const data = {
      podId: podId,
      careTeamId: careTeamId,
    }
    setSelectedCareTeamId(careTeamDetails.id)
    addCareTeamPod(data)
    setSelectedPodId('')
  }
  useEffect(() => {
    verifyInvalidFields(careTeamDetails)
    careTeamPodsListAction()
  }, [])

  const getErrorMessage = (invalidFieldName: string) =>
    `There was an issue displaying this care team's pods due to invalid ${invalidFieldName} field`

  const requestLeavePod = (careTeamId: number, name: string) => {
    const podId = podList.find((pod: any) => pod.name == name)?.id
    setSelectedCareTeamId(careTeamDetails.id)
    deleteCareTeamToPod(careTeamId, podId)
  }

  const hasAnyInvalidField =
    !careTeamDetails.market ||
    !TYPE.includes(careTeamDetails.type) ||
    !careTeamDetails.label

  const invalidFieldName = verifyInvalidFields(careTeamDetails)

  return hasAnyInvalidField ? (
    <Typography>{getErrorMessage(invalidFieldName)}</Typography>
  ) : (
    <Grid
      item
      className={classes.container}
      container
      direction="column"
      spacing={3}
    >
      <Grid item>
        <Typography>Care Team Pods </Typography>
      </Grid>
      <Grid item>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Pod Name</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedPodList.map((name: string) => (
              <TableRow key={name}>
                <TableCell align="left"> {name} </TableCell>
                <TableCell align="right">
                  <IconButton
                    className={classes.button}
                    onClick={() => requestLeavePod(careTeamDetails.id, name)}
                  >
                    <Icon className={classes.icon}>clear</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item>
          <NativeSelect
            data-testid="careteam-pod-select"
            onChange={handleChangePodSelection}
          >
            <option key="empty" value="">
              -Not Selected-
            </option>
            {podList.map(({ id, name }: any) => (
              <option key={id} value={name}>
                {name}
              </option>
            ))}
          </NativeSelect>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => requestJoinPod(careTeamDetails.id)}
            disabled={selectedPodId == ''}
          >
            Add to Pod
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
