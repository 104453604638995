import React from 'react'
import { RiskLevelDisplay, getRiskLevel } from '~/features/patientInfo'
import PropTypes from '~/utils/propTypes'

const RiskCell = ({ row, value }) =>
  row.groupedBy ? (
    getRiskLevel(value).display
  ) : (
    <RiskLevelDisplay score={value} />
  )

RiskCell.propTypes = {
  row: PropTypes.object.isRequired,
  value: PropTypes.number,
}

export default React.memo(RiskCell)
