import { Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import patientRecordKey from '../key'
import { getScreenings } from './root'
const key = 'screenings'

export const getPatientScreenings = get(key)

export const Screenings = Record({
  phq4Screening: {},
  phq9Screening: {},
  gad7Screening: {},
  sdohLonelinessScreening: {},
  sdohPhysicalActivityScreening: {},
  sdohSafetyScreening: {},
  auditCScreening: {},
  edmontonFrailScaleScreening: {},
  miniCogScreening: {},
  mocaScreening: {},
  ppsScreening: {},
  currentHousing: {},
  housingProblems: {},
  foodAccess: {},
  reliableTransportation: {},
  reliableUtilities: {},
  financialStrain: {},
  employmentHelp: {},
  familyAndCommunity: {},
  educationHelp: {},
  urgentNeeds: {},
  followUpAreas: {},
})

/* FETCH PATIENT SCREENINGS */
export const fetchScreenings = Request({
  typePrefix: patientRecordKey,
  typeBase: 'FETCH_SCREENINGS',
  requestParams: ['patientId'],
  operation: patientId =>
    AspireAPI.get(`patient_record/${patientId}/screenings`),
  transform: Screenings,
  messages: { failed: 'There was a problem fetching patient screenings' },
})

export default createReducer(key, Screenings(), {
  [fetchScreenings.SUCCEEDED]: (_state, { payload }) => payload,
})

export const getPatientScreeningsMap = pipe(getScreenings, get(key))
