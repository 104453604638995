// Statuses
const CREATED = 'created'
const IN_PROGRESS = 'in_progress'
const REASSIGNED = 'reassigned'
export const CLOSED = 'closed'
export const CANCELLED = 'cancelled'
export const SNOOZED = 'snoozed'

const ACTIVE_STATUSES = [CREATED, IN_PROGRESS, REASSIGNED]
const INACTIVE_STATUSES = [CLOSED, CANCELLED, SNOOZED]
const VALID_CARE_TEAM_ROLES = ['pcc_rn', 'pcc_cc', 'field_sw']

export const isActive = status => ACTIVE_STATUSES.includes(status)
export const isInactive = status => INACTIVE_STATUSES.includes(status)

export const getCareTeamMemberFieldValues = (
  careTeamMembers,
  shouldFilter,
  user
) => {
  let fieldValues = careTeamMembers
    .filter(({ role }) => !shouldFilter || VALID_CARE_TEAM_ROLES.includes(role))
    .groupBy(({ id }) => id)
    .map(users => {
      // Some users have multiple roles on a care team
      const roles = users.map(({ roleLabel }) => roleLabel).join(', ')

      return users.first().update('name', name => `${name} - ${roles}`)
    })
    .toList()

  // Add current user if not already present
  if (!fieldValues.some(({ id }) => id === user.id)) {
    fieldValues = fieldValues.push(user)
  }

  return fieldValues
    .sortBy(({ name }) => name)
    .map(({ id, name }) => ({
      value: id,
      label: name,
    }))
}
