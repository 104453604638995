import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import DevXGrid from '~/components/DevXGrid'
import DisplayUTCTimestampAsLocalDate from '~/components/DisplayUTCTimestampAsLocalDate'
import PatientCell from '~/components/PatientCell'
import PropTypes from '~/utils/propTypes'
import { Paper } from '@material-ui/core'
import RiskCell from '../../components/RiskCell'
import { getCensus } from '../../data/census'
import { ADD_LIST_ITEM, getDialog } from '../../data/dialog'
import PatientDetails from '../PatientDetails'
import AddListDialog from './AddListDialog'
import RowActionsBase from './RowActions'

const BOOLEAN_WIDTH = 115

const program_abbr_mapping = {
  'CareMore Health Network': 'CHN',
  'CareMore At Home': 'CAH',
}

const baseColumns = [
  {
    name: 'patientFirstName',
    title: 'Patient First Name',
    displayComponent: PatientCell,
  },
  {
    name: 'patientLastName',
    title: 'Patient Last Name',
    displayComponent: PatientCell,
  },
  {
    name: 'program',
    title: 'Program',
    getCellValue: ({ program }) => program_abbr_mapping[program] || program,
  },
  { name: 'city', title: 'City' },
  { name: 'state', title: 'State' },
  { name: 'status', title: 'Status' },
  { name: 'substatus', title: 'Sub Status' },
  { name: 'metalTier', title: 'Metal Tier' },
  {
    name: 'riskLevel',
    title: 'Risk Level',
    displayComponent: RiskCell,
  },
  { name: 'careTeam', title: 'Care Team Assigned' },
  { name: 'caremoreCaseManager', title: 'CareMore Case Manager' },
  { name: 'fieldSw', title: 'Field SW' },
  { name: 'communityHealthWorker', title: 'Community Health Worker' },
  { name: 'careCoordinator', title: 'Care Coordinator' },
  { name: 'clinicalOperationsManager', title: 'Clinical Operations Manager' },
  {
    name: 'lastLoggedCommunication',
    title: 'Last Logged Communication',
    displayComponent: DisplayUTCTimestampAsLocalDate,
  },
  {
    name: 'lastLoggedCommunicationOutcome',
    title: 'Last Logged Communication Outcome',
  },
  {
    name: 'lastLoggedCommunicationLoggedBy',
    title: 'Last Logged Communication Logged By',
  },
  {
    name: 'lastHraCompletedDate',
    title: 'Last HRA Completed Date',
    displayComponent: DisplayUTCTimestampAsLocalDate,
  },
  {
    name: 'lastAssessmentCompletedDate',
    title: 'Last Assessment Completed Date',
    displayComponent: DisplayUTCTimestampAsLocalDate,
  },
  {
    name: 'lastCarePlanModificationDate',
    title: 'Last Care Plan Modification Date',
    displayComponent: DisplayUTCTimestampAsLocalDate,
  },
  { name: 'product', title: 'Product' },
  {
    name: 'effectiveDate',
    title: 'Effective Date',
  },
  {
    name: 'termDate',
    title: 'Term Date',
  },
  {
    name: 'lastAdmit',
    title: 'Last Admit',
    displayComponent: DisplayUTCTimestampAsLocalDate,
  },
  {
    name: 'lastEr',
    title: 'Last ER',
    displayComponent: DisplayUTCTimestampAsLocalDate,
  },
  {
    name: 'cmInHospital',
    title: 'In Hospital',
    type: 'boolean',
  },
  { name: 'cmInSnf', title: 'In SNF', type: 'boolean' },
  {
    name: 'lastSnfAdmit',
    title: 'Recent SNF DC',
    displayComponent: DisplayUTCTimestampAsLocalDate,
  },
  {
    name: 'acpDocuments',
    title: 'ACP Documents',
    type: 'boolean',
  },
]

const columnExtensions = [
  {
    columnName: 'patientFirstName',
    width: 175,
  },
  {
    columnName: 'patientLastName',
    width: 175,
  },
  { columnName: 'program', width: 150 },
  { columnName: 'city', width: 150 },
  { columnName: 'state', width: 150 },
  { columnName: 'status', width: 150 },
  { columnName: 'substatus', width: 150 },
  { columnName: 'metalTier', width: 150 },
  {
    columnName: 'riskLevel',
    width: 150,
  },
  { columnName: 'careTeam', width: 200 },
  { columnName: 'caremoreCaseManager', width: 175 },
  { columnName: 'fieldSw', width: 150 },
  { columnName: 'communityHealthWorker', width: 175 },
  { columnName: 'careCoordinator', width: 175 },
  { columnName: 'clinicalOperationsManager', width: 175 },
  { columnName: 'lastLoggedCommunication', width: 175 },
  {
    columnName: 'lastLoggedCommunicationOutcome',
    width: 175,
  },
  {
    columnName: 'lastLoggedCommunicationLoggedBy',
    width: 175,
  },
  { columnName: 'lastHraCompletedDate', width: 175 },
  {
    columnName: 'lastAssessmentCompletedDate',
    width: 175,
  },
  {
    columnName: 'lastCarePlanModificationDate',
    width: 175,
  },
  { columnName: 'product', width: 150 },
  { columnName: 'effectiveDate', width: 150 },
  { columnName: 'termDate', width: 150 },
  { columnName: 'lastAdmit', width: 150 },
  { columnName: 'lastEr', width: 150 },
  {
    columnName: 'cmInHospital',
    width: BOOLEAN_WIDTH,
  },
  { columnName: 'cmInSnf', width: BOOLEAN_WIDTH },
  {
    columnName: 'lastSnfAdmit',
    width: BOOLEAN_WIDTH,
  },
  {
    columnName: 'lastCallDate',
    width: 150,
  },
  {
    columnName: 'acpDocuments',
    width: BOOLEAN_WIDTH,
  },
  {
    columnName: 'substatus',
    width: 150,
  },
]

const defaultHiddenColumnNames = [
  'program',
  'city',
  'state',
  'riskLevel',
  'caremoreCaseManager',
  'fieldSw',
  'communityHealthWorker',
  'careCoordinator',
  'clinicalOperationsManager',
  'lastLoggedCommunicationOutcome',
  'lastLoggedCommunicationLoggedBy',
  'lastHraCompletedDate',
  'product',
  'effectiveDate',
  'termDate',
  'lastAdmit',
  'lastEr',
  'lastSnfAdmit',
]

const pageSizes = [25, 50]

const CaseManagerCensusGrid = ({
  userId,
  excludedColumnNames,
  rowActions,
  rowActionsCount,
  localSettingsKey,
  excludedListTypes,
  showListButton,
}) => {
  const census = useSelector(getCensus)

  const columns = useMemo(
    () => baseColumns.filter(({ name }) => !excludedColumnNames.includes(name)),
    [excludedColumnNames]
  )
  const rows = useMemo(() => census.toArray(), [census])

  const RowActions = useCallback(
    props => (
      <RowActionsBase
        showListButton={showListButton}
        rowActions={rowActions}
        {...props}
      />
    ),
    [rowActions]
  )

  const dialog = useSelector(getDialog)

  return (
    <React.Fragment>
      <Paper>
        <DevXGrid
          columns={columns}
          columnExtensions={columnExtensions}
          rows={rows}
          rowActions={RowActions}
          rowActionsCount={rowActionsCount + (showListButton ? 1 : 0)}
          defaultHiddenColumnNames={defaultHiddenColumnNames}
          detailComponent={PatientDetails}
          localSettingsKey={localSettingsKey}
          columnHideable
          columnReorderable
          filterable
          groupable
          pageable
          pageSizes={pageSizes}
          defaultPageSize={25}
          searchable
          sortable
          isVirtual={true}
        />
      </Paper>

      <AddListDialog
        userId={userId}
        excludedListTypes={excludedListTypes}
        open={dialog.dialogType === ADD_LIST_ITEM}
        patientId={dialog.patientId}
        patientName={dialog.patientName}
      />
    </React.Fragment>
  )
}

CaseManagerCensusGrid.propTypes = {
  excludedColumnNames: PropTypes.array,
  excludedListTypes: PropTypes.array,
  localSettingsKey: PropTypes.string,
  rowActions: PropTypes.elementType,
  rowActionsCount: PropTypes.number,
  showListButton: PropTypes.bool,
  userId: PropTypes.string,
}

CaseManagerCensusGrid.defaultProps = {
  excludedColumnNames: [],
  rowActionsCount: 0,
  showListButton: false,
}

export default CaseManagerCensusGrid
