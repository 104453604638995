import reduceReducers from 'reduce-reducers'
import { combineReducers } from 'redux-immutable'
import appointments from './data/appointments'
import assessments from './data/assessments'
import commonReducer from './data/common/reducer'
import currentAssessmentId from './data/currentAssessmentId'
import currentLegacyAssessmentId from './data/currentLegacyAssessmentId'
import currentTelephonicEncounterType from './data/currentTelephonicEncounterType'
import currentVirtualEncounterType from './data/currentVirtualEncounterType'
import legacyAssessments from './data/legacyAssessments'
import manifest from './data/manifest'
import patientContacts from './data/patientContacts'
import saveInfo from './data/saveInfo'
import sectionsInfo from './data/sectionsInfo'
import telehealthOS from './data/telehealthOS'
import telephonicEncounterTypes from './data/telephonicEncounterTypes'
import virtualEncounterTypes from './data/virtualEncounterTypes'
import key from './key'

const combinedReducers = combineReducers({
  [assessments.key]: assessments,
  [currentAssessmentId.key]: currentAssessmentId,
  [currentLegacyAssessmentId.key]: currentLegacyAssessmentId,
  [legacyAssessments.key]: legacyAssessments,
  [patientContacts.key]: patientContacts,
  [saveInfo.key]: saveInfo,
  [sectionsInfo.key]: sectionsInfo,
  [telehealthOS.key]: telehealthOS,
  [manifest.key]: manifest,
  [appointments.key]: appointments,
  [virtualEncounterTypes.key]: virtualEncounterTypes,
  [currentVirtualEncounterType.key]: currentVirtualEncounterType,
  [telephonicEncounterTypes.key]: telephonicEncounterTypes,
  [currentTelephonicEncounterType.key]: currentTelephonicEncounterType,
})

const reducer = reduceReducers(combinedReducers, commonReducer)

reducer.key = key

export default reducer
