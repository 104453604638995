import { ofType } from 'redux-observable'
import { concat, of } from 'rxjs'
import { catchError, mergeMap, pluck } from 'rxjs/operators'
import loading from '~/utils/loading'
import {
  ORDER_DETAIL_SAVE_ACTION_REQUESTED,
  applyAction,
  applyActionFailed,
  applyActionSucceeded,
  getOrderDetailNotes,
  noteChanged,
} from '../data/orderDetail'
import showSuccess from '../utils/showSuccess'

const combinedNote = (actionNote, action, state, id) => {
  const internalNote = getOrderDetailNotes(state, id)
  const actionString = action.label.toUpperCase()
  const note = actionNote ? actionNote : ''
  const combinedNote = `${internalNote}\n${actionString}: ${note}`
  return combinedNote
}
export default (action$, state$) =>
  action$.pipe(
    ofType(ORDER_DETAIL_SAVE_ACTION_REQUESTED),
    pluck('payload'),
    mergeMap(({ id, action, cancelReason, actionNote }) => {
      return loading(
        applyAction(id, action.key, cancelReason, actionNote).pipe(
          mergeMap(res => {
            const notes = combinedNote(actionNote, action, state$.value, id)
            return concat(
              of(applyActionSucceeded(res)),
              of(noteChanged(id, notes)),
              showSuccess(
                `${action.historyDescription}. Please refresh the table to view updates.`
              )
            )
          }),
          catchError(err => of(applyActionFailed(err)))
        ),
        `Could not ${action.label} order. Please try again.`
      )
    })
  )
