import { ImageProperties, jsPDF } from 'jspdf'
import React, { ChangeEvent, useRef, useState } from 'react'
// @ts-expect-error no export
import uuid from 'uuid/v4'
import { ActionDialog } from '~/components/dialogs'
import { Button, TextField, makeStyles } from '@material-ui/core'
import { Delete, Image } from '@material-ui/icons'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: 'grid',
    alignContent: 'center',
    alignItems: 'center',
  },
  fileContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(1),
  },
  fileList: {
    minHeight: spacing(30),
    marginTop: spacing(2),
  },
  fileName: {
    marginRight: spacing(1),
    marginLeft: spacing(1),
    maxWidth: spacing(42),
    minWidth: spacing(42),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  pdfName: {
    marginTop: spacing(2),
  },
  error: {
    marginLeft: spacing(1),
    color: palette.severity.error.static,
  },
}))

type Props = {
  disabled: boolean
  onChange: (file: File) => void
  fullWidth: boolean
  file: File
  margin: string
  open: boolean
  onClose: () => void
  imageLimit: number
}

const ImagePDF = (props: Props) => {
  const classes = useStyles()
  const [uploadedImages, setuploadedImages] = useState<File[]>([])
  const [error, setError] = useState('Required')
  const [fileLimit, setFileLimit] = useState(false)

  const fileInput = useRef<HTMLInputElement>(null)
  const fileNameInput = useRef<HTMLInputElement>(null)

  const { disabled, onChange, open, onClose, imageLimit } = props

  const onClick = () => {
    fileInput?.current?.click()
  }

  const handleUploadFiles = (files: File[]) => {
    const uploaded: File[] = [...uploadedImages]
    let limitExceeded = false
    files.map((file: File) => {
      const fileExtension = file.name.lastIndexOf('.')
      const fileName =
        `${file.name.substring(0, fileExtension)}_${uuid()}` +
        file.name.substring(fileExtension)
      const newFile = new File([file], fileName, { type: file.type })

      uploaded.push(newFile)
      if (uploaded.length > imageLimit) {
        setFileLimit(true)
        limitExceeded = true
      }
      if (fileInput && fileInput.current) fileInput.current.value = ''
    })
    if (!limitExceeded) setuploadedImages(uploaded)
  }

  const handleDeleteFile = (fileName: string) => {
    setuploadedImages(
      uploadedImages.filter((file: File) => file.name !== fileName)
    )
    if (fileInput && fileInput.current) fileInput.current.value = ''
    uploadedImages.length <= imageLimit && setFileLimit(false)
  }

  const handleOnChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    const chosenFiles = Array.prototype.slice.call(target.files)
    handleUploadFiles(chosenFiles)
  }

  const handleCreatePDF = () => {
    const pdf = new jsPDF()
    const imageReaders: any[] = []

    uploadedImages.map(file => {
      imageReaders.push(readAsDataURL(file))
    })

    Promise.all(imageReaders).then(images => {
      images.map((image, index) => {
        const {
          imagePositionX,
          imagePositionY,
          imageWidth,
          imageHeight,
        } = getImageProperties(pdf, pdf.getImageProperties(image))

        pdf.addImage(
          image,
          'PNG',
          imagePositionX,
          imagePositionY,
          imageWidth,
          imageHeight,
          '',
          'FAST'
        )
        if (images.length - 1 !== index) pdf.addPage('a4', 'p')
      })

      const newPdf = new File(
        [pdf.output('blob')],
        `${fileNameInput?.current?.value}.pdf`,
        { type: 'application/pdf' }
      )
      onChange(newPdf)
      onClose()
    })
  }

  const getImageProperties = (pdf: jsPDF, imageProps: ImageProperties) => {
    const margin = 0.05
    const pdfWidth = pdf.internal.pageSize.width * (1 - margin)
    const pdfHeight = pdf.internal.pageSize.height * (1 - margin)

    const imagePositionX = pdf.internal.pageSize.width * (margin / 2)
    const imagePositionY = pdf.internal.pageSize.height * (margin / 2)

    const widthRatio = pdfWidth / imageProps.width
    const heightRatio = pdfHeight / imageProps.height
    const ratio = Math.min(widthRatio, heightRatio)

    const imageWidth = imageProps.width * ratio
    const imageHeight = imageProps.height * ratio

    return { imagePositionX, imagePositionY, imageWidth, imageHeight }
  }

  const readAsDataURL = (file: File) => {
    return new Promise<string>(function (resolve, reject) {
      const fileReader = new FileReader()

      fileReader.onload = () => {
        resolve(fileReader.result as string)
      }

      fileReader.onerror = () => {
        reject(fileReader)
      }

      fileReader.readAsDataURL(file)
    })
  }

  const onChangeFileName = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    const re = new RegExp('^([a-zA-Z0-9-_]+)$')
    !re.test(newValue)
      ? setError('Type alphanumeric characters only')
      : setError('')
  }

  return (
    <ActionDialog
      open={open}
      title="Upload images"
      mode="create"
      onCreate={handleCreatePDF}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      disableAction={!!error || uploadedImages.length === 0}
    >
      <div className={classes.root}>
        <div>
          <Button
            color="primary"
            variant="contained"
            onClick={onClick}
            fullWidth
            disabled={disabled || fileLimit}
          >
            Browse Files
          </Button>
          <input
            id="fileUpload"
            ref={fileInput}
            type="file"
            onChange={handleOnChangeFile}
            disabled={disabled || fileLimit}
            accept="image/*"
            style={{ display: 'none' }}
          />
          {fileLimit && (
            <span className={classes.error}>
              You can only add a maximum of {imageLimit} files
            </span>
          )}
        </div>
        <div className={classes.fileList}>
          {uploadedImages.length === 0 ? (
            <span>No files selected</span>
          ) : (
            uploadedImages.map((file: File, index) => (
              <div key={index} className={classes.fileContainer}>
                <Image />
                <span className={classes.fileName}>{file?.name}</span>
                <Delete onClick={() => handleDeleteFile(file?.name)} />
              </div>
            ))
          )}
        </div>
        <div className={classes.pdfName}>
          <TextField
            type="text"
            placeholder={'Add a PDF name'}
            disabled={uploadedImages.length === 0}
            inputRef={fileNameInput}
            helperText={error}
            error={!!error}
            onChange={onChangeFileName}
            fullWidth
          />
        </div>
      </div>
    </ActionDialog>
  )
}

export default ImagePDF
