export enum NoteType {
  Patient = 'patient',
  Internal = 'internal',
  Carrier = 'carrier',
}
export const NO_RECORDS_FOUND = 'No Records Found'
export const DESTINATION = 'Destination'
export const PICKUP = 'Pickup'
export const CURRENT_YEAR = new Date().getFullYear()

export const BENEFIT_YEARS = [
  { value: CURRENT_YEAR + 2, label: CURRENT_YEAR + 2 },
  { value: CURRENT_YEAR + 1, label: CURRENT_YEAR + 1 },
  { value: CURRENT_YEAR, label: CURRENT_YEAR },
  { value: CURRENT_YEAR - 1, label: CURRENT_YEAR - 1 },
]

export const HAND_TO_HAND_TRIP_TYPE_GROUPS = ['H0147', 'H6229']
export const HAND_TO_HAND_TRIP_TYPES = [
  'WheelChair HandToHand',
  'Ambulatory HandToHand',
]

export const RECUR = 'recur'
export const FILES = 'files'
export const EDITING = 'editing'
export const EDITSTATUS = 'editStatus'
export const NO_TRIP_FOUND = 'No Trips Found'
export const LOADING_TRIP_INFO = 'Loading Trip Information'

export const STATUS_ACTIVE = 'Active'

export const formatTime = (time: string) => {
  const timeArr = time.split(':')
  let hours = parseInt(timeArr[0])
  const minutes = parseInt(timeArr[1])
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12
  const min = minutes < 10 ? `0${minutes}` : `${minutes}`
  const strTime = `${hours}:${min} ${ampm}`
  return strTime
}

export const PCP = 'PCP'
export const MEMBER_ADDRESS_TYPE = "Member's home address"
export const PRIMARY_CARE_PROVIDER = 'Primary Care Provider'
export const TEMPORARY_ADDRESS = 'Temporary Address'
export const PCP_MEMBER_ADDRESS_LOCATION_TYPES = [
  MEMBER_ADDRESS_TYPE,
  PRIMARY_CARE_PROVIDER,
  TEMPORARY_ADDRESS,
]
export const HOME = 'Home'
export const MAIL = 'Mail'
export const PHONE_REGEXP = new RegExp(/[^\d.]/g)
export const VA_MEMBER_GROUP_ID = 'H3447'

export const DFLT_CLASSPLAN_PRODUCT_OPTION = {
  classplanProductId: 'All',
  classplanProductName: '',
}

export const EMPTY_CLASSPLAN_PRODUCT_OPTION = {
  classplanProductId: '',
  classplanProductName: '',
}
