import { SOCKET_PATH } from 'ahc-config'
import { Socket } from 'phoenix/priv/static/phoenix'
import { Observable } from 'rxjs'
import {
  allowOverride,
  getId as getOverrideUserId,
  header as overrideHeader,
} from '~/features/userOverride'
import keycloak from '~/utils/security/keycloak'
import telemetry from '~/utils/telemetry'

const socketUrl = SOCKET_PATH
const phxBindings = ['phx_close', 'phx_error', 'phx_reply']

let socket = null
const subscribedChannels = {}

const createSocket = () =>
  new Promise(resolve => {
    // Disconnect existing socket
    if (socket) socket.disconnect()

    getOverrideUserId().then(overrideUserId => {
      socket = new Socket(
        socketUrl,
        allowOverride && overrideUserId
          ? { params: { [overrideHeader]: overrideUserId } }
          : undefined
      )

      socket.onOpen(() => {
        resolve()
      })

      socket.onClose(() => telemetry.warn('Socket Closed'))

      socket.connect()
    })
  })

const createOrGetSocket = () =>
  new Promise(resolve => {
    // Create socket with latest access token
    if (!socket || keycloak.isTokenExpired()) {
      return createSocket().then(() =>
        Promise.all(
          Object.values(subscribedChannels).map(({ topic, params, bindings }) =>
            joinChannel(topic, params, bindings)
          )
        ).then(resolve)
      )
    }
    // Socket is valid
    else {
      resolve()
    }
  })

const joinChannel = (topic, params, bindings = []) =>
  keycloak.token.then(
    token =>
      new Promise((resolve, reject) => {
        const channel = socket.channel(topic, { ...params, token })

        // Carry over existing listeners
        bindings
          .filter(binding => !phxBindings.includes(binding.event))
          .forEach(({ event, callback }) => channel.on(event, callback))

        subscribedChannels[topic] = channel

        channel.onError(e => {
          const error = new Error(`Channel Error: ${topic}`)
          telemetry.error(error, e)
        })
        channel
          .join()
          .receive('ok', () => {
            resolve(subscribedChannels[topic])
          })
          .receive('error', e => {
            const error = new Error(`Error Joining Channel: ${topic}`)
            telemetry.error(error, e)
            reject(error)
          })
          .receive('timeout', e => {
            const error = new Error(`Channel Timed Out: ${topic}`)
            telemetry.error(error, e)
            reject(error)
          })
      })
  )

const joinOrGetChannel = (topic, params) =>
  new Promise((resolve, reject) => {
    // Channel is subscribed and access token is valid
    if (subscribedChannels[topic] && !keycloak.isTokenExpired()) {
      resolve(subscribedChannels[topic])
    }
    // Channel is subscribed and access token is invalid
    else if (subscribedChannels[topic] && keycloak.isTokenExpired()) {
      return createOrGetSocket().then(() => resolve(subscribedChannels[topic]))
    }
    // Create channel
    else {
      return createOrGetSocket()
        .then(() => joinChannel(topic, params))
        .then(channel => resolve(channel))
        .catch(e => {
          const error = new Error('Socket Connection Error')
          telemetry.error(error, e)
          reject(error)
        })
    }
  })

const leaveChannel = topic => {
  if (subscribedChannels[topic]) {
    subscribedChannels[topic].leave()
    delete subscribedChannels[topic]
  }
}

const pushToChannel = (topic, eventType, data) =>
  joinOrGetChannel(topic).then(
    channel =>
      new Promise((resolve, reject) =>
        channel
          .push(eventType, data, 10000)
          .receive('ok', data => {
            resolve(data)
          })
          .receive('error', e => {
            const error = new Error('Failed to push to channel')
            telemetry.error(error, e)
            reject(error)
          })
          .receive('timeout', e => {
            const error = new Error('Networking issue while pushing to channel')
            telemetry.error(error, e)
            reject(error)
          })
      )
  )

const getChannelStream = (topic, eventType) =>
  Observable.create(observer => {
    joinOrGetChannel(topic)
      .then(channel => channel.on(eventType, data => observer.next(data)))
      .catch(error => observer.error(error))
  })

export { joinOrGetChannel, leaveChannel, pushToChannel, getChannelStream }
