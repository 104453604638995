import { ofType } from 'redux-observable'
import { push } from 'redux-routable'
import { of } from 'rxjs'
import { mergeMap, pluck } from 'rxjs/operators'
import { addCareTeam } from '../data/careTeams'
import { CARE_TEAM_MANAGEMENT_CARE_TEAM_DETAIL } from '../routes'
export default action$ =>
  action$.pipe(
    ofType(addCareTeam.SUCCEEDED),
    pluck('payload'),
    mergeMap(payload =>
      of(
        push(CARE_TEAM_MANAGEMENT_CARE_TEAM_DETAIL, {
          careTeamId: payload.id,
        })
      )
    )
  )
