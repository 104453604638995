import React from 'react'
import { connect } from 'react-redux'
import { compose } from '~/utils/functionalHelpers'
import PropTypes from '~/utils/propTypes'
import { Button, Icon } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { saveButtonPressed } from '../data/orderDetail'
import { getUnsavedChanges } from '../data/saveIndicator'

const styles = ({ palette, spacing }) => ({
  footer: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2),
    paddingTop: spacing(1),
    margin: -spacing(1) * 2,
    marginTop: 0,
    zIndex: 1,
    bottom: -spacing(1),
    position: 'sticky',
    backgroundColor: palette.background.default,
  },
})

const SaveButton = ({ classes, unsavedChanges, onSave, orderId, icon }) => (
  <React.Fragment>
    {icon ? (
      <div>
        <Button
          onClick={() => onSave(orderId)}
          color="primary"
          icon="save"
          variant="text"
          disabled={!unsavedChanges}
        >
          <Icon>save</Icon>
        </Button>
      </div>
    ) : (
      <div className={classes.footer}>
        <Button
          onClick={() => onSave(orderId)}
          color="primary"
          variant="contained"
          size="small"
          disabled={!unsavedChanges}
        >
          {unsavedChanges ? 'Save' : 'Saved'}
        </Button>
      </div>
    )}
  </React.Fragment>
)

SaveButton.propTypes = {
  classes: PropTypes.object.isRequired,
  unsavedChanges: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  orderId: PropTypes.number.isRequired,
  icon: PropTypes.bool,
}

SaveButton.defaultProps = {
  icon: false,
}

export default compose(
  connect(
    state => ({
      unsavedChanges: getUnsavedChanges(state),
    }),
    dispatch => ({
      onSave: id => dispatch(saveButtonPressed(id)),
    })
  ),
  withStyles(styles)
)(SaveButton)
