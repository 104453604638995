import { combineEpics } from 'redux-observable'
import { epic as offlineDrugs } from './data/offlineDrugs'
import clearPatientMedications from './epics/clearPatientMedications'
import directSSO from './epics/directSSO'
import drugSearch from './epics/drugSearch'
import getInteractionAlertsForSelectedDrug from './epics/getInteractionAlertsForSelectedDrug'
import initMedicationsFeature from './epics/initMedicationsFeature'
import medicationSelected from './epics/medicationSelected'
import saveAfterConnect from './epics/saveAfterConnect'
import savePatientMedication from './epics/savePatientMedication'
import successfulMedicationSave from './epics/successfulMedicationSave'
import transformAssessmentData from './epics/transformAssessmentData'

export default combineEpics(
  clearPatientMedications,
  directSSO,
  drugSearch,
  getInteractionAlertsForSelectedDrug,
  initMedicationsFeature,
  medicationSelected,
  offlineDrugs,
  saveAfterConnect,
  savePatientMedication,
  successfulMedicationSave,
  transformAssessmentData
)
