import { List, Map, Record } from 'immutable'
import { get as getIn } from 'lodash'
import moment from 'moment'
import { CareTeamMember } from '~/data/careTeams'
import {
  CareTeam,
  transformCareTeam,
} from '~/features/careTeamManagement/data/careTeams'
import { InsuranceCoverage } from '~/features/insuranceManagement/data/insuranceCoverages'
import AspireAPI from '~/resources/aspire'
import Request, { flattenErrors } from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import {
  action,
  creator,
  get,
  getIn as getAt,
  into,
  payload,
  type,
} from '~/utils/data'
import { formatName } from '~/utils/format'
import { pipe } from '~/utils/functionalHelpers'
import { CAREMORE_BRAND } from '~/utils/programInfo'

const key = 'patientInfo'

export const PATIENT_INFO_CLEARED = type(key, 'PATIENT_INFO_CLEARED')
export const PATIENT_INFO_STORED = type(key, 'PATIENT_INFO_STORED')
const PATIENT_INFO_UPDATED = type(key, 'PATIENT_INFO_UPDATED')

export const patientInfoCleared = creator(PATIENT_INFO_CLEARED)
export const patientInfoStored = creator(PATIENT_INFO_STORED, 'patientInfo')
export const patientInfoUpdated = creator(PATIENT_INFO_UPDATED, 'changes')

export const PATIENT_INFO_SAVE_REQUESTED = type(
  key,
  'PATIENT_INFO_SAVE_REQUESTED'
)
export const CONTACT_INFO_SAVE_REQUESTED = type(
  key,
  'CONTACT_INFO_SAVE_REQUESTED'
)

export const patientInfoSaveRequested = creator(
  PATIENT_INFO_SAVE_REQUESTED,
  'patientInfo'
)
export const contactInfoSaveRequested = creator(
  CONTACT_INFO_SAVE_REQUESTED,
  'contactInfo'
)

export const User = Record({
  id: null,
  name: null,
  phone: null,
})

export const AcpInfo = Record({
  carePreferences: null,
  goalsOfCare: null,
  hospiceEligible: null,
  providerPrognosis: null,
})

export const App = Record({
  active: null,
  id: null,
  market: null,
  name: null,
  phone: null,
  role: null,
  timeZone: null,
})

export const Aspire = Record({
  consentForService: null,
  consentForServiceDate: null,
  contactId: null,
  cqrHotspotter: null,
  dateEnrolled: null,
  globalMemberId: null,
  healthPlanSpecificPrograms: null,
  hotspotterDetails: null,
  hotspotterPlan: null,
  hotspotterPlanOther: null,
  id: null,
  internalSubstatus: null,
  primaryChannel: null,
  programEnrolled: null,
  riskLevel: null,
  secondaryChannel: null,
  status: null,
  substatus: null,
})

export const Attachment = Record({
  createdDate: null,
  displayName: null,
  id: null,
  link: null,
})

export const Clinical = Record({
  drugAllergiesDescription: null,
  hasDrugAllergies: null,
  herbalSupplements: null,
  hospitalizationsRatio: null,
  id: null,
  lastPpsScore: null,
  medicationConsent: null,
  medicationConsentDate: null,
  medicationNotes: null,
  primaryDiagnosis: null,
  primaryDiagnosisOther: null,
  secondaryDiagnosis: null,
  secondaryDiagnosisOther: null,
  tertiaryDiagnosis: null,
  tertiaryDiagnosisOther: null,
})

export const Contact = Record({
  active: null,
  contactName: null,
  createdAt: null,
  description: null,
  effectiveEnd: null,
  email: null,
  hipaaAuthExpiration: null,
  id: null,
  phiApprovedContact: false,
  phiVoicemailContact: false,
  phone: null,
  preferredNumber: false,
  relationship: null,
  type: null,
})

export const Demographics = Record({
  address: null,
  age: null,
  apartment: null,
  city: null,
  currentLocation: null,
  dateOfBirth: null,
  firstName: null,
  id: null,
  lastName: null,
  latitude: null,
  locationExact: null,
  locationType: null,
  longitude: null,
  maritalStatus: null,
  memberPlanId: null,
  middleName: null,
  name: null,
  neighborhood: null,
  nickname: null,
  organizationId: null,
  otherApartment: null,
  otherCity: null,
  otherLatitude: null,
  otherLongitude: null,
  otherPostalCode: null,
  otherState: null,
  otherStateCode: null,
  otherStreet: null,
  phone: null,
  postalCode: null,
  preferredEmail: null,
  preferredPhone: null,
  preferredPronoun: null,
  region: null,
  salutation: null,
  socialSecurityNumber: null,
  spokenLanguage: null,
  state: null,
  stateCode: null,
  street: null,
  suffix: null,
  title: null,
  timeZone: null,
  writtenLanguage: null,
  // ===> New demographics fields <====
  certEthnicity: null,
  ethnicityLabel: null,
  ethnicityExtra: [],
  ethnicityExtraLabel: null,
  race: [],
  raceLabel: null,
  certLanguage: null,
  languageLabel: null,
  genderIdentity: null,
  genderIdentityLabel: null,
  genderIdentityOther: null,
  languageNotes: null,
  sex: null,
  gender: null,
  sexLabel: null,
  sexualOrientation: null,
  sexualOrientationLabel: null,
  sexualOrientationOther: null,
  hpManagementLevel: null,
})

export const Discharge = Record({
  dateOfDeath: null,
  dischargeDate: null,
  dischargeReason: null,
  hospiceId: null,
  id: null,
  initialActiveDate: null,
  initialEnrolledDate: null,
  otherDischargeReason: null,
  reinstatementDate: null,
  reinstatementType: null,
})

export const FluShot = Record({
  fluShotReceived: null,
  fluShotCounseling: null,
  lastFluShotDate: null,
  lastFluShotCounselingDate: null,
  noFluShotReason: null,
  noFluShotReasonOther: null,
  completedOn: null,
})

export const General = Record({
  id: null,
  primaryCaregiver: null,
  primaryCaregiverOther: null,
  visitInstructions: null,
})

export const LastEncounter = Record({
  encounterId: null,
  type: null,
  label: null,
  dateOfService: null,
  providerName: null,
  providerId: null,
})

export const LastValuesFromEncounters = Record({
  carePreferences: null,
  goalsOfCareDocumentation: null,
  historyOfPresentIllness: null,
  hospiceEligible: null,
  primaryDecisionmaker: null,
  providerPrognosis: null,
})

export const Market = Record({
  leadPhysician: User(),
  city: null,
  faxNumber: null,
  hotlinePhone: null,
  id: null,
  localPhone: null,
  marketDirector: User(),
  marketToProgram: null,
  name: null,
  neighborhood: null,
  region: null,
  state: null,
  street: null,
})

export const NextScheduledVisit = Record({
  encounterType: null,
  type: null,
  date: null,
  provider: User(),
})

export const PatientCareTeam = Record({
  patientId: null,
  careTeamId: null,
  careTeam: CareTeam(),
})

// Lead Physicians are Users
export const Physician = Record({
  faxNumber: null,
  id: null,
  name: null,
  npiNumber: null,
  phone: null,
  speciality: null,
  practice: null,
})

export const PrimaryDecisionMaker = Record({
  hasScanAdvDirDocs: null,
  name: null,
  phoneNumber: null,
  relationship: null,
})

export const Referral = Record({
  id: null,
  referralFacilitator: null,
  referralListBatchId: null,
  mailingDate: null,
  mailingType: null,
  referralNote: null,
  referralSourceType: null,
  referredDate: null,
  receiptOfReferralDate: null,
  urgentReferral: null,
  timeZone: null,
})

export const ReferringEntity = Record({
  id: null,
  name: null,
  type: null,
})

export const Research = Record({
  caseNumber: null,
  dateOfService: null,
  description: '',
  id: null,
  shouldResetCallAttemptCount: null,
  status: null,
})

export const Scheduling = Record({
  lastCallDateTime: null,
  lastCallNotes: null,
  lastCallType: null,
  lastVisitDate: null,
  nextCallDate: null,
  appNtv: null,
  nextScheduledVisitId: null,
  nextScheduledVisit: NextScheduledVisit(),
  nextVisitStartTime: null,
})

export const Unengaged = Record({
  fromDate: null,
  reason: null,
  notes: null,
  id: null,
  open: null,
})

export const VirtualCarePlanning = Record({
  internetAccessAvailable: null,
  hasPreferredEmailOrPhone: null,
  patientId: null,
  preferredVideoApplications: null,
  preferredVideoApplicationsOther: null,
  videoDevicesAvailable: null,
  videoDevicesAvailableOther: null,
  videoAptInterest: null,
  deviceSetupOffered: null,
  deviceSetupComplete: null,
  virtualCapable: null,
})

export const Patient = Record({
  acpAttachments: null,
  acpInfo: AcpInfo(),
  address: null,
  app: App(),
  appId: null,
  aspire: Aspire(),
  brand: null,
  careTeam: Map(), // Association based on clinical.patient_care_team view Prefer PatientCareTeam
  careTeamId: null,
  caseManager: User(),
  channel: null,
  clinical: Clinical(),
  contacts: List(),
  coverages: List(),
  createdBy: null,
  createdById: null,
  createdDate: null,
  currentResearchCase: Research(),
  daysSinceLastVisit: null,
  demographics: Demographics(), // @deprecated
  discharge: Discharge(),
  emergencyResponseDevice: null,
  empiKey: null,
  fluShot: List(),
  general: General(),
  id: null,
  insuranceCoverage: null,
  isCaseMgmt: null,
  lastAssessmentId: null,
  lastChwCheckInEncounterId: null,
  lastCmrnEncounterId: null,
  lastCssIntakeEncounterId: null,
  lastEncounterId: null,
  lastEncounter: LastEncounter(),
  lastEncounters: Map(),
  lastLegacyAssessmentId: null,
  lastLegacySwCaseId: null,
  lastModifiedBy: null,
  lastModifiedById: null,
  lastModifiedDate: null,
  lastSwEncounterId: null,
  lastTelephonicEncounterId: null,
  lastValuesFromEncounters: LastValuesFromEncounters(),
  market: Market(),
  marketCode: null,
  marketId: null,
  paneledProvider: null,
  patientCareTeam: PatientCareTeam(), // New Association based on aspire.patient_care_team.
  photo: null,
  primaryCarePhysician: Physician(),
  primaryCarePhysicianId: null,
  primaryChannelCarrier: null,
  primaryInsuranceCarrier: null,
  primaryInsuranceName: null,
  primaryInsuranceNumber: null,
  programEnrolled: null,
  referral: Referral(),
  referringEntity: ReferringEntity(),
  referringEntityId: null,
  referringPhysician: Physician(),
  referringPhysicianId: null,
  research: Research(),
  riskColor: null,
  riskColorUpdated: null,
  scheduling: Scheduling(),
  subscriberId: null,
  unengaged: Unengaged(),
  virtualCarePlanning: VirtualCarePlanning(),
})

const transformAttachments = attachments =>
  Array.isArray(attachments) ? List(attachments.map(Attachment)) : List()

const transformContacts = contacts =>
  Array.isArray(contacts) ? List(contacts.map(Contact)) : List()

const transformCoverages = coverages =>
  Array.isArray(coverages) ? List(coverages.map(InsuranceCoverage)) : List()

const transformFluShots = fluShots =>
  Array.isArray(fluShots)
    ? List(fluShots.map(FluShot)).sortBy(get('completedOn'), (a, b) =>
        a < b ? 1 : -1
      )
    : List()

const transformLastEncounters = lastEncounters =>
  Array.isArray(lastEncounters)
    ? into(LastEncounter, 'type')(lastEncounters)
    : Map()

const setPatientLastEncounter = lastEncounters =>
  transformLastEncounters(lastEncounters)
    .toList()
    .filter(get('dateOfService'))
    .sortBy(get('dateOfService'), (a, b) => (a < b ? 1 : -1))
    .first() || LastEncounter()

// Association based on clinical.patient_care_team view
const transformCareTeamMembers = careTeamMembers =>
  Array.isArray(careTeamMembers)
    ? into(CareTeamMember, 'role')(careTeamMembers)
    : Map()

const transformLastValuesFromEncounters = (lastValuesFromEncounters = {}) =>
  LastValuesFromEncounters({
    ...lastValuesFromEncounters,
    leadPhysician: PrimaryDecisionMaker(
      lastValuesFromEncounters.primaryDecisionMaker
    ),
  })

const transformMarket = (market = {}) =>
  Market({
    ...market,
    leadPhysician: User(market.leadPhysician),
    marketDirector: User(market.marketDirector),
  })

const transformPatientCareTeam = pct => {
  const patientCareTeam = pct || {}
  return PatientCareTeam({
    ...patientCareTeam,
    careTeam: transformCareTeam(patientCareTeam.careTeam),
  })
}

const transformScheduling = (scheduling = {}) => {
  const nextVisit = NextScheduledVisit({
    encounterType: getIn(scheduling, ['nextScheduledVisit', 'encounterType']),
    type: getIn(scheduling, ['nextScheduledVisit', 'type']),
    date: getIn(scheduling, ['nextScheduledVisit', 'date']),
    provider: User(getIn(scheduling, ['nextScheduledVisit', 'provider'])),
  })
  return Scheduling({
    lastCallDateTime: scheduling.lastCallDateTime,
    lastCallNotes: scheduling.lastCallNotes,
    lastCallType: scheduling.lastCallType,
    lastVisitDate: scheduling.lastVisitDate,
    nextCallDate: scheduling.nextCallDate,
    appNtv: scheduling.appNtv,
    nextScheduledVisitId: scheduling.nextScheduledVisitId,
    nextScheduledVisit: nextVisit,
    nextVisitStartTime: scheduling.nextVisitStartTime,
  })
}

const transformLastVisitDate = (lastEncounters = {}) => {
  const lastEncounter = setPatientLastEncounter(lastEncounters)

  return lastEncounter.dateOfService
    ? moment().diff(moment(lastEncounter.dateOfService), 'days')
    : null
}

const transformVirtualCarePlanning = (
  virtualCare,
  preferredEmail,
  preferredNumber,
  textingApproved
) => {
  const hasPreferredEmailOrPhone =
    !!preferredEmail || (!!preferredNumber && textingApproved)
  return virtualCare
    ? VirtualCarePlanning({
        virtualCapable:
          virtualCare.internetAccessAvailable?.length >= 1 &&
          virtualCare.videoDevicesAvailable?.length >= 1 &&
          hasPreferredEmailOrPhone,
        hasPreferredEmailOrPhone,
        internetAccessAvailable: virtualCare.internetAccessAvailable,
        patientId: virtualCare.patientId,
        preferredVideoApplications: virtualCare.preferredVideoApplications,
        preferredVideoApplicationsOther:
          virtualCare.preferredVideoApplicationsOther,
        videoDevicesAvailable: virtualCare.videoDevicesAvailable,
        videoDevicesAvailableOther: virtualCare.videoDevicesAvailableOther,
        videoAptInterest: virtualCare.videoAptInterest,
        deviceSetupOffered: virtualCare.deviceSetupOffered,
        deviceSetupComplete: virtualCare.deviceSetupComplete,
      })
    : VirtualCarePlanning({ hasPreferredEmailOrPhone })
}

export const transformPatient = patient =>
  Patient({
    acpAttachments: transformAttachments(patient.acpAttachments),
    acpInfo: AcpInfo(patient.acpInfo),
    app: App(patient.app),
    appId: patient.appId,
    aspire: Aspire(patient.aspire),
    brand: patient.brand,
    careTeam: transformCareTeamMembers(patient.careTeam),
    careTeamId: patient.careTeamId,
    caseManager: User(patient.caseManager),
    clinical: Clinical(patient.clinical),
    contacts: transformContacts(patient.contacts),
    coverages: transformCoverages(patient.coverages),
    createdBy: patient.createdBy && patient.createdBy.name,
    createdById: patient.createdById,
    createdDate: patient.createdDate,
    currentResearchCase: Research(patient.currentResearchCase),
    daysSinceLastVisit: transformLastVisitDate(patient.lastEncounters),
    demographics: Demographics(patient.demographics),
    discharge: Discharge(patient.discharge),
    empiKey: patient.empiKey,
    fluShot: transformFluShots(patient.fluShot),
    general: General(patient.general),

    id: patient.id,
    isCaseMgmt: patient.isCaseMgmt,
    lastAssessmentId: patient.lastEncounterId,
    lastChwCheckInEncounterId: patient.lastChwCheckInEncounterId,
    lastCmrnEncounterId: patient.lastCmrnEncounterId,
    lastCssIntakeEncounterId: patient.lastCssIntakeEncounterId,
    lastValuesFromEncounters: transformLastValuesFromEncounters(
      patient.lastValuesFromEncounters
    ),
    lastEncounter: setPatientLastEncounter(patient.lastEncounters),
    lastEncounters: transformLastEncounters(patient.lastEncounters),
    lastLegacyAssessmentId: patient.lastLegacyAssessmentId,
    lastLegacySwCaseId: patient.lastLegacySwCaseId,
    lastModifiedBy: patient.lastModifiedBy && patient.lastModifiedBy.name,
    lastModifiedById: patient.lastModifiedById,
    lastModifiedDate: patient.lastModifiedDate,
    lastSwEncounterId: patient.lastSwEncounterId,
    market: transformMarket(patient.market),
    marketCode: patient.marketCode ? patient.marketCode : 'CLASSIC',
    marketId: patient.marketId,
    paneledProvider: patient.paneledProvider,
    patientCareTeam: transformPatientCareTeam(patient.patientCareTeam),
    primaryCarePhysician: Physician(patient.primaryCarePhysician),
    primaryCarePhysicianId: patient.primaryCarePhysicianId,
    primaryChannelCarrier: patient.primaryChannelCarrier,
    primaryInsuranceCarrier: patient.primaryInsuranceCarrier,
    primaryInsuranceName: patient.primaryInsuranceName,
    primaryInsuranceNumber: patient.primaryInsuranceNumber,
    programEnrolled: patient.programEnrolled,
    referral: Referral(patient.referral),
    referringEntity: ReferringEntity(patient.referringEntity),
    referringEntityId: patient.referringEntityId,
    referringPhysician: Physician(patient.referringPhysician),
    referringPhysicianId: patient.referringPhysicianId,
    research: Research(patient.research),
    riskColor: patient.riskColorInitial,
    riskColorUpdated: patient.riskColorUpdated,
    scheduling: transformScheduling(patient.scheduling),
    subscriberId: patient.subscriberId,
    unengaged: Unengaged(patient.unengaged),
    virtualCarePlanning: transformVirtualCarePlanning(
      patient.virtualCarePlanning,
      patient.demographics.preferredEmail,
      patient.demographics.preferredPhone,
      patient.demographics.preferredContact?.primaryPhone?.textingApproved
    ),
  })

export const fetchPatient = Request({
  typePrefix: key,
  typeBase: 'FETCH_PATIENT',
  requestParams: ['patientId'],
  operation: patientId => AspireAPI.get(`v1/patient/${patientId}`),
  transform: transformPatient,
})

export const savePatientInfoApi = (patientId, changes) =>
  AspireAPI.put(`v1/patient/${patientId}`, {
    patient_info: changes,
  })

export const savePatientInfo = Request({
  typePrefix: key,
  typeBase: 'SAVE',
  requestParams: ['patientId', 'changes'],
  operation: savePatientInfoApi,
  messages: {
    succeeded: 'Patient changes successfully saved',
    failed: e =>
      flattenErrors(
        getIn(e, 'response.data.message') ||
          'There was a problem saving changes to the Patient Record'
      ),
  },
})

export const saveContactInfo = (patientId, changes) =>
  AspireAPI.put(`v1/patient/${patientId}/contact_info`, changes)

export default createReducer(key, Patient(), {
  [fetchPatient.SUCCEEDED]: pipe(action, payload),
  [PATIENT_INFO_CLEARED]: () => Patient(),
  [PATIENT_INFO_STORED]: (_state, { payload }) => payload.patientInfo,
  [PATIENT_INFO_UPDATED]: (state, { payload }) =>
    state.mergeDeep(payload.changes),
})

export const getPatientInfo = pipe(get(key))

export const getPatientId = pipe(getPatientInfo, get('id'))

export const getCurrentResearchCase = pipe(
  getPatientInfo,
  get('currentResearchCase')
)

export const getIsCaseMgmt = pipe(getPatientInfo, get('isCaseMgmt'))

export const getEmpiKey = pipe(getPatientInfo, get('empiKey'))

export const getLastPatientAssessmentId = pipe(
  getPatientInfo,
  get('lastAssessmentId')
)

export const getResearch = pipe(getPatientInfo, get('research'))

export const getUnengaged = pipe(getPatientInfo, get('unengaged'))

export const getPatientName = pipe(
  getPatientInfo,
  get('demographics'),
  formatName
)

export const getPatientLastEncounters = pipe(
  getPatientInfo,
  get('lastEncounters')
)

export const getPatientLastEncounter = pipe(
  getPatientLastEncounters,
  lastEncounters =>
    lastEncounters
      .toList()
      .filter(get('dateOfService'))
      .sortBy(get('dateOfService'), (a, b) => (a < b ? 1 : -1))
      .first() || LastEncounter()
)

export const getPatientLastEncounterId = pipe(
  getPatientLastEncounter,
  getAt(['id'])
)

export const getPatientStatus = pipe(
  getPatientInfo,
  getAt(['aspire', 'status'])
)

export const getPatientBrand = pipe(getPatientInfo, get('brand'))

export const getProgramEnrolled = pipe(
  getPatientInfo,
  getAt(['aspire', 'programEnrolled'])
)

export const getPatientInfoProp = (state, key) =>
  pipe(getPatientInfo, get(key))(state)

export const getIsCaremoreBrand = state => {
  const brand = getPatientBrand(state)
  if (brand) {
    return brand === CAREMORE_BRAND
  }
  return false
}

export const getMarketToProgram = state => {
  const programEnrolled = getProgramEnrolled(state)
  const marketToPrograms = pipe(
    getPatientInfo,
    getAt(['market', 'marketToProgram'])
  )(state)

  const transformModuleNameToSentence = program =>
    program
      .split('.')
      .pop()
      .replace(/([A-Z])/g, ' $1')
      .trim()

  return marketToPrograms?.filter(
    mtp => transformModuleNameToSentence(mtp.program) === programEnrolled
  )[0]
}
