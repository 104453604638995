import cx from 'classnames'
import React from 'react'
import PropTypes from '~/utils/propTypes'
import { withStyles } from '@material-ui/core/styles'
import RiskLevel from './RiskLevel'
import RiskLevelIcon from './RiskLevelIcon'

const styles = {
  flex: {
    display: 'inline-flex',
    alignItems: 'center',
  },
}

const RiskLevelDisplay = ({ score = 4, classes, className, overview }) => {
  return (
    <span className={cx(classes.flex, className)}>
      <RiskLevelIcon overview={overview} score={score} />
      <RiskLevel score={score} />
    </span>
  )
}

RiskLevelDisplay.propTypes = {
  score: PropTypes.number,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  overview: PropTypes.bool,
}

RiskLevelDisplay.defaultProps = {
  className: '',
  overview: false,
}

export default withStyles(styles)(RiskLevelDisplay)
