import cx from 'classnames'
import React from 'react'
import PropTypes from '~/utils/propTypes'
import Icon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles'
import getRiskLevel from '../utils/riskLevelValues'

const styles = ({ palette }) => ({
  withScore: {
    color: ({ score }) => {
      const finalScore = score === null ? 4 : score
      return palette.riskLevel[finalScore]
    },
    marginRight: 10,
  },
})

const RiskLevelIcon = ({ score, classes, className, overview }) => {
  const { icon, overviewIcon } = getRiskLevel(score)

  return (
    <Icon
      classes={{
        root: cx(className, {
          [classes.withScore]: Boolean(score) || score === null,
          [classes.withoutScore]: !score,
        }),
      }}
    >
      {overview ? overviewIcon : icon}
    </Icon>
  )
}

RiskLevelIcon.propTypes = {
  score: PropTypes.number,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  overview: PropTypes.bool,
}

RiskLevelIcon.defaultProps = {
  className: '',
  score: 0,
}

export default withStyles(styles)(RiskLevelIcon)
