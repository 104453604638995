// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import rootKey from '../key'
import { updateDischargeRecommendation } from './discharges'

// KEY
const key = 'recommendationUpdateDialog'

export const getDischargeRecEditDialogId = getIn([rootKey, key])

// CREATORS
const creator = scopedCreator(key)

export const editDischargeRecDialogIdChanged = creator(
  'EDITABLE_DISCHARGE_REC_DIALOG_CASE_ID_CHANGED'
)

// REDUCER
export default createReducer(key, null, {
  // @ts-expect-error ts-migrate(6133) FIXME: 'state' is declared but its value is never read.
  [editDischargeRecDialogIdChanged]: (state: any, { payload }: any) =>
    payload || null,
  [updateDischargeRecommendation.SUCCEEDED]: () => null,
})
