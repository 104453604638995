import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  getPatientContactFormAddresses,
  getPatientContactFormEmails,
  getPatientContactFormPhones,
  patientContactFormAddressesCreator,
  patientContactFormEmailsCreator,
  patientContactFormPhonesCreator,
} from '~/apps/patientRecord/data/patientContactForm'
import { getFieldValues } from '~/data/fieldValues'
import { useAction } from '~/hooks'
// @ts-expect-error ts-migrate(6133) FIXME: Could not find declaration file for module... Remove this comment to see the full error message
import PropTypes from '~/utils/propTypes'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Tooltip from '@material-ui/core/Tooltip'
import {
  addContact,
  addContactDialogClosed,
  getDialogOpenValue,
  updateContact,
} from '../../../data/patientContactDialog'
import { patientContactFormUseStyles } from '../utils/utils'
import { PatientAddressForm } from './PatientAddressForm'
import { PatientEmailForm } from './PatientEmailForm'
import { PatientPhoneForm } from './PatientPhoneForm'
interface PatientContactFormPropTypes {
  contact: PropTypes.object.isRequired
  actionType: PropTypes.string.isRequired
  patientId: PropTypes.string.isRequired
  sourceIsAssessment: PropTypes.boolean
  onChange: PropTypes.func
}

export const PatientContactForm = ({
  contact,
  actionType,
  patientId,
  sourceIsAssessment = false,
  onChange,
}: PatientContactFormPropTypes) => {
  const classes = patientContactFormUseStyles()
  const getContactValues = (contact: any) => {
    return {
      id: contact?.id || '0',
      patientId: contact?.patientId,
      type: contact?.type || '',
      name: contact?.name || '',
      relationship: contact?.relationship || '',
      notes: contact?.notes || '',
      preferred: contact?.preferred || false,
      decisionMaker: contact?.decisionMaker || false,
      scheduling: contact?.scheduling || false,
      phones: contact?.phones || [],
      emails: contact?.emails || [],
      addresses: contact?.addresses || [],
      emailAsked: contact?.emailAsked || false,
    }
  }
  const { control, handleSubmit, getValues, setValue } = useForm({
    defaultValues: getContactValues(contact),
  })

  const setPhones = useAction(patientContactFormPhonesCreator)
  const setEmails = useAction(patientContactFormEmailsCreator)
  const setAddresses = useAction(patientContactFormAddressesCreator)
  const saveContact = useAction(updateContact.requested)
  const addNewContact = useAction(addContact.requested)
  const onClose = useAction(addContactDialogClosed)
  const relationTypeFields = useSelector(getFieldValues(['contact_type']))

  const phones = useSelector(getPatientContactFormPhones)
  const emails = useSelector(getPatientContactFormEmails)
  const addresses: any = useSelector(getPatientContactFormAddresses)
  const open: boolean = useSelector(getDialogOpenValue)

  const [validateErrorMsg, setValidateErrorMsg] = useState('')
  const addressesRef = useRef(addresses)

  useEffect(() => {
    const selectedContact = getContactValues(contact)

    setPhones(
      selectedContact && selectedContact.phones ? selectedContact.phones : []
    )

    setEmails(
      selectedContact && selectedContact.emails ? selectedContact.emails : []
    )

    setAddresses(
      selectedContact && selectedContact.addresses
        ? selectedContact.addresses
        : []
    )
    addressesRef.current =
      selectedContact && selectedContact.addresses
        ? selectedContact.addresses
        : []

    setValue('id', selectedContact?.id)
    setValue('patientId', selectedContact?.patientId)
    setValue('type', selectedContact?.type)
    setValue('name', selectedContact?.name)
    setValue('relationship', selectedContact?.relationship)
    setValue('notes', selectedContact?.notes)
    setValue('phones', selectedContact?.phones)
    setValue('emails', selectedContact?.emails)
    setValue('addresses', selectedContact?.addresses)
    setValue('scheduling', selectedContact?.scheduling)
    setValue('emailAsked', selectedContact?.emailAsked)
  }, [contact])

  const handleClose = () => {
    clearHandler()
    onClose()
  }

  const clearHandler = () => {
    setPhones([])
    setEmails([])
    setAddresses([])
    setValidateErrorMsg('')
  }

  const handleAddressFieldChange = () => {
    setValidateErrorMsg('')
  }

  const addPhone = () => {
    const indexes = phones.map((el: any) => el.index)
    const index = phones && phones.length ? Math.max(...indexes) + 1 : 1

    const addPhones: any = [
      ...phones,
      {
        id: null,
        primary: !(phones && phones.length),
        active: true,
        delete: false,
        textingApproved: false,
        index: index,
      },
    ]

    setPhones(addPhones)
    setValidateErrorMsg('')
  }

  const displayPhones = (phoneArr: any) => {
    return phoneArr
      ?.filter((p: any) => !p.delete)
      .map((phone: any) => (
        <PatientPhoneForm phone={phone} control={control} key={phone.index} />
      ))
  }

  const addEmail = () => {
    const indexes = emails.map((el: any) => el.index)
    const index = emails && emails.length ? Math.max(...indexes) + 1 : 1

    const addmails: any = [
      ...emails,
      {
        id: null,
        primary: !(emails && emails.length),
        active: true,
        delete: false,
        index: index,
      },
    ]

    setEmails(addmails)
    setValidateErrorMsg('')
  }

  const displayEmails = (emailArr: any) => {
    return emailArr
      ?.filter((e: any) => !e.delete)
      .map((email: any) => (
        <PatientEmailForm email={email} control={control} key={email.index} />
      ))
  }

  const validateAddressesForm = (addresses: any): boolean => {
    if (addresses && addresses.length) {
      const address = addresses.some(
        (address: any) => address.location && !address.location.postalCode
      )
      const errorMessage = address ? 'Some values are missing' : ''
      setValidateErrorMsg(errorMessage)
      return !errorMessage
    }
    return true
  }

  const validate = () => {
    const errorMessage =
      phones?.filter((p: any) => p.primary).length == 0 &&
      emails?.filter((e: any) => e.primary).length === 0
        ? `Must have 1 primary phone or email`
        : ''

    setValidateErrorMsg(errorMessage)

    return errorMessage ? false : validateAddressesForm(addresses)
  }

  const onSubmit = () => {
    if (validate()) {
      const contactSave = getValues()
      if (actionType === 'Add') {
        const contactData = {
          id: null,
          patientId: patientId,
          name: contactSave.name,
          type: contactSave.type?.toLowerCase(),
          relationship: contactSave.relationship,
          notes: contactSave.notes,
          preferred: false,
          decisionMaker: false,
          delete: false,
          scheduling: contactSave.scheduling,
          phones: getPhones(),
          emails: emails,
          addresses: addresses,
          emailAsked: contactSave.emailAsked,
        }
        sourceIsAssessment
          ? onChange(contactData)
          : addNewContact(patientId, contactData)
      } else if (actionType === 'Edit') {
        const contactData = {
          id: contact.id,
          patientId: patientId,
          name: contactSave.name,
          type: contactSave.type?.toLowerCase(),
          relationship: contactSave.relationship,
          notes: contactSave.notes,
          preferred: contact.preferred,
          decisionMaker: contact.decisionMaker,
          scheduling: contactSave.scheduling,
          phones: getPhones(),
          emails: emails,
          addresses: addresses,
          emailAsked: contactSave.emailAsked,
        }
        sourceIsAssessment
          ? onChange(contactData)
          : saveContact(patientId, contact.id, contactData)
      }
      handleClose()
    }
  }

  const getPhones = () => {
    return phones.map((phone: any) => ({
      active: phone.active,
      delete: phone.delete,
      id: phone.id,
      phone: phone.phone?.replace(/\D+/g, ''),
      primary: phone.primary,
      textingApproved: phone.textingApproved,
      type: phone.type,
    }))
  }

  return (
    <div>
      <Dialog
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        maxWidth="md"
        fullWidth
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Patient Contact</DialogTitle>
        <DialogContent>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Box className={classes.primaryBox}>
              <Box className={classes.textFieldBox}>
                <Controller
                  as={TextField}
                  control={control}
                  name="name"
                  variant="filled"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  rules={{
                    validate: value => {
                      if (!value && getValues('type') != 'patient') return false
                      return true
                    },
                  }}
                  label="Name"
                  fullWidth
                  required={getValues('type') != 'patient'}
                ></Controller>
              </Box>

              <Box className={classes.textFieldBox}>
                <Controller
                  as={TextField}
                  control={control}
                  name="relationship"
                  variant="filled"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  label="Relationship"
                  fullWidth
                ></Controller>
              </Box>

              <Box>
                <Controller
                  as={TextField}
                  control={control}
                  name="type"
                  variant="filled"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  rules={{ required: 'Please fill out this field.' }}
                  label="Type"
                  fullWidth
                  select
                  SelectProps={{ native: true }}
                  required={true}
                >
                  <option value="" key="" />
                  {relationTypeFields.entrySeq().map(([k, v]: any) => (
                    <option value={v.value} key={k}>
                      {v.label}
                    </option>
                  ))}
                </Controller>
              </Box>
            </Box>

            <Box className={classes.primaryBox}>
              <Controller
                as={TextField}
                control={control}
                name="notes"
                variant="filled"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                label="Notes"
                fullWidth
              ></Controller>
            </Box>

            <Box className={classes.primaryBox}>
              <Box className={classes.textFieldBox}>
                <Tooltip title="Can contact for scheduling">
                  <FormControlLabel
                    control={
                      <Controller
                        name="scheduling"
                        control={control}
                        render={({ value, onChange }) => (
                          <Checkbox
                            color="secondary"
                            onChange={(_e, checked) => onChange(checked)}
                            checked={value}
                          />
                        )}
                      />
                    }
                    label="Scheduling Contact"
                  />
                </Tooltip>
              </Box>

              <Box className={classes.textFieldBox}>
                <Tooltip title="Asked about having a contact email">
                  <FormControlLabel
                    control={
                      <Controller
                        name="emailAsked"
                        control={control}
                        render={({ value, onChange }) => (
                          <Checkbox
                            color="secondary"
                            onChange={(_e, checked) => onChange(checked)}
                            checked={value}
                          />
                        )}
                      />
                    }
                    label="Email Asked"
                  />
                </Tooltip>
              </Box>
            </Box>

            <Grid>
              <Button
                onClick={() => {
                  addPhone()
                }}
                id="addPhone"
              >
                ADD PHONE
              </Button>
              {displayPhones(phones)}
            </Grid>
            <Grid>
              <Button
                onClick={() => {
                  addEmail()
                }}
                id="addEmail"
              >
                ADD EMAIL
              </Button>
              {displayEmails(emails)}
            </Grid>
            <Grid>
              <PatientAddressForm
                addressList={addressesRef.current}
                control={control}
                handleAddressFieldChange={handleAddressFieldChange}
              />
            </Grid>
            <Box
              display="flex"
              flexDirection="row-reverse"
              justifyContent="space-between"
              alignItems="center"
              paddingLeft={1}
            >
              <Button color="primary" type="submit">
                Submit
              </Button>
              {validateErrorMsg ? (
                <Typography color="error">{validateErrorMsg}</Typography>
              ) : null}
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}
