import classnames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { AttachmentsGrid } from '~/features/attachments'
import { PatientTimelineMinimal } from '~/features/patientTimeline/containers/PatientTimelineMinimal'
import { useTabs } from '~/hooks'
import { Badge, Icon, Tab, Tabs, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getCurrentPatientId } from '../../data/currentPatientId'
import { getHealthplanAlerts } from '../../data/healthplanAlerts'
import { getPatientAlerts } from '../../data/patientAlerts'
import { getSchedulingAlerts } from '../../data/schedulingAlerts'
import Alerts from './Alerts'
import CareTeam from './CareTeam'
import Contacts from './Contacts'
import Demographics from './Demographics'
import Scheduling from './Scheduling'

const DEMOGRAPHICS = 'demographics'
const ALERTS = 'alerts'
const CONTACTS = 'contacts'
const ATTACHMENTS = 'attachments'
const SCHEDULING = 'scheduling'
const HISTORY = 'history'
const CARE_TEAM = 'careTeam'

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    height: 'calc(100% - 42px)',
  },
  tab: {
    minWidth: 95,
  },
  alertIcon: {
    marginRight: spacing(0.5),
  },
  alertLabel: {
    marginRight: spacing(1.5),
  },
  content: {
    height: 'calc(100% - 52px)',
    overflowY: 'scroll',
    padding: spacing(1),
  },
}))

const PatientInfo = () => {
  const classes = useStyles()

  const patientId = useSelector(getCurrentPatientId)
  const patientAlerts = useSelector(getPatientAlerts)
  const healthplanAlerts = useSelector(getHealthplanAlerts)
  const schedulingAlerts = useSelector(getSchedulingAlerts)
  const alertCount =
    patientAlerts.count() + healthplanAlerts.count() + schedulingAlerts.count()

  const [tab, setTab] = useTabs(DEMOGRAPHICS)

  return patientId ? (
    <div className={classes.container}>
      <Tabs value={tab} onChange={setTab}>
        <Tab
          className={classes.tab}
          value={DEMOGRAPHICS}
          label="Demographics"
        />
        <Tab
          className={classes.tab}
          value={ALERTS}
          label={
            <Badge badgeContent={alertCount} color="secondary">
              {alertCount > 0 && (
                <Icon
                  className={classes.alertIcon}
                  fontSize="small"
                  color="secondary"
                >
                  warning
                </Icon>
              )}
              <span
                className={classnames({
                  [classes.alertLabel]: alertCount > 0,
                })}
              >
                Alerts
              </span>
            </Badge>
          }
        />
        <Tab className={classes.tab} value={CONTACTS} label="Contacts" />
        <Tab className={classes.tab} value={ATTACHMENTS} label="Attachments" />
        <Tab className={classes.tab} value={SCHEDULING} label="Scheduling" />
        <Tab className={classes.tab} value={HISTORY} label="History" />
        <Tab className={classes.tab} value={CARE_TEAM} label="Care Team" />
      </Tabs>

      <div className={classes.content}>
        {tab === DEMOGRAPHICS && <Demographics />}
        {tab === ALERTS && <Alerts />}
        {tab === CONTACTS && <Contacts />}
        {tab === ATTACHMENTS && <AttachmentsGrid />}
        {tab === SCHEDULING && <Scheduling />}
        {tab === HISTORY && <PatientTimelineMinimal />}
        {tab === CARE_TEAM && <CareTeam />}
      </div>
    </div>
  ) : (
    <Typography variant="subtitle1">
      Select a patient to view their info
    </Typography>
  )
}

export default PatientInfo
