import { combineEpics } from 'redux-observable'
import { entered, exited } from 'redux-routable'
import { filter, mapTo } from 'rxjs/operators'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
import { patientInfoCleared } from '~/features/patientInfo'
import router from '../router'

const enter = action$ =>
  action$.pipe(
    filter(entered(router)),
    mapTo(
      fetchDistinctFieldValues([
        'encounter_assessment_status',
        'contact_type',
        'contact_address_type',
        'contact_phone_type',
        'contact_email_type',
      ])
    )
  )

const exit = action$ =>
  action$.pipe(filter(exited(router)), mapTo(patientInfoCleared()))

export default combineEpics(enter, exit)
