import { Record } from 'immutable'
import { get, scopedCreator } from '~/utils/data'
import key from '../../key'

export const Stakeholder = Record({
  id: null,
  sfid: null,
  contactId: null,
  name: null,
  specialty: null,
  phone: null,
  faxNumber: null,
  street: null,
  city: null,
  state: null,
  postalCode: null,
  title: null,
  practiceName: null,
  physicianNotes: null,
  reportTypes: null,
  type: null,
  createdBy: null,
  createdAt: null,
})

export const stakeholdersInitialized = scopedCreator(key)(
  'STAKEHOLDERS_INITIALIZED'
)

export const getRoot = get(key)
