import React, { PureComponent } from 'react'
import {
  WithAuthorized,
  actions as permissionActions,
} from '~/features/authorization'
import PropTypes from '~/utils/propTypes'
import { Button, Icon, Tooltip, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import OrdersSaveButton from '../containers/OrdersSaveButton'
import ActionDialog from './ActionDialog'

const actionStyles = theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  warning: {
    color: theme.palette.severity.error.static,
  },
  sideMargin: { marginRight: theme.spacing(1) },
})

class ActionButtons extends PureComponent {
  state = {
    showActionDialog: false,
    action: null,
  }

  onAction = action => {
    action.requireReason || action.requireConfirmation
      ? this.setState({
          showActionDialog: true,
          action: action,
        })
      : this.props.onAction(action, null, null)
  }

  onClose = () => {
    this.setState({
      showActionDialog: false,
      action: null,
    })
  }

  onConfirmAction = (action, cancelReason, actionNote) => {
    this.props.onAction(action, cancelReason, actionNote)
    this.onClose()
  }

  render() {
    const {
      actions,
      cancellationReasons,
      classes,
      disabled,
      hasErrors,
      orderId,
      outOfNetworkReasonRequired,
      previewOrderPdf,
      status,
      vendorId,
    } = this.props

    return (
      <React.Fragment>
        {hasErrors && (
          <Typography className={classes.warning}>
            Form is currently invalid
          </Typography>
        )}
        <div className={classes.buttonContainer}>
          <OrdersSaveButton orderId={orderId} icon={true} />
          <Tooltip title="Preview PDF" key="orderPdf">
            <Button
              className={classes.sideMargin}
              color="primary"
              disabled={['closed', 'cancelled'].includes(status)}
              icon="file_copy"
              label="Preview PDF"
              onClick={() => {
                previewOrderPdf(orderId)
              }}
              variant="text"
            >
              <Icon>file_copy</Icon>
            </Button>
          </Tooltip>

          {actions.map(action => {
            const Component = ({ authorized }) => (
              <Button
                className={classes.sideMargin}
                variant="outlined"
                key={action.key}
                disabled={
                  (!authorized ||
                    disabled ||
                    outOfNetworkReasonRequired ||
                    (action.requireValidation && hasErrors) ||
                    (action.requireVendorSelection && !vendorId)) &&
                  !action.alwaysAvailable
                }
                onClick={() => this.onAction(action)}
              >
                {action.label}
                {action.requireVendorSelection && !vendorId
                  ? ' (Requires Vendor)'
                  : ''}
                {!authorized ? ' (Requires Authorization)' : ''}
              </Button>
            )
            if (action.requiredPermission) {
              return (
                <WithAuthorized
                  action={permissionActions.EDIT}
                  component={Component}
                  key={action.key}
                  object={action.requiredPermission}
                />
              )
            } else {
              return <Component key={action.key} authorized />
            }
          })}
          <ActionDialog
            action={this.state.action}
            id={orderId}
            note
            onClose={this.onClose}
            onConfirm={this.onConfirmAction}
            open={this.state.showActionDialog}
            reasons={cancellationReasons}
          />
        </div>
      </React.Fragment>
    )
  }
}

ActionButtons.propTypes = {
  actions: PropTypes.array,
  cancellationReasons: PropTypes.object,
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool.isRequired,
  onAction: PropTypes.func,
  orderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  outOfNetworkReasonRequired: PropTypes.bool,
  previewOrderPdf: PropTypes.func,
  status: PropTypes.string,
  vendorId: PropTypes.string,
}

export default withStyles(actionStyles)(ActionButtons)
