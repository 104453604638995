import { Map } from 'immutable'
// Takes an array of status and creates a Map of
// status objects with ids
const createStatusMapping = statuses =>
  statuses
    .map((s, i) => new Map({ id: i, name: s }))
    .reduce((acc, val, i) => acc.set(i, val), new Map())

const idToStatusName = statusMapping => id => statusMapping.getIn([id, 'name'])

export { createStatusMapping, idToStatusName }
