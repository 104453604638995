// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getIn, scopedCreator } from '~/utils/data'
import rootKey from '../key'
import { updateDischarge } from './discharges'

// KEY
const key = 'dischargeUpdateDialog'

export const getDischargeEditDialogId = getIn([rootKey, key])

// CREATORS
const creator = scopedCreator(key)

export const editDischargeDialogIdChanged = creator(
  'EDITABLE_DISCHARGE_DIALOG_CASE_ID_CHANGED'
)

// REDUCER
export default createReducer(key, null, {
  // @ts-expect-error ts-migrate(6133) FIXME: 'state' is declared but its value is never read.
  [editDischargeDialogIdChanged]: (state: any, { payload }: any) =>
    payload || null,
  [updateDischarge.SUCCEEDED]: () => null,
})
