import { combineEpics } from 'redux-observable'
import { epic as uploadPatients } from './data/patientUpload'
import appEntered from './epics/appEntered'
import {
  fetchHealthConcernsEpic,
  fetchInterventionsEpic,
  fetchOutcomesEpic,
  panelOpened,
  saveHealthConcern,
  saveIntervention,
  saveOutcome,
  saveGoal,
} from './epics/careplanManagerRoutes'
import permissionSetModified from './epics/permissionSetModified'
import prescriberLocationsViewed from './epics/prescriberLocationsViewed'
import prescribingLocationsEntered from './epics/prescribingLocationsEntered'
import roleModified from './epics/roleModified'
import setPreferredPrescriberLocation from './epics/setPreferredPrescriberLocation'
import snackbarMessage from './epics/snackbarMessage'
import userModified from './epics/userModified'
import userRoutes from './epics/userRoutes'

export default combineEpics(
  appEntered,
  fetchHealthConcernsEpic,
  fetchInterventionsEpic,
  fetchOutcomesEpic,
  panelOpened,
  permissionSetModified,
  prescriberLocationsViewed,
  prescribingLocationsEntered,
  roleModified,
  saveGoal,
  saveHealthConcern,
  saveIntervention,
  saveOutcome,
  setPreferredPrescriberLocation,
  snackbarMessage,
  uploadPatients,
  userModified,
  userRoutes
)
