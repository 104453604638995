import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useAction } from '~/hooks'
import { formatDate, formatDateFromTimestamp } from '~/utils/format'
import { Icon, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { setAlertDialogValue } from '../data/alertDialog'
import { Alert as AlertRecord } from '../data/patientAlerts'

const noCrossCoverageReason = ({ type, noCrossCoverageReason }) =>
  type === 'No Cross Coverage' ? ` (${noCrossCoverageReason})` : ''

const SYSTEM_GENERATED = 'System Generated'
const ASPIRE_SYSTEM = 'Aspire System'
const CAREMORE_SYSTEM = 'CareMore System'

const isAspireGenerated = ({ type }) => type === SYSTEM_GENERATED
const isCareMoreGenerated = ({ type }) => type === CAREMORE_SYSTEM
const isGenerated = alert =>
  isAspireGenerated(alert) || isCareMoreGenerated(alert)

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  alert: {
    display: 'flex',
    alignItems: 'center',
    margin: { top: spacing(1), bottom: spacing(1) },
  },
  icon: {
    color: palette.common.white,
    position: 'relative',
    top: 2,
  },
  deleteIcon: {
    color: alert =>
      isGenerated(alert) ? palette.shades.darker : palette.common.white,
  },
  content: {
    flex: 1,
    '& > *': {
      color: palette.common.white,
      [breakpoints.up('md')]: {
        display: 'inline',
      },
    },
    '& > *:not(:last-child)': {
      marginRight: spacing(1),
    },
  },
  iconButton: {
    marginLeft: 'auto',
    padding: 2,
  },
  iconContainer: {
    flex: '0 0 40px',
  },
  iconButtonContainer: {
    flex: '0 0 40px',
    textAlign: 'right',
  },
  type: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  createdBy: {
    fontStyle: 'italic',
  },
}))

const Alert = ({ alert }) => {
  const classes = useStyles(alert)
  const setDialogValue = useAction(setAlertDialogValue)
  const handleClick = useCallback(() => setDialogValue(alert.id), [alert.id])

  const type = isGenerated(alert)
    ? SYSTEM_GENERATED
    : alert.type + noCrossCoverageReason(alert)

  const createdDate = isCareMoreGenerated(alert)
    ? formatDate(alert.createdDate)
    : formatDateFromTimestamp(alert.createdDate)

  const generatedCreatedBy = isCareMoreGenerated(alert)
    ? CAREMORE_SYSTEM
    : ASPIRE_SYSTEM

  const createdBy = alert.createdByUser
    ? alert.createdByUser.name
    : generatedCreatedBy

  return (
    <div className={classes.alert}>
      <div className={classes.iconContainer}>
        <Icon className={classes.icon}>warning</Icon>
      </div>
      <div className={classes.content}>
        <Typography className={classes.type} component="span">
          {type}
        </Typography>
        <Typography component="span">({createdDate})</Typography>
        <Typography component="span">{alert.description}</Typography>
        <Typography className={classes.createdBy} component="span">
          (Created by {createdBy})
        </Typography>
      </div>
      <div className={classes.iconButtonContainer}>
        <IconButton
          disabled={isGenerated(alert)}
          className={classes.iconButton}
          onClick={handleClick}
        >
          <Icon className={classes.deleteIcon}>cancel</Icon>
        </IconButton>
      </div>
    </div>
  )
}

Alert.propTypes = {
  alert: PropTypes.instanceOf(AlertRecord).isRequired,
}

export default Alert
