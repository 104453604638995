import { Record } from 'immutable'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/fe... Remove this comment to see the full error message
import { notCondition } from '~/features/scheduling'
import {
  dueHigh,
  dueMedium,
  hasOpenTOCTasks,
  inHospital,
  inLTC,
  inSNF,
  isCallable,
  isOrange,
  isRed,
  isYellow,
  needsCall,
  needsCallTomorrow,
  noScore,
  notDue,
  notDueForCall,
  ntvdFrom,
  ntvdTo,
  overdueHigh,
  overdueLow,
  overdueMax,
  overdueMedium,
  reinstated,
  rideAlongPriority,
  scheduledFrom,
  scheduledTo,
  statusActive,
  statusReferred,
  statusScheduled,
  tasksReminders,
  transitionOfCare,
  wasCalledToday,
  fivePercentTierThree,
  fivePercentTierTwo,
  fivePercentTierOne,
  telephonic,
  homeBasedPalliativeCare,
  pcPlus,
  commercialPalliativeCare,
  hbpcHybrid,
  highRiskComplexCare,
  careMoreHealthNetwork,
  careMoreAtHome,
} from './patientConditions'

const Filter = Record({
  key: null,
  condition: null,
})

const FilterDefinitions = Record({
  notDue: Filter(),
  overdueMax: Filter(),
  overdueHigh: Filter(),
  overdueMedium: Filter(),
  overdueLow: Filter(),
  dueMedium: Filter(),
  dueHigh: Filter(),
  active: Filter(),
  scheduled: Filter(),
  referred: Filter(),
  reinstated: Filter(),
  transitionOfCare: Filter(),
  scheduledFrom: Filter(),
  scheduledTo: Filter(),
  ntvdFrom: Filter(),
  ntvdTo: Filter(),
  includeYellow: Filter(),
  includeOrange: Filter(),
  includeRed: Filter(),
  noScore: Filter(),
  tasksReminders: Filter(),
  needsCallToday: Filter(),
  excludeCalledToday: Filter(),
  needsCallTomorrow: Filter(),
  notDueForCall: Filter(),
  excludeUncallable: Filter(),
  excludeHospital: Filter(),
  excludeSNF: Filter(),
  excludeLTC: Filter(),
  excludeOpenTOCTasks: Filter(),
  rideAlongHighPriority: Filter(),
  rideAlongNotHighPriority: Filter(),
  fivePercentTierThree: Filter(),
  fivePercentTierTwo: Filter(),
  fivePercentTierOne: Filter(),
  telephonic: Filter(),
  homeBasedPalliativeCare: Filter(),
  pcPlus: Filter(),
  commercialPalliativeCare: Filter(),
  hbpcHybrid: Filter(),
  highRiskComplexCare: Filter(),
  careMoreHealthNetwork: Filter(),
  careMoreAtHome: Filter(),
})

export default FilterDefinitions({
  // Patient Dueness
  notDue: Filter({
    key: 'notDue',
    condition: notDue,
  }),
  overdueMax: Filter({ key: 'overdueMax', condition: overdueMax }),
  overdueHigh: Filter({ key: 'overdueHigh', condition: overdueHigh }),
  overdueMedium: Filter({ key: 'overdueMedium', condition: overdueMedium }),
  overdueLow: Filter({ key: 'overdueLow', condition: overdueLow }),
  dueMedium: Filter({ key: 'dueMedium', condition: dueMedium }),
  dueHigh: Filter({ key: 'dueHigh', condition: dueHigh }),
  // Patient Status
  active: Filter({ key: 'active', condition: statusActive }),
  scheduled: Filter({ key: 'scheduled', condition: statusScheduled }),
  referred: Filter({ key: 'referred', condition: statusReferred }),
  reinstated: Filter({ key: 'reinstated', condition: reinstated }),
  transitionOfCare: Filter({
    key: 'transitionOfCare',
    condition: transitionOfCare,
  }),
  // Patient Scheduled Date
  scheduledFrom: Filter({ key: 'scheduledFrom', condition: scheduledFrom }),
  scheduledTo: Filter({ key: 'scheduledTo', condition: scheduledTo }),
  // Next Target Visit Date
  ntvdFrom: Filter({ key: 'ntvdFrom', condition: ntvdFrom }),
  ntvdTo: Filter({ key: 'ntvdTo', condition: ntvdTo }),
  // Risk Stratification Score
  includeYellow: Filter({
    key: 'includeYellow',
    condition: isYellow,
  }),
  includeOrange: Filter({
    key: 'includeOrange',
    condition: isOrange,
  }),
  includeRed: Filter({
    key: 'includeRed',
    condition: isRed,
  }),
  noScore: Filter({
    key: 'noScore',
    condition: noScore,
  }),
  // Patient Calls
  tasksReminders: Filter({
    key: 'tasksReminders',
    condition: tasksReminders,
  }),
  needsCallToday: Filter({
    key: 'needsCallToday',
    condition: needsCall,
  }),
  excludeCalledToday: Filter({
    key: 'excludeCalledToday',
    condition: notCondition(wasCalledToday),
  }),
  needsCallTomorrow: Filter({
    key: 'needsCallTomorrow',
    condition: needsCallTomorrow,
  }),
  notDueForCall: Filter({
    key: 'notDueForCall',
    condition: notDueForCall,
  }),
  excludeUncallable: Filter({
    key: 'excludeUncallable',
    condition: isCallable,
  }),
  // Transition of Care
  excludeHospital: Filter({
    key: 'excludeHospital',
    condition: notCondition(inHospital),
  }),
  excludeSNF: Filter({ key: 'excludeSNF', condition: notCondition(inSNF) }),
  excludeLTC: Filter({ key: 'excludeLTC', condition: notCondition(inLTC) }),
  excludeOpenTOCTasks: Filter({
    key: 'excludeOpenTOCTasks',
    condition: notCondition(hasOpenTOCTasks),
  }),
  // Ride Along
  rideAlongHighPriority: Filter({
    key: 'rideAlongHighPriority',
    condition: rideAlongPriority,
  }),
  rideAlongNotHighPriority: Filter({
    key: 'rideAlongNotHighPriority',
    condition: notCondition(rideAlongPriority),
  }),
  // Program
  fivePercentTierThree: Filter({
    key: 'fivePercentTierThree',
    condition: fivePercentTierThree,
  }),
  fivePercentTierTwo: Filter({
    key: 'fivePercentTierTwo',
    condition: fivePercentTierTwo,
  }),
  fivePercentTierOne: Filter({
    key: 'fivePercentTierOne',
    condition: fivePercentTierOne,
  }),
  telephonic: Filter({
    key: 'telephonic',
    condition: telephonic,
  }),
  homeBasedPalliativeCare: Filter({
    key: 'homeBasedPalliativeCare',
    condition: homeBasedPalliativeCare,
  }),
  pcPlus: Filter({
    key: 'pcPlus',
    condition: pcPlus,
  }),
  commercialPalliativeCare: Filter({
    key: 'commercialPalliativeCare',
    condition: commercialPalliativeCare,
  }),
  hbpcHybrid: Filter({
    key: 'hbpcHybrid',
    condition: hbpcHybrid,
  }),
  highRiskComplexCare: Filter({
    key: 'highRiskComplexCare',
    condition: highRiskComplexCare,
  }),
  careMoreHealthNetwork: Filter({
    key: 'careMoreHealthNetwork',
    condition: careMoreHealthNetwork,
  }),
  careMoreAtHome: Filter({
    key: 'careMoreAtHome',
    condition: careMoreAtHome,
  }),
})
