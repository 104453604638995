import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/Request' or its corres... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '~/utils/createReducer' or its ... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { getIn } from '~/utils/data'
import rootKey from '../key'

// KEY
const key = 'careTeamValidityCheck'

export const verifyPatientCareTeamAssignment = Request({
  typePrefix: rootKey,
  typeBase: 'ASSIGNED_CARE_TEAM_VALIDITY_CHECK',
  requestParams: ['patientId'],
  operation: (patientId: any) =>
    AspireAPI.get(`care_teams/patient/${patientId}/validity_check`),
  messages: {
    failed: "There was a problem verifying the patient's care team assignment",
  },
})

export const getValidityCheckResults = getIn([rootKey, key])

// REDUCER
export default createReducer(key, null, {
  [verifyPatientCareTeamAssignment.REQUESTED]: () => null,
  // @ts-expect-error ts-migrate(6133) FIXME: 'state' is declared but its value is never read.
  [verifyPatientCareTeamAssignment.SUCCEEDED]: (state: any, data: any) =>
    data.payload,
  [verifyPatientCareTeamAssignment.FAILED]: () => null,
})
