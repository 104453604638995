import { List, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { get, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { getRoot } from './common/shared'
import { LimitedBenefit, TransportationNote } from './interfaces'

// keys
const BENEFITS = 'transportationBenefits'
const NOTES = 'notes'

// CREATORS
const creator = scopedCreator(BENEFITS)
export const resetBenefits = creator('PATIENT_BENEFIT_RESET')
export const resetNotes = creator('PATIENT_NOTES_RESET')

export const Limited = Record({
  benefitName: null,
  benefitLimit: null,
  hasHitlimit: null,
  hasHitHalfway: null,
  remaining: null,
  taken: null,
})

export const Note = Record({
  createdAt: null,
  createdBy: null,
  note: null,
})

// TRANSFORMER
export const transformLimited = (limited: [LimitedBenefit]) =>
  Array.isArray(limited) ? List(limited.map(Limited)) : List()

export const transformNotes = (notes: [TransportationNote]) =>
  Array.isArray(notes) ? List(notes.map(Note)) : List()

export const transformOneWay = (limited: [LimitedBenefit]) =>
  Array.isArray(limited) ? limited.find(b => b.benefitName === 'ONEWAY') : null

export const transformUnlimited = (unlimited: [string]) =>
  Array.isArray(unlimited) ? List(unlimited) : List()

export const transFormBenefits = (data: any) => {
  return {
    oneway: transformOneWay(data?.limited),
    limited: transformLimited(data?.limited),
    unlimited: transformUnlimited(data?.unlimited),
    isOverrideEligible: data?.isOverrideEligible,
    eeBenefitStatus: data?.eeBenefitStatus,
  }
}

// REQUEST
export const fetchMemberBenefits = Request({
  typePrefix: BENEFITS,
  typeBase: 'FETCH_BENEFITS',
  requestParams: ['id', 'requestParams'],
  operation: (id: string, requestParams: any) => {
    let url = `transportation/patient_benefits/${id}`
    if (requestParams && requestParams.eligibilityEffectiveDate) {
      url = `${url}?benefitYear=${requestParams.benefitYear}&classplanProductId=${requestParams.classplanProductId}&eligibilityEffectiveDate=${requestParams.eligibilityEffectiveDate}&productDescription=${requestParams.productDescription}&shouldRefreshEEBenefit=${requestParams.shouldRefreshEEBenefit}`
    }

    return AspireAPI.get(url)
  },
  transform: transFormBenefits,
  messages: { failed: 'Failed to fetch transportation benefits' },
})

export const fetchNotes = Request({
  typePrefix: NOTES,
  typeBase: 'FETCH_UNLIMITED_BENEFITS',
  requestParams: ['id', 'requestParams'],
  operation: (id: string, requestParams: any) => {
    let url = `transportation/patient_notes/${id}`
    if (requestParams && requestParams.eligibilityEffectiveDate) {
      url = `${url}?benefitYear=${requestParams.benefitYear}&eligibilityEffectiveDate=${requestParams.eligibilityEffectiveDate}`
    }
    return AspireAPI.get(url)
  },
  transform: transformNotes,
  messages: { failed: 'Failed to fetch transportation patient notes' },
})

// REDUCER
export const transportationBenefits = createReducer(BENEFITS, List(), {
  [fetchMemberBenefits.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [resetBenefits]: () => Record({}),
})

export const notes = createReducer(NOTES, List(), {
  [fetchNotes.SUCCEEDED]: (_state: any, { payload }: any) => payload,
  // @ts-expect-error can we deal with the toString being automatically called?
  [resetNotes]: () => List(),
})

// SELECTORS
export const getBenefits = pipe(getRoot, get(BENEFITS))
export const getNotes = pipe(getRoot, get(NOTES))
