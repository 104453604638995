import classnames from 'classnames'
import React from 'react'
import { Diagnosis } from '~/apps/caseManagerDashboard/components/censusGrids/Diagnosis'
import DetailItem from '~/components/DetailItem'
import { formatDate } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ spacing }) => ({
  grid: {
    display: 'grid',
  },
  mainColumns: {
    gridTemplateColumns: 'auto auto',
    gridRowGap: `${spacing(4)}px`,
  },
  itemColumns: {
    gridTemplateColumns: 'auto 1fr',
    columnGap: spacing(2),
  },
})

const CaremoreHospitalizationDetails = ({ classes, row }) => (
  <Card>
    <CardContent>
      <div className={classnames(classes.grid, classes.mainColumns)}>
        {/* Hospitalization Information */}
        <div>
          <Typography variant="subtitle1" color="textSecondary">
            Hospitalization Information
          </Typography>

          <div className={classnames(classes.grid, classes.itemColumns)}>
            {/* Status */}
            <DetailItem label="Status:" value={row.status} />
            {/* Place of Service */}
            <DetailItem label="Place of Service:" value={row.placeOfService} />
            {/* Facility Name */}
            <DetailItem label="Facility Name:" value={row.facilityName} />
            {/* OON Flag */}

            <DetailItem
              label="OON Flag:"
              value={row.outOfNetwork ? 'true' : 'false'}
            />
            <DetailItem
              label="ASC (Ambulatory-Sensitive Condition):"
              value={row.ascFlag ? 'true' : 'false'}
            />
            {/* Admitting Diagnosis */}
            <DetailItem
              label="Admitting Diagnosis:"
              value={<Diagnosis value={row.admittingDiagnosis} />}
            />
            {/* Admission Date */}
            <DetailItem
              label="Admission Date:"
              value={formatDate(row.admitDate)}
            />
            {/* Admission Source*/}
            <DetailItem label="Admission Source:" value={row.admissionSource} />
            {/* Metal Tier */}
            <DetailItem label="Metal Tier:" value={row.metalTier} />
          </div>
        </div>
        {/* Hospitalization Information Continued */}
        <div>
          <Typography variant="subtitle1" color="textSecondary">
            Hospitalization Information Continued
          </Typography>

          <div className={classnames(classes.grid, classes.itemColumns)}>
            {/* Discharge Disposition */}
            <DetailItem
              label="Discharge Disposition:"
              value={row.dischargeDisposition}
            />
            {/* Discharge Diagnosis */}
            <DetailItem
              label="Discharge Diagnosis:"
              value={<Diagnosis value={row.dischargeDiagnosis} />}
            />
            {/* Discharge Date */}
            <DetailItem
              label="Discharge Date:"
              value={row.dischargeDate ? formatDate(row.dischargeDate) : ''}
            />
            {/* Discharging Extensivist */}
            <DetailItem
              label="Discharging Extensivist:"
              value={row.dischargingExtensivist}
            />
            {/* Hospitalization Notes */}
            <DetailItem label="Hospitalization Notes:" value={row.notes} />
            {/* Included Observation Stay*/}
            <DetailItem
              label="Included Observation Stay:"
              value={row.observationStay ? 'true' : 'false'}
            />
            {/* Authorization Status*/}
            <DetailItem label="Authorization Status:" value={row.authStatus} />
            {/* Authorization Reason*/}
            <DetailItem label="Authorization Reason:" value={row.authReason} />
          </div>
        </div>
      </div>
    </CardContent>
  </Card>
)

CaremoreHospitalizationDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  row: PropTypes.record.isRequired,
}

export default withStyles(styles)(CaremoreHospitalizationDetails)
