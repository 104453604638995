import { Map, Record } from 'immutable'
import { createSelector } from 'reselect'
import { actions, objects } from '~/features/authorization'
import {
  checkPermission,
  getPermissions,
} from '~/features/authorization/data/permissions'
import AspireAPI from '~/resources/aspire'
import { theme } from '~/themes/default'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { creator, type } from '~/utils/data'
import rootKey from '../key'
import { getRoot } from './common/shared'

// KEY
const key = 'metrics'

// RECORDS
export const Metric = Record({
  total: null,
  title: null,
  target: null,
  metric: null,
  market: null,
  color: null,
  key: null,
})

const mapData = (data: any) =>
  Map(Object.keys(data).map(key => [key, Metric(data[key])]))

export const colorMappings = {
  green: theme.palette.severity.success.static,
  red: theme.palette.severity.error.static,
  yellow: theme.palette.severity.warning.static,
}
// ACTIONS
export const FETCHED_METRICS = type(rootKey, 'FETCHED_METRICS')
export const REQUEST_FETCH_METRICS = type(rootKey, 'REQUEST_FETCH_METRICS')

// ACTION CREATORS
export const fetchedMetrics = creator(FETCHED_METRICS, 'metrics')
export const requestMetrics = creator(REQUEST_FETCH_METRICS, 'appId')

// REDUCER
const initState = Map()

export default createReducer(key, initState, {
  // @ts-expect-error ts-migrate(6133) FIXME: 'state' is declared but its value is never read.
  [FETCHED_METRICS]: (state: any, { payload }: any) =>
    mapData(payload.metrics || {}),
})

// SELECTORS
// @ts-expect-error Object is unknown
export const getMetrics = (state: any) => getRoot(state).get(key, Map())

const splashPageMetrics = [
  'visitsPerWeek',
  'hospitalizationPercentage',
  'hospiceDeathThirty',
  'hospiceDeathNinety',
]

export const getSplashPageMetrics = (state: any) => {
  const rawMetrics = getMetrics(state) || Map()
  return rawMetrics
    .map((v: any, k: any) => v.set('key', k))
    .toList()
    .filter((category: any) => category.get('metric') && category.get('market'))
    .filter(
      (category: any) => splashPageMetrics.indexOf(category.get('key')) > -1
    )
}

export const getMetricsList = (state: any) => {
  const rawMetrics = getMetrics(state)
  return (
    rawMetrics
      .map((v: any, k: any) => v.set('key', k))
      .toList()
      .filter(
        (category: any) => category.get('metric') && category.get('market')
      )
      .sort((a: any, b: any) => a.get('title').localeCompare(b.get('title')))
      // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
      .sort((a: any, b: any) => !!b.get('color') - !!a.get('color'))
      .sort(
        (a: any, b: any) =>
          // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
          (b.get('color') == 'yellow') - (a.get('color') == 'yellow')
      )
      .sort(
        (a: any, b: any) =>
          Number(b.get('color') == 'red') - Number(a.get('color') == 'red')
      )
  )
}

export const canViewOtherMetrics = createSelector(
  [getPermissions],
  permissions =>
    checkPermission(
      permissions,
      objects.APP_DASH_VIEW_OTHER_METRICS,
      actions.VIEW
    )
)

// ASYNC
export const fetchAppMetrics = (user_id: any) =>
  AspireAPI.get('dashboard/app_dash_metrics', { params: { user_id } })
