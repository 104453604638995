import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
// @ts-expect-error ts-migrate(7016)
import { switchTo } from '~/utils/operators'
import { addHcGoal, addHcGoalNote, fetchHcGoals } from '../data/hcGoal'
import {
  deferExternalHealthConcern,
  fetchExternalHealthConcerns,
} from '../data/externalHealthConcern'
import {
  addGoalIntervention,
  addGoalInterventionNote,
  fetchHcGoalInterventions,
  getCurrentHcGoal,
} from '../data/hcGoalInterventions'
// @ts-expect-error ts-migrate(7016)
import { getPatientId } from '~/apps/patientRecord/data/patientId'

export const saveHcGoal = (action$: any, state$: any) =>
  action$.pipe(
    ofType(addHcGoal.SUCCEEDED, addHcGoalNote.SUCCEEDED),
    switchTo(state$),
    map(getPatientId),
    map((patientId: any) => fetchHcGoals.requested(patientId))
  )

export const saveHcGoalIntervention = (action$: any, state$: any) =>
  action$.pipe(
    ofType(addGoalIntervention.SUCCEEDED, addGoalInterventionNote.SUCCEEDED),
    switchTo(state$),
    map(getCurrentHcGoal),
    map((care_plan_goal_id: any) =>
      fetchHcGoalInterventions.requested(care_plan_goal_id)
    )
  )

export const saveExternalHealthConcern = (action$: any, state$: any) =>
  action$.pipe(
    ofType(deferExternalHealthConcern.SUCCEEDED, addHcGoal.SUCCEEDED),
    switchTo(state$),
    map(getPatientId),
    map((patientId: any) => fetchExternalHealthConcerns.requested(patientId))
  )
