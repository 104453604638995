import PropTypes from '~/utils/propTypes'
import getRiskLevel from '../utils/riskLevelValues'

const RiskLevel = ({ score }) => getRiskLevel(score).display

RiskLevel.propTypes = {
  score: PropTypes.number,
}

export default RiskLevel
