import { colors } from '@material-ui/core'
import lightTheme from '../../light'
import heartsBackground from './hearts-lite.png'

export const valentinesDayStyles = () => ({
  '@global': {
    html: {
      minHeight: '100%',
    },
    body: {
      backgroundColor: 'pink',
      background: `url(${heartsBackground})`,
      backgroundSize: '5%',
    },
    '.holidayIcon': {
      animation: 'heartbeat .4s infinite alternate',
      color: '#ffccd5',
      transformOrigin: 'center',
    },
    '.holidayText': {
      color: '#ffccd5',
      fontFamily: "'Kaushan Script', cursive",
      fontSize: '1.5em',
    },
    '@keyframes heartbeat': {
      to: { transform: 'scale(1.4)' },
    },
  },
})

export const valentinesDayTheme = () => ({
  ...lightTheme,
  palette: {
    ...lightTheme.palette,
    holiday: 'valentines',
    primary: {
      light: colors.red[300],
      main: colors.red[500],
      dark: colors.red[700],
    },
    secondary: {
      main: colors.red[500],
    },
    drawer: {
      header: colors.red[800],
      backgroundClosed: {
        backgroundColor: colors.red[700],
      },
      backgroundOpen: {
        background: {
          color: colors.red[700],
        },
      },
    },
    enrolledStatusBadge: {
      backgroundColor: '#8a1e1f',
      color: colors.common.white,
    },
    accordion: {
      detailsBackground: '#ffe6ea',
      header: colors.red[700],
    },
    vitalsToolbarHeader: {
      backgroundColor: colors.red[700],
    },
    vitalsChipContainer: {
      backgroundColor: 'pink',
    },
    vitalsChipChosen: {
      backgroundColor: '#8a1e1f',
      color: colors.common.white,
    },
    calendarHead: {
      backgroundColor: colors.red[700],
    },
    calendarTitleText: {
      color: colors.red[500],
    },
    contentText: {
      primaryLabel: colors.pink[500],
      secondaryLabel: colors.red[500],
    },
    notifications: {
      linkText: colors.red[500],
    },
    patientSearchProgramBadge: {
      backgroundColor: '#8a1e1f',
      color: colors.common.white,
    },
    patientInfoTag: {
      backgroundColor: colors.lightBlue[700],
      '& button:hover': {
        backgroundColor: colors.lightBlue[800],
      },
    },
    tabSelected: {
      backgroundColor: colors.red[700],
    },
    toolbarHeaderText: {
      color: colors.common.white,
    },
  },
  props: {
    MuiFab: {
      disableRipple: true,
    },
  },
  overrides: {
    ...lightTheme.overrides,
    MuiAppBar: {
      colorPrimary: {
        color: colors.common.white,
      },
    },
    MuiButton: {
      outlined: {
        borderColor: colors.red[500],
        color: colors.red[500],
      },
      root: {
        '&:hover': {
          backgroundColor: '#ff99ac',
        },
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: '#ffe6ea',
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: '#ff99ac',
        },
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: '#ffc0cb',
        '& .material-icons': {
          color: colors.red[500],
        },
      },
    },
    MuiFab: {
      root: {
        borderRadius: 0,
        transform: 'rotate(-45deg)',
      },
      label: {
        '& span': {
          left: 6,
          position: 'relative',
          top: '-6px',
          transform: 'rotate(45deg)',
          zIndex: 10,
        },
      },
      primary: {
        backgroundColor: colors.red[500],
        '&::before': {
          content: '""',
          backgroundColor: colors.red[500],
          borderRadius: '50%',
          height: 56,
          position: 'absolute',
          top: '-25px',
          width: 56,
        },
        '&::after': {
          content: '""',
          backgroundColor: colors.red[500],
          borderRadius: '50%',
          height: 56,
          left: 26,
          position: 'absolute',
          top: 0,
          width: 56,
        },
        '&:hover': {
          backgroundColor: colors.red[500],
        },
      },
      secondary: {
        backgroundColor: colors.red[500],
        '&::before': {
          content: '""',
          backgroundColor: colors.red[500],
          borderRadius: '50%',
          height: 56,
          position: 'absolute',
          top: '-25px',
          width: 56,
        },
        '&::after': {
          content: '""',
          backgroundColor: colors.red[500],
          borderRadius: '50%',
          height: 56,
          left: 26,
          position: 'absolute',
          top: 0,
          width: 56,
        },
        '&:hover': {
          backgroundColor: colors.red[500],
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: '#ffc0cb',
      },
    },
    MuiToolbar: {
      root: {
        '& button, & div, & p, & svg': {
          color: colors.common.white,
        },
      },
    },
  },
})
