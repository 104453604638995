import moment from 'moment'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createSelector } from 'reselect'
import { ActionDialog } from '~/components/dialogs'
import { AutoComplete } from '~/components/fields'
import PropTypes from '~/utils/propTypes'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { SUBSCRIBER_CHOICES } from '../constants'
import { getChannelList } from '../data/channelList'
import {
  coverageSelectedForCreation,
  isCreatingCoverage,
} from '../data/dialogs'
import { getHealthplanProgramList } from '../data/healthplanSpecificPrograms'
import { saveNewInsuranceCoverage } from '../data/insuranceCoverages'

const filteredHealthplanSpecificPrograms = createSelector(
  [h => h, (h, id) => id],
  (programs, id) => programs.filter(item => item.healthplanCrosswalkId == id)
)

const styles = ({ spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(1),
    '& div': {
      marginBottom: spacing(0.5),
    },
  },
  containerChild: {
    marginTop: spacing(1),
    width: '100%',
  },
})

const initState = {
  id: null,
  accountId: null,
  effectiveDate: null,
  expirationDate: null,
  groupNumber: null,
  insuranceNotes: null,
  priority: null,
  subscriberChoice: null,
  subscriberDob: null,
  subscriberName: null,
  patientId: null,
  healthplanCrosswalkId: null,
  healthplanName: null,
  primaryContractName: null,
  healthplanSpecificPrograms: null,
  healthplanSpecificProgramList: [],
  dialogOpen: false,
}

const CoverageCreationDialog = ({
  classes,
  open,
  onClose,
  saveCoverage,
  primaryContracts,
  healthplanProgramList,
  patientId,
  activeCoverage,
}) => {
  const [state, setState] = useState(initState)

  const {
    accountId,
    effectiveDate,
    expirationDate,
    groupNumber,
    insuranceNotes,
    priority,
    subscriberChoice,
    subscriberDob,
    subscriberName,
    primaryContractName,
    healthplanCrosswalkId,
    healthplanName,
    healthplanPatientId,
    healthplanSpecificProgramList,
    healthplanSpecificPrograms,
    dialogOpen,
  } = state
  const closeDialog = () => {
    setState(initState)
    onClose()
  }
  const openDialog = () => {
    setState({ ...state, dialogOpen: true })
  }

  const onChangeHealthplanName = name => {
    setState({
      ...state,
      healthplanCrosswalkId: null,
      primaryContractName: name,
      healthplanSpecificProgramList: [],
      healthplanPatientId: null,
      healthplanSpecificPrograms: null,
    })
  }

  const onSelectPrimaryContract = (id, name) => {
    const healthplan = primaryContracts
      .filter(v => v.value === id)
      .map(v => v.secondaryLabel)
    if (id && id != healthplanCrosswalkId) {
      setState({
        ...state,
        healthplanCrosswalkId: id,
        primaryContractName: name,
        healthplanName: healthplan,
        healthplanPatientId: null,
        healthplanSpecificPrograms: null,
        healthplanSpecificProgramList: filteredHealthplanSpecificPrograms(
          healthplanProgramList,
          id
        ),
      })
    }
  }

  const editAccountId = event =>
    setState({ ...state, accountId: event.target.value })
  const editEffectiveDate = event =>
    setState({ ...state, effectiveDate: event.target.value })
  const editExpirationDate = event =>
    setState({ ...state, expirationDate: event.target.value })
  const editGroupNumber = event =>
    setState({ ...state, groupNumber: event.target.value })
  const editHealthplanPatientId = event =>
    setState({ ...state, healthplanPatientId: event.target.value })
  const editInsuranceNotes = event =>
    setState({ ...state, insuranceNotes: event.target.value })
  const editHealthplanSpecificPrograms = event =>
    setState({ ...state, healthplanSpecificPrograms: event.target.value })
  const editPriority = event => {
    if (event.target.value > 0 && event.target.value <= 10)
      setState({ ...state, priority: Math.floor(event.target.value) })
  }
  const editSubscriberChoice = event =>
    setState({ ...state, subscriberChoice: event.target.value })
  const editSubscriberDob = event =>
    setState({ ...state, subscriberDob: event.target.value })
  const editsubscriberName = event =>
    setState({ ...state, subscriberName: event.target.value })

  const openCoverage = (activeCoverage, priority) =>
    activeCoverage.length > 0
      ? (moment().format('YYYY-MM-DD') <
          activeCoverage[0].get('expirationDate') &&
          priority == 1) ||
        (activeCoverage[0].get('expirationDate') == null && priority == 1)
      : false

  return (
    <div>
      <ActionDialog
        fullWidth
        maxWidth="sm"
        mode="create"
        disableAction={
          !healthplanCrosswalkId ||
          !effectiveDate ||
          !accountId ||
          !patientId ||
          !subscriberChoice ||
          !priority
        }
        onClose={closeDialog}
        onCreate={() =>
          openCoverage(activeCoverage, priority)
            ? openDialog()
            : saveCoverage({ ...state, patientId })
        }
        open={!!open}
        title="Add New Insurance Coverage"
      >
        <div className={classes.container}>
          {primaryContracts && primaryContracts.length ? (
            <AutoComplete
              label="Primary Contract"
              value={primaryContractName || ''}
              name="name"
              onChange={onChangeHealthplanName}
              onValueSelected={onSelectPrimaryContract}
              availableSuggestions={primaryContracts}
            />
          ) : (
            <TextField label="Primary Contract" disabled value={'Loading...'} />
          )}
          <TextField
            label="Healthplan"
            id="healthplan-name"
            InputLabelProps={{ shrink: true }}
            disabled
            value={healthplanName || ''}
          />
          <TextField
            label="Account Number"
            id="account-number"
            fullWidth
            required
            onChange={editAccountId}
            value={accountId || ''}
          />
          <TextField
            label="Group Number"
            id="group-number"
            fullWidth
            onChange={editGroupNumber}
            value={groupNumber || ''}
          />
          <TextField
            label="Alternate Healthplan Patient ID"
            id="alternate-healthplan-patient-id"
            fullWidth
            onChange={editHealthplanPatientId}
            value={healthplanPatientId || ''}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Effective Date"
            onChange={editEffectiveDate}
            type="date"
            required
            value={effectiveDate || ''}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Expiration Date"
            onChange={editExpirationDate}
            type="date"
            value={expirationDate || ''}
          />
          {healthplanSpecificProgramList.length > 0 && (
            <FormControl>
              <InputLabel htmlFor="subscriber-choice">
                Healthplan Specific Program
              </InputLabel>
              <Select
                classes={{ root: classes.selectRoot }}
                className={classes.select}
                input={
                  <Input
                    name="healthplan_specific_program"
                    id="healthplan_specific_program"
                  />
                }
                name="healthplan_specific_program"
                onChange={editHealthplanSpecificPrograms}
                value={healthplanSpecificPrograms || ''}
              >
                <MenuItem key={'none'} value={null}>
                  -- None --
                </MenuItem>
                {healthplanSpecificProgramList.map(({ value }) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <TextField
            label="Insurance Notes"
            id="insurance-notes"
            fullWidth
            onChange={editInsuranceNotes}
            value={insuranceNotes || ''}
          />
          <TextField
            label="Priority"
            id="priority"
            fullWidth
            required
            type="number"
            onChange={editPriority}
            value={priority || ''}
          />
          <FormControl required>
            <InputLabel htmlFor="subscriber-choice">Subscriber</InputLabel>
            <Select
              classes={{ root: classes.selectRoot }}
              className={classes.select}
              input={<Input name="subscriber-choice" id="subscriber-choice" />}
              name="subscriber-choice"
              onChange={editSubscriberChoice}
              value={subscriberChoice || ''}
            >
              {SUBSCRIBER_CHOICES.map((reason, index) => (
                <MenuItem key={index} value={reason}>
                  {reason}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Subscriber Name"
            id="subscriber-name"
            fullWidth
            onChange={editsubscriberName}
            value={subscriberName || ''}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            label="Subscriber DOB"
            onChange={editSubscriberDob}
            type="date"
            value={subscriberDob || ''}
          />
        </div>
      </ActionDialog>
      <Dialog open={dialogOpen} onClose={closeDialog} onExited={onClose}>
        <DialogTitle>Would you like to close the current coverage?</DialogTitle>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() =>
              saveCoverage({ ...state, termPrevCoverage: false, patientId })
            }
          >
            No
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              saveCoverage({ ...state, termPrevCoverage: true, patientId })
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

CoverageCreationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  saveCoverage: PropTypes.func.isRequired,
  primaryContracts: PropTypes.array,
  patientId: PropTypes.string,
  healthplanProgramList: PropTypes.array,
  activeCoverage: PropTypes.array,
}

const mapStateToProps = state => ({
  open: isCreatingCoverage(state),
  primaryContracts: getChannelList(state),
  healthplanProgramList: getHealthplanProgramList(state),
})

const mapDispatchToProps = {
  onClose: () => coverageSelectedForCreation(false),
  saveCoverage: saveNewInsuranceCoverage.requested,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(CoverageCreationDialog)
