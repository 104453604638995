import { List, Map, Record } from 'immutable'
import { get as loGet } from 'lodash'
import { createSelector } from 'reselect'
import { getUsers } from '~/data/users'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import appKey from '../key'
import { getRoot } from './common/shared'
import { getCurrentCareTeamId } from './currentCareTeamId'
import { getCurrentPatientId } from './currentPatientId'

export const REQUEST_FULL_CARE_TEAM_LIST = `${appKey}/REQUEST_FULL_CARE_TEAM_LIST`

export const requestFullCareTeamList = () => ({
  type: REQUEST_FULL_CARE_TEAM_LIST,
})

const careTeamsKey = 'careTeams'
const rolesKey = 'careTeamRoles'
const typesKey = 'careTeamTypes'
const patientCareTeamsKey = 'patientCareTeamId'

export const CareTeamRole = Record({
  key: null,
  label: null,
})

export const CareTeamType = Record({
  key: null,
  label: null,
  defaultPrimaryRole: null,
  requiredRoles: null,
  programs: null,
})

export const CareTeam = Record({
  id: null,
  label: null,
  members: [],
  primaryRole: null,
  type: null,
  validStateCodes: null,
  market: null,
  pods: null,
})

export const CareTeamMember = Record({
  careTeamId: null,
  role: null,
  user: null,
  userId: null,
})

export const transformCareTeam = data => {
  if (data) {
    const { members = [], ...CareTeamInfo } = data

    return CareTeam({
      members: into(CareTeamMember, 'role')(members),
      ...CareTeamInfo,
    })
  } else {
    return new CareTeam({
      members: List(),
    })
  }
}

export const fetchCareTeams = Request({
  typePrefix: appKey,
  typeBase: 'FETCH_CARETEAMS',
  operation: () => AspireAPI.get(`care_teams/care_team`),
  transform: into(transformCareTeam, 'id'),
  messages: { failed: 'There was a problem fetching care teams' },
})

export const changePatientProgramAndCareTeam = Request({
  typePrefix: appKey,
  typeBase: 'CHANGE_PATIENT_CARE_TEAM',
  requestParams: ['patientId', 'careTeamId', 'programEnrolled'],
  operation: (patientId, careTeamId, programEnrolled) =>
    AspireAPI.put(`care_teams/patient/${patientId}/care_team`, {
      careTeamId,
      programEnrolled,
    }),
  messages: { failed: 'There was a problem changing patient care team' },
})

export const fetchPatientCareTeam = Request({
  typePrefix: appKey,
  typeBase: 'FETCH_PATIENT_CARETEAMS',
  requestParams: ['patientId'],
  operation: patientId =>
    AspireAPI.get(`care_teams/patient/${patientId}/care_team`),
  transform: transformCareTeam,
  messages: { failed: 'There was a problem fetching patient care team' },
})

export const addCareTeam = Request({
  typePrefix: appKey,
  typeBase: 'ADD_CARETEAM',
  requestParams: ['label', 'type', 'members', 'validStateCodes'],
  operation: (label, type, members, validStateCodes) =>
    AspireAPI.post('care_teams/care_team', {
      label,
      validStateCodes,
      type,
      members: members.map(mem => {
        mem.user_id = mem.userId
        delete mem['userId']
        return mem
      }),
    }),
  transform: transformCareTeam,
  messages: { failed: 'There was a problem adding this CareTeam' },
})

export const fetchCareTeamRoles = Request({
  typePrefix: appKey,
  typeBase: 'FETCH_CARE_TEAM_ROLES',
  operation: () => AspireAPI.get(`care_teams/roles`),
  transform: into(CareTeamRole, 'key'),
  messages: { failed: 'There was a problem fetching care team roles' },
})

export const fetchCareTeamTypes = Request({
  typePrefix: appKey,
  typeBase: 'FETCH_CARE_TEAM_TYPES',
  operation: () => AspireAPI.get(`care_teams/types`),
  transform: into(CareTeamType, 'key'),
  messages: { failed: 'There was a problem fetching care team types' },
})

export const changeCareTeam = Request({
  typePrefix: appKey,
  typeBase: 'CHANGE_CARE_TEAM',
  requestParams: ['careTeamId', 'label', 'validStateCodes'],
  operation: (careTeamId, label, validStateCodes) =>
    AspireAPI.put(`care_teams/care_team/${careTeamId}`, {
      label,
      validStateCodes,
    }),
  messages: { failed: 'There was a problem updating care team' },
})

export const changeCareTeamMember = Request({
  typePrefix: appKey,
  typeBase: 'CHANGE_CARE_TEAM_MEMBER',
  requestParams: ['careTeamId', 'role', 'userId'],
  operation: (careTeamId, role, userId) =>
    AspireAPI.put(`care_teams/${careTeamId}/member/${role}`, { userId }),
  messages: { failed: 'There was a problem updating care team member' },
})

export const addCareTeamMember = Request({
  typePrefix: appKey,
  typeBase: 'ADD_CARE_TEAM_MEMBER',
  requestParams: ['careTeamId', 'role', 'userId'],
  operation: (careTeamId, role, userId) =>
    AspireAPI.post(`care_teams/${careTeamId}/member`, { role, userId }),
  messages: { failed: 'There was a problem adding care team member' },
})

export const deleteCareTeamMember = Request({
  typePrefix: appKey,
  typeBase: 'REMOVE_CARE_TEAM_MEMBER',
  requestParams: ['careTeamId', 'role', 'userId'],
  operation: (careTeamId, role) =>
    AspireAPI.delete(`care_teams/${careTeamId}/member/${role}`),
  messages: { failed: 'There was a problem removing care team member' },
})

export const bulkReplaceCareTeamUser = Request({
  typePrefix: appKey,
  typeBase: 'BULK_REPLACE_CARE_TEAM_MEMBER',
  requestParams: ['type', 'role', 'originalUser', 'newUser'],
  operation: (type, role, originalUser, newUser) =>
    AspireAPI.put(`care_teams/care_team_member_replacement`, {
      type,
      role,
      originalUser,
      newUser,
    }),
  messages: {
    succeeded: 'Care teams successfully updated',
    failed: 'There was a problem removing care team member',
  },
})

const CARE_POD_REMOVED = `${appKey}/CARE_TEAM_POD_REMOVED`
const CARE_POD_ADDED = `${appKey}/CARE_POD_ADDED`

export const careTeamPodRemoved = (careTeamId, podId) => ({
  type: CARE_POD_REMOVED,
  payload: { careTeamId, podId },
})

export const careTeamPodAdded = (careTeamId, podId) => ({
  type: CARE_POD_ADDED,
  payload: { careTeamId, podId },
})

export const careTeamsReducer = createReducer(careTeamsKey, Map(), {
  [fetchCareTeams.SUCCEEDED]: (_state, { payload }) => payload,
  [fetchPatientCareTeam.SUCCEEDED]: (state, { payload }) =>
    state.set(payload.id, payload),
  [changeCareTeam.SUCCEEDED]: (state, data) => {
    const payload = loGet(data, 'meta.request.payload')
    return state
      .setIn([payload.careTeamId, 'label'], payload.label)
      .setIn([payload.careTeamId, 'validStateCodes'], payload.validStateCodes)
  },
  [addCareTeam.SUCCEEDED]: (state, data) => {
    const payload = data.payload
    return state.set(payload.id, payload)
  },
  [changeCareTeamMember.SUCCEEDED]: (state, data) => {
    const payload = loGet(data, 'meta.request.payload')
    return state.setIn(
      [payload.careTeamId, 'members', payload.role, 'userId'],
      payload.userId
    )
  },
  [addCareTeamMember.SUCCEEDED]: (state, data) => {
    const payload = loGet(data, 'meta.request.payload')
    return state.setIn(
      [payload.careTeamId, 'members', payload.role],
      CareTeamMember({
        careTeamId: payload.careTeamId,
        role: payload.role,
        user: null,
        userId: payload.userId,
      })
    )
  },

  [deleteCareTeamMember.SUCCEEDED]: (state, data) => {
    const payload = loGet(data, 'meta.request.payload')
    return state.removeIn([payload.careTeamId, 'members', payload.role])
  },
  [CARE_POD_ADDED]: (state, { payload }) => {
    const podList = state
      .getIn([payload.careTeamId, 'pods'], [])
      .concat(payload)
    return state.setIn([payload.careTeamId, 'pods'], podList)
  },
  [CARE_POD_REMOVED]: (state, { payload }) => {
    const podList = state
      .getIn([payload.careTeamId, 'pods'], [])
      .filter(x => x.podId !== payload.podId)
    return state.setIn([payload.careTeamId, 'pods'], podList)
  },
})

export const rolesReducer = createReducer(rolesKey, Map(), {
  [fetchCareTeamRoles.SUCCEEDED]: (_state, { payload }) => payload,
})

export const typesReducer = createReducer(typesKey, Map(), {
  [fetchCareTeamTypes.SUCCEEDED]: (_state, { payload }) => payload,
})

export const patientCareTeamsReducer = createReducer(
  patientCareTeamsKey,
  Map(),
  {
    [fetchPatientCareTeam.SUCCEEDED]: (state, data) => {
      const patientId = loGet(data, 'meta.request.payload.patientId')
      const payload = data.payload
      return state.set(patientId, payload.id)
    },
    [changePatientProgramAndCareTeam.SUCCEEDED]: (state, data) => {
      const patientId = loGet(data, 'meta.request.payload.patientId')
      const careTeamId = loGet(data, 'meta.request.payload.careTeamId')
      return state.set(patientId, careTeamId)
    },
  }
)

export const getCareTeams = pipe(getRoot, get(careTeamsKey))

export const getPatientCareTeams = pipe(getRoot, get(patientCareTeamsKey))

const getCareTeamById = state => id => pipe(getCareTeams, get(id))(state)

export const getPatientCareTeamById = patientId => state =>
  pipe(getPatientCareTeams, get(patientId), getCareTeamById(state))(state)

export const getRoles = pipe(getRoot, get(rolesKey))

export const getTypes = pipe(getRoot, get(typesKey))

export const getCurrentCareTeamDetails = createSelector(
  [getCareTeams, getUsers, getCurrentCareTeamId],
  (careTeams, users, careTeamId) => {
    const ct = careTeams.get(careTeamId)
    if (ct) {
      const updatedMembers = ct.members.map(member => {
        const userDetail = users.get(member.userId)
        return member.set('user', userDetail)
      })

      return ct.set('members', updatedMembers.toList()).toJS()
    } else {
      return null
    }
  }
)

export const getPatientCareTeamDetails = createSelector(
  [getCareTeams, getUsers, getCurrentPatientId, getPatientCareTeams],
  (careTeams, users, patientId, patientCareTeams) => {
    const ctId = patientCareTeams.get(patientId)
    const ct = careTeams.get(ctId)
    if (ct) {
      const updatedMembers = ct.members.map(member => {
        const userDetail = users.get(member.userId)
        return member.set('user', userDetail)
      })

      return ct.set('members', updatedMembers.toList()).toJS()
    } else {
      return null
    }
  }
)

export const getCareTeamsWithUsers = createSelector(
  [getCareTeams, getUsers],
  (careTeams, users) => {
    return careTeams
      .map(ct => {
        const updatedMembers = ct.members.map(member => {
          const userDetail = users.get(member.userId)
          return member.set('user', userDetail)
        })

        return ct.set('members', updatedMembers.toList())
      })
      .toList()
      .toJS()
  }
)
