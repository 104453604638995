import { format } from 'date-fns'
import { List, Record } from 'immutable'
import { startCase } from 'lodash'
import setTaggedValue from '~/components/JsonForm/utils/setTaggedValue'
import { Form } from '~/features/caremoreLogCommunication/data/form'
import key from '~/features/communications/key'
import { Contact } from '~/features/quickCallLogger/data/common/shared'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { removeUnderscores } from '~/utils/stringManipulation'

const FETCH_COMMUNICATIONS_KEY = 'fetchCommunications'

export const Communication = Record({
  type: '',
  contact: '',
  mode: '',
  direction: '',
  purpose1: '',
  outcome: '',
  ctRole: '',
  purpose2: '',
  logger: '',
  time: '',
  notes: '',
  id: null,
  contactDetail: {},
  careteam: {},
  extensivist: {},
  form: {},
})

const FormOverride = Record({
  label: '',
  value: '',
  schemaValue: '',
})

const formatERVisitString = ({ facility, discharge, admit } = {}) => {
  ;[admit, discharge] = [admit, discharge].map(d =>
    d ? format(Date.parse(d), 'MM/dd/yyyy hh:mm') : ''
  )
  discharge = discharge ? `${discharge} -` : ''
  return admit ? `${admit} - ${discharge} ${facility}` : facility
}

const getPurposeString = (
  { contactPurpose, name, ...formData },
  decodedData,
  logSource
) => {
  if (logSource.substring(0, 6) === 'Letter') {
    return logSource.replace('Letter - ', '')
  }
  switch (contactPurpose) {
    case 'appointment_scheduling':
      return decodedData?.appointmentType || formData?.appointmentType
    case 'care_coordination':
      return (
        decodedData?.typeOfCareCoordination || formData?.typeOfCareCoordination
      )
    case 'self_referral':
      return formData?.direction == 'outbound'
        ? decodedData?.referralNotes || formData?.referralNotes
        : decodedData?.describe || formData?.describe
    case 'transplant':
      return decodedData?.stageOfProcess || formData?.stageOfProcess
    case 'er_diversion':
    case 'er_follow_up':
    case 'transitions_of_care':
      return formatERVisitString(formData)
    default:
      return ''
  }
}

const admissionOverride = (direction, contactPurpose, value) => {
  let label = ''
  if (direction == 'inbound') {
    switch (contactPurpose) {
      case 'er_follow_up':
        label = 'chooseAdmissionInboundERVisit'
        break
      case 'transitions_of_care':
        label = 'chooseAdmissionInTransitionsOfCare'
        break
    }
  } else {
    switch (contactPurpose) {
      case 'er_follow_up':
        label = 'chooseAdmission'
        break
      case 'transitions_of_care':
        label = 'chooseAdmissionOutTransitionsOfCare'
        break
    }
  }
  return value
    ? FormOverride({
        label: label,
        schemaValue: {
          enumNames: [value],
          enum: [value],
        },
        value: value,
      })
    : null
}

// Update formData and schema for data selections that were dynamically generated off patient info
const applyFormOverrides = (form, overrides) => {
  Object.keys(form.tags).forEach(t => form.tags[t].data.shift())
  return overrides
    .filter(o => o)
    .reduce((form, o) => (form = setTaggedValue(form, o.label, o)), form)
}

const formTransform = (form, type, formOverrides) => {
  let { schema, uiSchema } = form
  schema = applyFormOverrides(form, formOverrides)?.schema
  const { properties } = schema
  schema = properties[type] || properties[Object.keys(properties)[0]]
  uiSchema = uiSchema[type] || uiSchema[uiSchema['ui:order'][0]]

  return Form({ ...form, schema, uiSchema })
}

const getUniqueCareTeam = (data, ct = {}) => {
  return data?.filter(c => {
    if (!ct?.[c.id]) {
      ct[c.id] = c
      return true
    } else return false
  })
}

const getTypeString = ({ str, contactCategory }, decodedData) => {
  const type = removeUnderscores(str.substring(18))
  switch (type) {
    case 'pcp':
      return 'PCP'
    case 'other':
      return `Other - ${decodedData?.contactCategory || contactCategory}`
    default:
      return startCase(type)
  }
}

export const fetchCommunicationsRequest = Request({
  typePrefix: key,
  typeBase: FETCH_COMMUNICATIONS_KEY,
  requestParams: ['patientId'],
  operation: patientId =>
    AspireAPI.get(`v1/patients/${patientId}/log_communications`),
  transform: communications =>
    List(
      communications.map(
        ({
          type,
          loggedAt,
          id,
          form,
          decodedData,
          ervisit = [],
          contact = [],
          careteam = [],
          extensivist,
          loggedByDetail,
          logSource,
        }) => {
          try {
            const { formData } = form
            return Communication({
              type: getTypeString({ str: type, ...formData }, decodedData),
              contact: contact?.[0]?.name || formData?.name,
              mode: decodedData?.modeOfContact || formData?.modeOfContact,
              direction: decodedData?.direction || formData?.direction,
              purpose1: decodedData?.contactPurpose || formData?.contactPurpose,
              outcome: decodedData?.outcome || formData?.outcome,
              ctRole: loggedByDetail?.roles?.[0],
              purpose2: getPurposeString(
                { ...formData, ...ervisit?.[0] },
                decodedData,
                logSource
              ),
              logger: loggedByDetail?.name,
              time: loggedAt,
              notes: formData?.contactNotes,
              id: id,
              contactDetail: Contact(contact?.[0]),
              careteam: getUniqueCareTeam(careteam),
              extensivist: extensivist,
              form: formTransform(form, type, [
                admissionOverride(
                  formData?.direction,
                  formData?.contactPurpose,
                  formatERVisitString(ervisit?.[0])
                ),
              ]),
            })
          } catch (error) {
            return Communication({
              form: form,
              type: type,
              time: loggedAt,
              id: id,
              error: error,
            })
          }
        }
      )
    ),
  messages: {
    failed: 'Failed to fetch patient communications',
  },
})

export const getCommunicationsRows = get(key)

export const fetchCommunicationsReducer = createReducer('rows', List(), {
  [fetchCommunicationsRequest.SUCCEEDED]: (_state, { payload }) => payload,
})
