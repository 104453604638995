import epic from './epic'
import reducer from './reducer'
export { epic, reducer }
export { ORDER_SELECTED, getSelectedOrder } from './data/selectedOrder'
export { getCurrentlyAssignedOrders } from './data/orders'
export { default as OrderFullView } from './containers/OrderFullView' // this is a view for only one order - with id in route
export { newOrderDialogOpened } from './data/dialog'
export { default as NewOrderDialog } from './containers/NewOrderDialog'
export { default as FlagOrders } from './containers/OrdersFeatureToggle/FlagOrders'
export { getOrdersCount } from './data/common/derived'
export { orderSelected } from './data/selectedOrder'
