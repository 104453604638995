import { KeycloakConfig } from 'keycloak-js'

export const ASPIRE_API_PATH = window.env.ASPIRE_API_PATH
export const CUP_PATH = window.env.CUP_PATH
export const ORC_API_PATH = window.env.ORC_API_PATH
export const SOCKET_PATH = window.env.SOCKET_PATH
export const ORDERS_API_PATH = window.env.ORDERS_API_PATH
export const CLASSIC_APP_PATH = window.env.CLASSIC_APP_PATH
export const NAVIGATOR_PATH = window.env.NAVIGATOR_PATH
export const PATIENT_QUICK_VIEW_PATH = window.env.PATIENT_QUICK_VIEW_PATH
export const keycloak_config: KeycloakConfig = window.env.keycloak
