import React from 'react'
import Icon from '~/components/Icon'
import { christmasStyles, christmasTheme } from './christmas'
import { fallStyles, fallTheme } from './fall'
import { springStyles, springTheme } from './spring'
import { stPatricksDayStyles, stPatricksDayTheme } from './stPatricksDay'
import { summerStyles, summerTheme } from './summer'
import { valentinesDayStyles, valentinesDayTheme } from './valentinesDay'
export { default as HolidayLights } from './christmas/HolidayLights'

export const currentHoliday = 'fall'

const generateDrawerIcon = props => {
  if (currentHoliday === 'stpatricks') {
    return <div className="holidayIcon"></div>
  } else {
    return props ? <Icon className="holidayIcon">{props}</Icon> : null
  }
}

export const addHolidayClass = () => {
  document.querySelector('.header-bar').classList.add('holiday-app-bar')
}

export const removeHolidayClass = () => {
  document.querySelector('.header-bar').classList.remove('holiday-app-bar')
}

export const holidayTheme = holiday => {
  switch (holiday) {
    case 'christmas':
      return christmasTheme()
    case 'fall':
      return fallTheme()
    case 'spring':
      return springTheme()
    case 'stpatricks':
      return stPatricksDayTheme()
    case 'summer':
      return summerTheme()
    case 'valentines':
      return valentinesDayTheme()
    default:
      return {}
  }
}

export const holidayStyles = holiday => {
  switch (holiday) {
    case 'christmas':
      return christmasStyles(holiday)
    case 'fall':
      return fallStyles(holiday)
    case 'spring':
      return springStyles(holiday)
    case 'stpatricks':
      return stPatricksDayStyles(holiday)
    case 'summer':
      return summerStyles(holiday)
    case 'valentines':
      return valentinesDayStyles(holiday)
    default:
      return {}
  }
}

export const holidayDrawerIcon = holiday => {
  switch (holiday) {
    case 'fall':
      return generateDrawerIcon('aspire:autumn_leaf')
    case 'spring':
      return generateDrawerIcon()
    case 'stpatricks':
      return generateDrawerIcon()
    case 'valentines':
      return generateDrawerIcon('material:favorite')
    default:
      return null
  }
}

export const holidayLightsIcon = holiday => {
  switch (holiday) {
    case 'christmas':
      return 'material:ac_unit'
    case 'fall':
      return 'aspire:pumpkin'
    case 'spring':
      return 'material:local_florist'
    case 'stpatricks':
      return 'aspire:clover'
    case 'summer':
      return 'material:wb_sunny'
    case 'valentines':
      return 'material:favorite'
    default:
      return 'material:invert_colors'
  }
}
