import React from 'react'
// @ts-expect-error ts-migrate(6133) FIXME: Could not find declaration file for module... Remove this comment to see the full error message
import PropTypes from '~/utils/propTypes'
import { AddressList } from './AddressDetails/AddressList'
import { EmailList } from './EmailDetails/EmailList'
import { PhoneList } from './PhoneDetails/PhoneList'

interface ContactDetailsPropTypes {
  contact: PropTypes.object.isRequired
}

export const ContactDetails = ({ contact }: ContactDetailsPropTypes) => {
  return (
    <React.Fragment>
      {contact.phones && contact.phones.length ? (
        <PhoneList phones={contact.phones} />
      ) : null}
      <EmailList emails={contact.emails} asked={contact.emailAsked} />
      {contact.addresses && contact.addresses.length ? (
        <AddressList addresses={contact.addresses} />
      ) : null}
    </React.Fragment>
  )
}
