import React from 'react'
import { useSelector } from 'react-redux'
import { usePending } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { Typography } from '@material-ui/core'
import {
  fetchCallAttempt,
  fetchCallLogStatus,
  getCallLog,
} from '../../data/callLog'
import { fetchPatientInfo } from '../../data/patientInfo'
import ActiveCall from './ActiveCall'
import CallLogInfo from './CallLogInfo'
import CompleteAttemptButton from './CompleteAttemptButton'
import Contacts from './Contacts'
import Header from './Header'

const LogCall = ({ intentsFilter, defaultIntent }) => {
  const callLog = useSelector(getCallLog)

  const statusPending = usePending(fetchCallLogStatus)
  const attemptPending = usePending(fetchCallAttempt)
  const infoPending = usePending(fetchPatientInfo)

  const loaded =
    callLog.patientId && !statusPending && !attemptPending && !infoPending

  return loaded ? (
    <React.Fragment>
      <Header />
      <CallLogInfo
        intentsFilter={intentsFilter}
        defaultIntent={defaultIntent}
      />

      {callLog.activeContact ? <ActiveCall /> : <Contacts />}

      <CompleteAttemptButton />
    </React.Fragment>
  ) : callLog.patientId ? (
    <Typography variant="h6">Loading...</Typography>
  ) : null
}

LogCall.propTypes = {
  intentsFilter: PropTypes.array,
  defaultIntent: PropTypes.string,
}

export default LogCall
