import { changedTo } from 'redux-routable'
import { of } from 'rxjs'
import { distinctUntilChanged, filter, mergeMap, pluck } from 'rxjs/operators'
import { fetchAssessments } from '~/apps/assessment/data/assessments'
import { fetchCaseManagerCensus } from '~/features/patientCensus'
import { ownerTasksFetchRequested } from '~/features/tasks'
import { fetchInterventions } from '../data/intervention'
import router from '../router'

export default action$ =>
  action$.pipe(
    filter(changedTo(router)),
    pluck('payload', 'params', 'cmId'),
    distinctUntilChanged(),
    mergeMap(userId =>
      of(
        ownerTasksFetchRequested(userId),
        fetchCaseManagerCensus.requested(userId),
        fetchAssessments.requested({
          provider_ids: [userId],
          statuses: ['not_started', 'created', 'in_progress'],
        }),
        fetchInterventions.requested(userId)
      )
    )
  )
