import { Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import hospitalizationsKey from '../key'

const key = 'hospitalizationsData'

export const Hospitalization = Record({
  id: null,
  caseNumber: null,
  status: null,
  bedType: null,
  hospitalId: null,
  hospitalName: null,
  reason: null,
  otherHospitalizationReason: null,
  admissionDate: null,
  dischargeDate: null,
  notifiedBy: null,
  notifiedByOther: null,
  dischargedFromHospitalTo: null,
  dischargedFromHospitalToOther: null,
  wasHospitalizationAvoidable: null,
  aspireContactedPrior: null,
  aspireContactedPriorNo: null,
  otherHospitalizationInfo: null,
  includedObservationStay: null,
  admissionDiagnoses: null,
  dischargeDiagnoses: null,
  authorizationNumber: null,
  reviewStatus: null,
  avoidabilityReasons: null,
  avoidabilityComments: null,
  createdBy: null,
  createdAt: null,
  pearFlag: null,
  ascFlag: null,
})

export const CaremoreHospitalization = Record({
  id: null,
  authNumber: null,
  admitDate: null,
  admittingDiagnosisDescription: null,
  admittingDiagnosis: null,
  admissionSource: null,
  dischargeDate: null,
  dischargeDisposition: null,
  dischargeDiagnosis: null,
  dischargeDiagnosisDescription: null,
  dischargingExtensivist: null,
  facilityName: null,
  includedObservationStay: null,
  observationStay: null,
  notes: null,
  outOfNetwork: null,
  placeOfService: null,
  status: null,
  tocRisk: null,
  tocRiskEscalationCriteria: null,
  authReason: null,
  authStatus: null,
  showInCensus: null,
  pearFlag: null,
  ascFlag: null,
  metalTier: null,
})

export const fetchHospitalizations = Request({
  typePrefix: key,
  typeBase: 'FETCH_HOSPITALIZATIONS',
  requestParams: ['patientId'],
  operation: patientId =>
    AspireAPI.get(`hospitalizations/patient/${patientId}`),
  transform: into(Hospitalization, 'id'),
  messages: { failed: 'There was a problem fetching hospitalizations' },
})

export const fetchCaremoreHospitalizations = Request({
  typePrefix: key,
  typeBase: 'FETCH_HOSPITALIZATIONS',
  requestParams: ['patientId'],
  operation: patientId =>
    AspireAPI.get(`hospitalizations/patient/${patientId}`),
  transform: into(CaremoreHospitalization, 'id'),
  messages: { failed: 'There was a problem fetching hospitalizations' },
})

export const addHospitalization = Request({
  typePrefix: key,
  typeBase: 'ADD_HOSPITALIZATION',
  requestParams: ['patientId', 'hospitalization'],
  operation: (patientId, hospitalization) =>
    AspireAPI.post(`hospitalizations/patient/${patientId}`, {
      hospitalization,
    }),
  transform: Hospitalization,
  messages: { failed: 'There was a problem adding this hospitalization' },
})

export const saveHospitalization = Request({
  typePrefix: key,
  typeBase: 'SAVE_HOSPITALIZATION',
  requestParams: ['hospitalization'],
  operation: hospitalization =>
    AspireAPI.put(`hospitalizations/${hospitalization.id}`, {
      hospitalization,
    }),
  messages: { failed: 'There was a problem saving this hospitalization' },
})

export const deleteHospitalization = Request({
  typePrefix: key,
  typeBase: 'DELETE_HOSPITALIZATION',
  requestParams: ['hospitalizationId'],
  operation: hospitalizationId =>
    AspireAPI.delete(`hospitalizations/${hospitalizationId}`),
  messages: { failed: 'There was a problem deleting this hospitalization' },
})

export default createReducer(key, Map(), {
  [fetchHospitalizations.SUCCEEDED]: (_state, { payload }) => payload,
  [addHospitalization.SUCCEEDED]: (state, { payload }) =>
    state.set(payload.id, payload),
  [saveHospitalization.SUCCEEDED]: (
    state,
    {
      meta: {
        request: { payload },
      },
    }
  ) => state.set(payload.hospitalization.id, payload.hospitalization),
  [deleteHospitalization.SUCCEEDED]: (
    state,
    {
      meta: {
        request: { payload },
      },
    }
  ) => state.delete(payload.hospitalizationId),
})

export const getHospitalizations = pipe(get(hospitalizationsKey), get(key))
