import { List } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(2307) FIXME
import Request from '~/utils/Request'
// @ts-expect-error ts-migrate(2307) FIXME
import createReducer from '~/utils/createReducer'
import { scopedCreator, type } from '~/utils/data'
import rootKey from '../key'
import {
  getProblemIndexById,
  updateEnabledModifier,
  updateEnabledModifiers,
  updateModifierSelected,
  updateModifiersInModifierGroup,
  updateProblemCategoriesStatus,
  updateProblemCategory,
  updateProblemDiagnosis,
  updateProblemWorkflow,
  updateSecondaryProblemCategories,
  updateSecondaryProblemCategoriesStatus,
  updateSecondaryProblemCategory,
  updateSecondaryProblemDiagnosis,
  updateSecondaryProblemDiagnosisStatus,
  updateWorkflowLoaded,
} from './utils/helpers'
import {
  Imo,
  transformImoProblems,
  transformImoSearch,
  transformImoSelectedProblem,
  transformImoWorkflow,
  transformProblemInformation,
  transformProblemTypes,
} from './utils/transformers'

const key = 'imoSearch'

const creator = scopedCreator(key)
export const setSearchCriteria: any = creator(type(rootKey, 'SEARCH_CRITERIA'))
export const cancelSearch: any = creator(type(rootKey, 'CANCEL_SEARCH'))
export const noSuggestionSelected: any = creator(
  type(rootKey, 'NO_SUGGESTION_SELECTED')
)
export const clearSuggestions: any = creator(type(rootKey, 'CLEAR_SUGGESTIONS'))
export const clearImo: any = creator(type(rootKey, 'CLEAR_IMO'))
export const updateModifiersInGroup: any = creator(
  type(rootKey, 'UPDATE_MODIFIERS_IN_GROUP')
)
export const deleteModifiersInGroup: any = creator(
  type(rootKey, 'DELETE_MODIFIERS_IN_GROUP')
)
export const setEnabledModifiers: any = creator(
  type(rootKey, 'SET_ENABLED_MODIFIERS')
)
export const setEnabledModifier: any = creator(
  type(rootKey, 'SET_ENABLED_MODIFIER')
)
export const updateSelectedProblems: any = creator(
  type(rootKey, 'UPDATE_SELECTED_PROBLEMS')
)
export const addSelectedProblem: any = creator(
  type(rootKey, 'ADD_SELECTED_PROBLEM')
)
export const updateCategoriesStatus: any = creator(
  type(rootKey, 'UPDATE_CATEGORIES_STATUS')
)
export const updateSecondaryCategory: any = creator(
  type(rootKey, 'UPDATE_SECONDARY_CATEGORY')
)
export const updateSecondaryCategoriesStatus: any = creator(
  type(rootKey, 'UPDATE_SECONDARY_CATEGORIES_STATUS')
)
export const updateSecondaryDiagnosisStatus: any = creator(
  type(rootKey, 'UPDATE_SECONDARY_DIAGNOSIS_STATUS')
)
export const updateProblemsStatus: any = creator(
  type(rootKey, 'UPDATE_PROBLEMS_STATUS')
)
export const setWorkflowLoaded: any = creator(
  type(rootKey, 'SET_WORKFLOW_LOADED')
)
export const setModifierSelected: any = creator(
  type(rootKey, 'SET_MODIFIER_SELECTED')
)

export const fetchImoSessionId = Request({
  typePrefix: key,
  typeBase: 'GET_IMO_SESSIONS',
  requestParams: ['patientId'],
  operation: (patientId: string) => AspireAPI.get(`/imo/${patientId}/session`),
  messages: { failed: 'There was a problem getting IMO session id' },
})

export const fetchSuggestedImoProblems = Request({
  typePrefix: key,
  typeBase: 'FETCH_IMO_SUGGESTIONS',
  requestParams: [
    'patientId',
    'sessionId',
    'searchTerm',
    'filterBySex',
    'filterByAge',
  ],
  operation: (
    patientId: string,
    sessionId: string,
    searchTerm: string,
    filterBySex: boolean,
    filterByAge: boolean
  ) =>
    AspireAPI.get(`/imo/${patientId}/suggestions`, {
      params: { sessionId, searchTerm, filterBySex, filterByAge },
    }),
  transform: transformImoSearch,
  messages: { failed: 'There was a problem getting IMO suggestions' },
})

export const sendSuggestionSelected = Request({
  typePrefix: key,
  typeBase: 'SEND_SUGGESTION_SELECTED',
  requestParams: ['requestId', 'sessionId', 'suggestionText', 'rank'],
  operation: (
    requestId: string,
    sessionId: string,
    suggestionText: string,
    rank: number
  ) =>
    AspireAPI.get(`/imo/suggestion_selected`, {
      params: { sessionId, requestId, suggestionText, rank },
    }),
  messages: {
    failed: 'There was a problem sending the IMO suggestion selected',
  },
})

export const fetchSearchProblems = Request({
  typePrefix: key,
  typeBase: 'FETCH_IMO_SEARCH_PROBLEMS',
  requestParams: [
    'patientId',
    'sessionId',
    'searchTerm',
    'filterBySex',
    'filterByAge',
  ],
  operation: (
    patientId: string,
    sessionId: string,
    searchTerm: string,
    filterBySex: boolean,
    filterByAge: boolean
  ) =>
    AspireAPI.get(`/imo/${patientId}/search`, {
      params: { sessionId, searchTerm, filterBySex, filterByAge },
    }),
  transform: transformImoProblems,
  messages: { failed: 'There was a problem getting IMO problems' },
})

export const sendProblemSelected = Request({
  typePrefix: key,
  typeBase: 'SEND_PROBLEM_SELECTED',
  requestParams: ['sessionId', 'imoLexicalCode', 'rank'],
  operation: (sessionId: string, imoLexicalCode: string, rank: number) =>
    AspireAPI.get(`/imo/search_result_selected`, {
      params: { sessionId, imoLexicalCode, rank },
    }),
  messages: {
    failed: 'There was a problem sending the IMO problem selected',
  },
})

export const sendWorkflowPresented = Request({
  typePrefix: key,
  typeBase: 'SEND_WORKFLOW_PRESENTED',
  requestParams: ['sessionId', 'imoLexicalCode', 'rank'],
  operation: (sessionId: string, imoLexicalCode: string, rank: number) =>
    AspireAPI.get(`/imo/modifier_workflow_presented`, {
      params: { sessionId, imoLexicalCode, rank },
    }),
  messages: {
    failed: 'There was a problem sending the IMO workflow presented',
  },
})

export const fetchProblemWorkflow = Request({
  typePrefix: key,
  typeBase: 'FETCH_IMO_PROBLEM_WORKFLOW',
  requestParams: ['patientId', 'sessionId', 'imoLexicalCode'],
  operation: (patientId: string, sessionId: string, imoLexicalCode: number) =>
    AspireAPI.get(`/imo/${patientId}/workflow`, {
      params: { sessionId, imoLexicalCode },
    }),
  transform: transformImoWorkflow,
  messages: { failed: 'There was a problem getting IMO problem workflow' },
})

export const fetchProblemInformation = Request({
  typePrefix: key,
  typeBase: 'FETCH_IMO_PROBLEM_INFO',
  requestParams: ['icd10Code'],
  operation: (icd10Code: string) =>
    AspireAPI.get(`/care_plan/problem_types/search_by_icd10`, {
      params: { icd10Code },
    }),
  transform: transformProblemInformation,
  messages: { failed: 'There was a problem getting IMO problem category' },
})
export const fetchSecondaryICD10Information = Request({
  typePrefix: key,
  typeBase: 'FETCH_SECONDARY_ICD10_INFO',
  requestParams: ['icd10Code'],
  operation: (icd10Code: string) =>
    AspireAPI.get(`/care_plan/problem_types/search_by_icd10`, {
      params: { icd10Code },
    }),
  transform: transformProblemInformation,
  messages: { failed: 'There was a problem getting IMO problem category' },
})

export const fetchProblemTypes = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_PROBLEM_TYPES',
  requestParams: ['problemCategoryId'],
  operation: (problemCategoryId: number) =>
    AspireAPI.get(
      `care_plan/problem_categories/${problemCategoryId}/problem_types`
    ),
  transform: transformProblemTypes,
  messages: { failed: 'There was an issue fetching problem types' },
})

export const fetchSecondaryProblemTypes = Request({
  typePrefix: rootKey,
  typeBase: 'FETCH_SECONDARY_PROBLEM_TYPES',
  requestParams: ['problemCategoryId'],
  operation: (problemCategoryId: number) =>
    AspireAPI.get(
      `care_plan/problem_categories/${problemCategoryId}/problem_types`
    ),
  transform: transformProblemTypes,
  messages: { failed: 'There was an issue fetching problem types' },
})

export const sendModifierSelected = Request({
  typePrefix: key,
  typeBase: 'SEND_MODIFIER_SELECTED',
  requestParams: ['sessionId', 'imoLexicalCode', 'modifierCode'],
  operation: (
    sessionId: string,
    imoLexicalCode: string,
    modifierCode: string
  ) =>
    AspireAPI.get(`/imo/modifier_item_selected`, {
      params: { sessionId, imoLexicalCode, modifierCode },
    }),
  messages: {
    failed: 'There was a problem sending the IMO modifier selected',
  },
})

export const sendResultSelected = Request({
  typePrefix: key,
  typeBase: 'SEND_RESULT_SELECTED',
  requestParams: ['sessionId', 'imoLexicalCode'],
  operation: (sessionId: string, imoLexicalCode: string) =>
    AspireAPI.get(`/imo/modifier_result_selected`, {
      params: { sessionId, imoLexicalCode },
    }),
  messages: {
    failed: 'There was a problem sending the IMO result selected',
  },
})

export const saveImoDetail = Request({
  typePrefix: key,
  typeBase: 'SAVE_IMO_DETAIL',
  requestParams: ['imoDetails'],
  operation: (imoDetails: any) =>
    AspireAPI.post('/imo/add_details', imoDetails),
  messages: {
    failed: 'There was a problem saving the IMO details',
  },
})

const initState = Imo()
export const imoSearch = createReducer(rootKey, initState, {
  [fetchImoSessionId.SUCCEEDED]: (state: any, { payload }: any) =>
    state.mergeDeep(payload),
  [fetchSuggestedImoProblems.SUCCEEDED]: (state: any, { payload }: any) =>
    state.mergeWith((prev: any, next: any, key: string) => {
      if (key === 'searchCriteria') {
        return next.size == 0 ? prev : next
      }
      return next
    }, payload),
  [clearSuggestions]: (state: any, _payload: any) =>
    state.set('suggestions', List([])),
  [clearImo]: () => initState,
  [cancelSearch]: (state: any, _payload: any) => state,
  [noSuggestionSelected]: (state: any, _payload: any) => state,
  [fetchSearchProblems.SUCCEEDED]: (state: any, { payload }: any) =>
    state.set('problems', payload),
  [setSearchCriteria]: (state: any, { payload }: any) =>
    state.set('searchCriteria', payload),
  [fetchProblemWorkflow.SUCCEEDED]: (state: any, { payload }: any) =>
    updateProblemWorkflow(state, payload),
  [fetchProblemInformation.SUCCEEDED]: (state: any, { payload }: any) =>
    updateProblemCategory(state, payload),
  [fetchProblemTypes.SUCCEEDED]: (state: any, { payload }: any) =>
    updateProblemDiagnosis(state, payload),
  [fetchSecondaryProblemTypes.SUCCEEDED]: (state: any, { payload }: any) =>
    updateSecondaryProblemDiagnosis(state, payload),
  [updateModifiersInGroup]: (
    state: any,
    { payload: { allowedModifiers, problemId, modifierGroupType } }: any
  ) =>
    updateModifiersInModifierGroup(
      state,
      allowedModifiers,
      problemId,
      modifierGroupType
    ),
  [setEnabledModifiers]: (state: any, { payload: { problemId } }: any) =>
    updateEnabledModifiers(state, problemId),
  [setEnabledModifier]: (state: any, { payload: { problemId } }: any) =>
    updateEnabledModifier(state, problemId),
  [updateSelectedProblems]: (state: any, { payload }: any) =>
    state.get('selectedProblems').size === 0 ||
    getProblemIndexById(state.get('selectedProblems'), payload.id) === -1
      ? state.updateIn(['selectedProblems'], (selectedProblems: List<any>) =>
          selectedProblems.push(transformImoSelectedProblem(payload))
        )
      : state,
  [addSelectedProblem]: (state: any, { payload }: any) =>
    state.set('problemSelected', payload),
  [updateCategoriesStatus]: (state: any, _payload: any) =>
    updateProblemCategoriesStatus(state),
  [updateSecondaryCategoriesStatus]: (state: any, { payload }: any) =>
    payload ? updateSecondaryProblemCategoriesStatus(state, payload) : state,
  [updateSecondaryDiagnosisStatus]: (state: any, { payload }: any) =>
    payload ? updateSecondaryProblemDiagnosisStatus(state) : state,
  [updateProblemsStatus]: (state: any, _payload: any) =>
    state.updateIn(
      ['problemsLoaded'],
      (problemsLoaded: boolean) => !problemsLoaded
    ),
  [setWorkflowLoaded]: (state: any, _payload: any) =>
    updateWorkflowLoaded(state),
  [setModifierSelected]: (state: any, payload: any) =>
    updateModifierSelected(state, payload),
  [fetchSecondaryICD10Information.SUCCEEDED]: (state: any, { payload }: any) =>
    updateSecondaryProblemCategory(state, payload),
  [updateSecondaryCategory]: (state: any, _payload: any) =>
    updateSecondaryProblemCategories(state),
  [saveImoDetail]: (state: any, _payload: any) => state,
})
