import { Record } from 'immutable'
import { get, typeCreator } from '~/utils/data'
import key from '../../key'

export const CareTeam = Record({
  user_name: null,
  user_id: null,
  role_name: null,
  role: null,
  patient_id: null,
  selected: false,
})

export const Extensivist = Record({
  id: null,
  displayName: null,
  firstName: null,
  middleName: null,
  lastName: null,
  roleId: null,
  username: null,
  deleted: null,
  createdBy: null,
  modifiedBy: null,
})

export const Contact = Record({
  name: null,
  phiContact: false,
  phiVoicemail: false,
  phone: null,
  phoneId: null,
  timestamp: null,
  preferred: false,
  relationship: null,
  type: null,
})

export const PrimaryCarePhysician = Record({
  name: null,
  phone: null,
  practice: null,
  specialty: null,
})

export const Disposition = Record({
  disposition: null,
  dispositionId: null,
  intent: null,
  intentId: null,
})

export const Stakeholder = Record({
  name: null,
  phone: null,
})

export const Vendor = Record({
  name: null,
  phone: null,
})

export const OtherNumber = Record({
  phone: null,
})

export const PreferredPharmacy = Record({
  name: null,
  phone: null,
})

export const QUICK_CALL_LOG = 'quick_call_log'
export const PATIENT = 'PATIENT'
export const PCP = 'PCP'
export const STAKEHOLDER = 'STAKEHOLDER'
export const VENDOR = 'VENDOR'
export const OTHER = 'OTHER'
export const CARE_TEAM = 'CARE_TEAM'
export const EXTENSIVIST = 'EXTENSIVIST'
export const PREFERRED_PHARMACY = 'PREFERRED_PHARMACY'

export const typePrefixCreator = typeCreator(key)

export const getRoot = get(key)
