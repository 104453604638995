import { Map, Record } from 'immutable'
import { createSelector } from 'reselect'
import { from } from 'rxjs'
import { map } from 'rxjs/operators'
import { calcFormData } from '~/components/JsonForm'
import { getUserId } from '~/data/session'
import { Vendor } from '~/data/vendors'
import { getHasPermission } from '~/features/authorization/data/permissions'
import AspireAPI from '~/resources/aspire'
import createReducer from '~/utils/createReducer'
import { creator, type } from '~/utils/data'
import { getOrderKey } from './common/shared'
import { ORDERS_FETCH_REQUESTED } from './orders'
import { getSelectedOrder } from './selectedOrder'

const Form = Record({
  schema: null,
  uiSchema: null,
  tags: null,
  data: null,
  ordersContext: null,
})

const OrderHistory = Record({
  id: null,
  userName: null,
  userId: null,
  orderId: null,
  description: null,
  createdBy: null,
  createdAt: null,
  action: null,
})

const OrderDetail = Record({
  actionApplied: null, // used to know if the user has applied an action and not yet refreshed the orders details
  actions: [],
  canEdit: false,
  cancellationReason: null,
  cancellationReasonKey: null,
  cancelledBy: null,
  createdAt: null,
  createdBy: null,
  currentlyAssignedTo: null,
  currentlyAssignedToName: null,
  currentlyAssignedToRole: null,
  description: null,
  dueDate: null,
  expectedFulfillmentDate: null,
  expirationDate: null,
  form: Form(),
  fulfillmentDate: null,
  history: [],
  id: null,
  lastSaved: null,
  modifiedAt: null,
  modifiedBy: null,
  note: null,
  outOfNetworkReason: null,
  patientAddress: null,
  patientDob: null,
  patientFirstName: null,
  patientFullName: null,
  patientId: null,
  patientLastName: null,
  patientStateCode: null,
  primaryInsurance: null,
  status: null,
  substatus: null,
  subtype: null,
  type: null,
  vendor: Vendor,
  vendorId: null,
  vendorName: null,
  workflowInstanceId: null,
})

export const key = 'orderDetail'

export const ORDER_DETAIL_FETCH_REQUESTED = type(key, 'FETCH_REQUESTED')
export const ORDER_DETAIL_FETCH_SUCCEEDED = type(key, 'FETCH_SUCCEEDED')
export const ORDER_DETAIL_FETCH_FAILED = type(key, 'FETCH_FAILED')
export const ORDER_DETAIL_FORM_CHANGED = type(key, 'FORM_CHANGED')
export const ORDER_DETAIL_FORM_SAVE_REQUESTED = type(key, 'FORM_SAVE_REQUESTED')
export const ORDER_DETAIL_FORM_SAVE_SUCCEEDED = type(key, 'FORM_SAVE_SUCCEEDED')
export const ORDER_DETAIL_FORM_SAVE_FAILED = type(key, 'FORM_SAVE_FAILED')
export const ORDER_SAVE_BUTTON_USED = type(key, 'ORDER_SAVE_BUTTON_USED')
export const ORDER_DETAIL_SAVE_ACTION_REQUESTED = type(key, 'ACTION_REQUESTED')
export const ORDER_DETAIL_SAVE_ACTION_SUCCEEDED = type(key, 'ACTION_SUCCEEDED')
export const ORDER_DETAIL_SAVE_ACTION_FAILED = type(key, 'ACTION_FAILED')
export const VENDOR_CHANGED = type(key, 'VENDOR_CHANGED')
export const ORDER_NOTE_CHANGED = type(key, 'NOTE_CHANGED')
export const EXPECTED_FULFILLMENT_DATE_CHANGED = type(
  key,
  'EXPECTED_FULFILLMENT_DATE_CHANGED'
)
export const FULFILLMENT_DATE_CHANGED = type(key, 'FULFILLMENT_DATE_CHANGED')
export const OUT_OF_NETWORK_REASON_CHANGED = type(
  key,
  'OUT_OF_NETWORK_REASON_CHANGED'
)

export const orderDetailFetchRequested = creator(
  ORDER_DETAIL_FETCH_REQUESTED,
  'id'
)
export const orderDetailFetchSucceeded = creator(
  ORDER_DETAIL_FETCH_SUCCEEDED,
  'order'
)
export const orderDetailFetchFailed = creator(ORDER_DETAIL_FETCH_FAILED)
export const formChanged = creator(ORDER_DETAIL_FORM_CHANGED, 'id', 'formData')

export const saveButtonPressed = creator(ORDER_SAVE_BUTTON_USED, 'id')

export const formSaveRequested = creator(
  ORDER_DETAIL_FORM_SAVE_REQUESTED,
  'id',
  'formData'
)
export const formSaveSuccess = creator(
  ORDER_DETAIL_FORM_SAVE_SUCCEEDED,
  'id',
  'savedOrder'
)
export const formSaveFailed = creator(
  ORDER_DETAIL_FORM_SAVE_FAILED,
  'id',
  'error'
)

export const applyActionRequested = creator(
  ORDER_DETAIL_SAVE_ACTION_REQUESTED,
  'id',
  'action',
  'cancelReason',
  'actionNote'
)

export const applyActionSucceeded = creator(
  ORDER_DETAIL_SAVE_ACTION_SUCCEEDED,
  'order'
)

export const applyActionFailed = creator(
  ORDER_DETAIL_SAVE_ACTION_FAILED,
  'id',
  'error'
)

export const vendorChanged = creator(VENDOR_CHANGED, 'id', 'vendorId', 'vendor')

export const noteChanged = creator(ORDER_NOTE_CHANGED, 'id', 'note')

export const expectedFulfillmentDateChanged = creator(
  EXPECTED_FULFILLMENT_DATE_CHANGED,
  'id',
  'expectedFulfillmentDate'
)

export const fulfillmentDateChanged = creator(
  FULFILLMENT_DATE_CHANGED,
  'id',
  'fulfillmentDate'
)

export const outOfNetworkReasonChanged = creator(
  OUT_OF_NETWORK_REASON_CHANGED,
  'id',
  'outOfNetworkReason'
)

export default createReducer(key, Map(), {
  [ORDER_DETAIL_FETCH_SUCCEEDED]: (state, { payload: { order } }) =>
    state.set(order.id, order),
  [ORDER_DETAIL_FORM_CHANGED]: (state, { payload: { id, formData } }) =>
    state.update(id, detail => {
      const { schema, uiSchema, ordersContext, tags } = detail.form
      const calcData = calcFormData({
        formData,
        schema,
        context: ordersContext,
        tags,
      })
      const newForm = Form({
        schema,
        uiSchema,
        data: calcData,
        ordersContext,
        tags,
      })
      return detail.set('form', newForm)
    }),
  [ORDERS_FETCH_REQUESTED]: () => Map(), // refresh orders...
  [ORDER_DETAIL_FORM_SAVE_SUCCEEDED]: (state, { payload }) => {
    const { id, savedOrder } = payload

    return state.update(id, order =>
      order
        .set('modifiedAt', savedOrder.modified_at)
        .set('lastSaved', savedOrder.modified_at)
        .set('vendorId', savedOrder.vendor_id)
    )
  },
  [ORDER_DETAIL_SAVE_ACTION_SUCCEEDED]: (state, { payload: { order } }) => {
    const updatedOrder = state.get(order.id).set('actionApplied', true)
    return state.set(order.id, updatedOrder)
  },
  [ORDER_NOTE_CHANGED]: (state, { payload: { note, id } }) => {
    const order = state.get(id).set('note', note || '')
    return state.set(id, order)
  },
  [VENDOR_CHANGED]: (state, { payload: { id, vendorId, vendor } }) =>
    state.update(id, o => o.set('vendorId', vendorId).set('vendor', vendor)),
  [FULFILLMENT_DATE_CHANGED]: (state, { payload: { fulfillmentDate, id } }) => {
    const order = state.get(id).set('fulfillmentDate', fulfillmentDate)
    return state.set(id, order)
  },
  [EXPECTED_FULFILLMENT_DATE_CHANGED]: (
    state,
    { payload: { expectedFulfillmentDate, id } }
  ) => {
    const order = state
      .get(id)
      .set('expectedFulfillmentDate', expectedFulfillmentDate)
    return state.set(id, order)
  },
  [OUT_OF_NETWORK_REASON_CHANGED]: (
    state,
    { payload: { outOfNetworkReason, id } }
  ) => {
    const order = state.get(id).set('outOfNetworkReason', outOfNetworkReason)
    return state.set(id, order)
  },
})

export const getOrderDetails = createSelector(
  state => getOrderKey(state).get(key),
  orderDetails => orderDetails
)

export const getOrderDetail = (state, id) => getOrderDetails(state).get(id)

export const getOrderDetailNotes = (state, id) => {
  const order = getOrderDetail(state, id)
  const note = order.get('note')
  return note
}

export const getSelectedOrderDetails = createSelector(
  [getOrderDetails, getSelectedOrder],
  (details, orderId) => details.get(parseInt(orderId))
)
// TODO: refactor to reselect
export const canEditDetail = (state, id) => {
  const userId = getUserId(state)
  const detail = getOrderDetail(state, id)
  // TODO: figure out how to fix the null id issue
  if (!detail) return false
  const permissionObject = `orders:${detail.currentlyAssignedToRole || 'NA'}`
  const assignedTo = detail.currentlyAssignedTo
  const hasPermission = getHasPermission(state, permissionObject, 'edit')
  return (assignedTo === userId || hasPermission) && detail.canEdit
}
// TODO: refactor these to reselect selectors
export const canApplyAction = (state, id) => {
  const userId = getUserId(state)
  const detail = getOrderDetail(state, id)
  if (!detail) return false
  const permissionObject = `orders:${detail.currentlyAssignedToRole}`
  const assignedTo = detail.currentlyAssignedTo
  const hasPermission = getHasPermission(state, permissionObject, 'edit')
  return assignedTo === userId || hasPermission
}

// API
export const fetchOrderDetail = id => {
  const request = AspireAPI.get(`v1/orders/${id}`)
  return request.then(parseOrderDetail)
}

export const saveOrder = (
  id,
  formData,
  note,
  vendorId,
  fulfillmentDate,
  expectedFulfillmentDate,
  outOfNetworkReason
) =>
  from(
    AspireAPI.post(`v1/orders/${id}/save`, {
      data: formData,
      note: note || '',
      vendorId,
      fulfillmentDate,
      expectedFulfillmentDate,
      outOfNetworkReason,
    })
  )

export const applyAction = (id, action, cancelReason, actionNote) => {
  return from(
    AspireAPI.post(`v1/orders/${id}/action/${action}`, {
      cancelReason: cancelReason || null,
      note: actionNote || null,
    })
  ).pipe(map(parseOrderDetail))
}

const parseOrderHistory = history => history.map(h => OrderHistory(h))

const parseOrderDetail = ({
  id,
  data = {},
  schema,
  uiSchema,
  orderHistory = [],
  ordersContext = [],
  vendor = {},
  ...rest
}) => {
  try {
    const calcData = calcFormData({
      formData: data,
      schema,
      context: ordersContext,
      tags: rest.tags,
    })
    const form = Form({
      schema,
      uiSchema,
      data: calcData,
      ordersContext,
      tags: rest.tags,
    })
    const history = parseOrderHistory(orderHistory)
    const vendorStruct = Vendor(vendor)
    return Promise.resolve(
      OrderDetail({ id, form, history, vendor: vendorStruct, ...rest })
    )
  } catch (error) {
    return Promise.reject(error)
  }
}
