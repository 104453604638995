import { combineReducers } from 'redux-immutable'
import assignementCareTeams from './data/assignment'
import {
  addCareTeamPod,
  careTeamDetailsById,
  currentCareTeamDetailId,
  deleteCareTeamPod,
} from './data/careTeam'
import { addEditCareTeamPods, careTeamPods } from './data/careTeamPods'
import {
  careTeamsReducer,
  patientCareTeamsReducer,
  rolesReducer,
  typesReducer,
} from './data/careTeams'
import currentCareTeamId from './data/currentCareTeamId'
import currentPatientId from './data/currentPatientId'
import key from './key'

const reducer = combineReducers({
  [assignementCareTeams.key]: assignementCareTeams,
  [careTeamsReducer.key]: careTeamsReducer,
  [rolesReducer.key]: rolesReducer,
  [typesReducer.key]: typesReducer,
  [currentCareTeamId.key]: currentCareTeamId,
  [currentPatientId.key]: currentPatientId,
  [patientCareTeamsReducer.key]: patientCareTeamsReducer,
  [careTeamPods.key]: careTeamPods,
  [addCareTeamPod.key]: addCareTeamPod,
  [deleteCareTeamPod.key]: deleteCareTeamPod,
  [currentCareTeamDetailId.key]: currentCareTeamDetailId,
  [addEditCareTeamPods.key]: addEditCareTeamPods,
  [careTeamDetailsById.key]: careTeamDetailsById,
})

reducer.key = key

export default reducer
