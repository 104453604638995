import moment from 'moment'
import momentTZ from 'moment-timezone'
import { DATE_PICKER_DATE_FORMAT } from './format'

// Get local and time zone offsets
const getOffsets = timeZone => {
  const dateTz = momentTZ().tz(timeZone)
  const offsetLocal = moment().utcOffset()
  const offsetTz = dateTz ? dateTz.utcOffset() : offsetLocal

  return { offsetLocal, offsetTz }
}

// Get a UTC zoned moment from a local moment given a time zone
export const localToUtc = (localDate, timeZone) => {
  const { offsetLocal, offsetTz } = getOffsets(timeZone)
  return moment(localDate)
    .utc()
    .add(offsetLocal - offsetTz, 'minutes')
}

// Get a local moment from a UTC zoned moment given a time zone
export const utcToLocal = (utcDate, timeZone) => {
  const { offsetLocal, offsetTz } = getOffsets(timeZone)
  return moment(utcDate)
    .local()
    .add(offsetTz - offsetLocal, 'minutes')
}

// Get a UTC zoned moment representing a date from a local moment
export const localToUtcDate = localDate => {
  const { years, months, date } = moment(localDate).local().toObject()
  return moment.utc({ years, months, date })
}

// Get a local moment representing a date from a UTC zoned moment
export const utcToLocalDate = utcDate => {
  const { years, months, date } = moment(utcDate).toObject()
  return moment({ years, months, date })
}

// Set the time of a moment from another moment's time
export const setTime = (date, time) => {
  const { hours, minutes, seconds } = moment(time).toObject()
  return moment(date).set({ hours, minutes, seconds })
}

// Add business dates to a moment
export const addBusinessDays = (date, days) => {
  const dayOfWeek = date.day()
  const daysToAdd =
    days +
    Math.trunc((days - 1) / 5) * 2 +
    (dayOfWeek + ((days - 1) % 5) >= 5 ? 2 : 0) +
    (dayOfWeek === 6 ? -1 : 0)

  return moment(date).add(daysToAdd, 'days')
}

export const sortRecordsByDate = (a, b, order = 'asc') => {
  const dateDiff = moment(a).diff(moment(b), 'days')
  return order === 'desc' ? -dateDiff : dateDiff
}

export const isDateAfterToday = date =>
  moment(date).format(DATE_PICKER_DATE_FORMAT) >
  moment().format(DATE_PICKER_DATE_FORMAT)

export const isPreviousYearThroughToday = date => {
  const lastYear = moment()
    .subtract(1, 'year')
    .startOf('year')
    .format(DATE_PICKER_DATE_FORMAT)

  return moment(date).isBetween(lastYear, moment(), null, '[]')
}
