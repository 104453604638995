import React, { useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import renderFieldValues from '~/components/fields/renderFieldValues'
import { Section } from '~/components/text'
import { VirtualCarePlanning, savePatientInfo } from '~/features/patientInfo'
import { useAction, useFieldValuesOld, useToggle } from '~/hooks'
import { Button, Input, Select, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getPatientInfo } from '../../../data/patientInfo'
import Reminder from './Reminder'

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  grid: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr',
    columnGap: spacing(2),
    rowGap: `${spacing(1)}px`,

    [breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto',
    },
  },
  nonEditableFields: {
    paddingTop: `${spacing(1)}px`,
  },
  row: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  submitButton: {
    display: 'none',
  },
  fieldName: {
    paddingRight: `${spacing(1)}px`,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '20%',
  },
}))

const getValues = patient => ({
  virtualCapable: patient.virtualCapable || '',
  videoDevicesAvailable: patient.videoDevicesAvailable || [],
  videoDevicesAvailableOther: patient.videoDevicesAvailableOther || '',
  preferredVideoApplications: patient.preferredVideoApplications || [],
  preferredVideoApplicationsOther:
    patient.preferredVideoApplicationsOther || '',
  internetAccessAvailable: patient.internetAccessAvailable || [],
  hasPreferredEmailOrPhone: patient.hasPreferredEmailOrPhone || '',
})

const Scheduling = () => {
  const classes = useStyles()

  const [edit, toggleEdit, toggleEditOff] = useToggle()
  const patientInfo = useSelector(getPatientInfo)
  const savePatientInfoRequested = useAction(savePatientInfo.requested)
  const internet = useFieldValuesOld('internet_access_available')
  const preferred = useFieldValuesOld('preferred_video_applications')
  const video = useFieldValuesOld('video_devices_available')
  const buttonRef = useRef()

  const { control, handleSubmit, watch, reset } = useForm({
    defaultValues: getValues(patientInfo),
  })

  useEffect(() => {
    if (patientInfo) {
      reset(getValues(patientInfo))
    }
  }, [patientInfo])

  const onSave = formData => {
    toggleEditOff()
    savePatientInfoRequested(patientInfo.id, {
      virtualCarePlanning: VirtualCarePlanning({
        ...formData,
        patientId: patientInfo.id,
      }),
    })
  }

  const formVideoDevicesAvailable = watch('videoDevicesAvailable')
  const formPreferredVideoApplications = watch('preferredVideoApplications')

  return (
    <React.Fragment>
      <Section title="Visit Instructions">
        {patientInfo.visitInstructions ? (
          <Typography>{patientInfo.visitInstructions}</Typography>
        ) : (
          <Typography variant="caption">No Visit Instructions</Typography>
        )}
      </Section>
      <Section title="Virtual Care Planning">
        <form onSubmit={handleSubmit(onSave)}>
          <div className={classes.grid}>
            <div className={classes.nonEditableFields}>
              <Typography variant="body1" className={classes.fieldName}>
                Virtual Capable: {patientInfo.virtualCapable ? 'Yes' : 'No'}
              </Typography>
            </div>
            <div className={classes.nonEditableFields}>
              <Typography variant="body1" className={classes.fieldName}>
                Has Preferred Email or Texting Approved Phone Number:{' '}
                {patientInfo.hasPreferredEmailOrPhone ? 'Yes' : 'No'}
              </Typography>
            </div>
            <div className={classes.row}>
              <Typography variant="body1" className={classes.fieldName}>
                Video-Enabled Devices Available:
              </Typography>
              <Controller
                control={control}
                label="Video-Enabled Devices Available"
                name="videoDevicesAvailable"
                render={({ value, onChange }) => (
                  <Select
                    multiple
                    value={value}
                    disabled={!edit}
                    onChange={e => onChange(e.target.value)}
                  >
                    {renderFieldValues(video)}
                  </Select>
                )}
              />
            </div>
            {formVideoDevicesAvailable?.includes('other') && (
              <div className={classes.row}>
                <Typography variant="body1" className={classes.fieldName}>
                  Other Video Enabled Device(s):
                </Typography>
                <Controller
                  as={TextField}
                  disabled={!edit}
                  control={control}
                  name="videoDevicesAvailableOther"
                />
              </div>
            )}
            <div className={classes.row}>
              <Typography variant="body1" className={classes.fieldName}>
                Internet Access Available:
              </Typography>
              <Controller
                control={control}
                label="Internet Access Available"
                name="internetAccessAvailable"
                render={({ value, onChange }) => (
                  <Select
                    multiple
                    value={value}
                    input={<Input />}
                    disabled={!edit}
                    onChange={e => onChange(e.target.value)}
                  >
                    {renderFieldValues(internet)}
                  </Select>
                )}
              />
            </div>
            <div className={classes.row}>
              <Typography variant="body1" className={classes.fieldName}>
                Preferred Video Applications:
              </Typography>
              <Controller
                control={control}
                label="Preferred Video Applications"
                name="preferredVideoApplications"
                render={({ value, onChange }) => (
                  <Select
                    multiple
                    value={value}
                    disabled={!edit}
                    onChange={e => onChange(e.target.value)}
                  >
                    {renderFieldValues(preferred)}
                  </Select>
                )}
              />
            </div>
            {formPreferredVideoApplications.includes('other') && (
              <div className={classes.row}>
                <Typography variant="body1" className={classes.fieldName}>
                  Other Preferred Video Application(s):
                </Typography>
                <Controller
                  as={TextField}
                  disabled={!edit}
                  control={control}
                  name="preferredVideoApplicationsOther"
                />
              </div>
            )}
            <br />
            <div className={classes.buttons}>
              <Button
                color="primary"
                variant="contained"
                onClick={toggleEdit}
                disabled={edit}
              >
                Edit
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!edit}
                onClick={() => buttonRef.current.click()}
              >
                Save
              </Button>
            </div>
            <button
              className={classes.submitButton}
              type="button"
              ref={e => {
                buttonRef.current = e
              }}
            />
          </div>
        </form>
      </Section>
      <Section title="Reminder">
        <Reminder patientId={patientInfo.id} />
      </Section>
    </React.Fragment>
  )
}

export default Scheduling
