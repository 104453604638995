import React from 'react'
import DevXGrid from '~/components/DevXGrid'
import { formatDate } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import { Paper } from '@material-ui/core'
import CoverageDetails from '../components/CoverageDetails'

class Coverages extends React.PureComponent {
  columns = [
    {
      name: 'primaryContract',
      title: 'Channel',
      wrapText: true,
    },
    {
      name: 'healthplan',
      title: 'Healthplan',
      wrapText: true,
    },
    {
      name: 'accountId',
      title: 'Account Number',
      wrapText: true,
    },
    {
      name: 'effectiveDate',
      title: 'Effective Date',
      getCellValue: ({ effectiveDate }) =>
        effectiveDate && formatDate(effectiveDate),
    },
    {
      name: 'priority',
      title: 'Priority',
    },
    {
      name: 'lastModifiedByUser',
      title: 'Last Modified By',
      getCellValue: ({ lastModifiedByUser }) =>
        lastModifiedByUser && lastModifiedByUser.name,
    },
  ]

  columnExtensions = [
    { columnName: 'primaryContract', width: 200 },
    { columnName: 'priority', width: 100 },
  ]

  defaultSorting = [{ columnName: 'priority', direction: 'asc' }]

  render() {
    const { coverages } = this.props

    return (
      <Paper>
        <DevXGrid
          rows={coverages}
          columns={this.columns}
          detailComponent={CoverageDetails}
          columnExtensions={this.columnExtensions}
          columnReorderable
          sortable
          defaultSorting={this.defaultSorting}
        />
      </Paper>
    )
  }
}

Coverages.propTypes = {
  coverages: PropTypes.array.isRequired,
}

export default Coverages
