import { Redirect, Route, Router, Scope } from 'redux-routable'
import key from './key'

export const CASE_MANAGER_DASHBOARD_HOME = `${key}.home`
export const CASE_MANAGER_DASHBOARD_CALENDAR = `${key}.calendar`
export const CASE_MANAGER_DASHBOARD_PATIENT_LIST = `${key}.patientList`
export const CASE_MANAGER_DASHBOARD_HIGH_RISK_HUDDLE = `${key}.highRiskHuddle`
export const CASE_MANAGER_DASHBOARD_EPISODES_OF_CARE = `${key}.episodesOfCare`
export const CASE_MANAGER_DASHBOARD_MY_LISTS = `${key}.myLists`
export const CASE_MANAGER_DASHBOARD_TASKS_BY_USER = `${key}.tasksByUser`
export const CASE_MANAGER_DASHBOARD_TASK_DETAIL = `${key}.taskDetail`
export const CASE_MANAGER_DASHBOARD_INPATIENT_CENSUS = `${key}.inpatientCensus`
export const CASE_MANAGER_DASHBOARD_DISCHARGE_CENSUS = `${key}.dischargeCensus`
export const CASE_MANAGER_DASHBOARD_SNF_CENSUS = `${key}.snfCensus`
export const CASE_MANAGER_DASHBOARD_ER_CENSUS = `${key}.erCensus`
export const CASE_MANAGER_DASHBOARD_INTERVENTIONS = `${key}.interventions`

export const tasksRouter = Router([
  Route(CASE_MANAGER_DASHBOARD_TASKS_BY_USER),
  Route(CASE_MANAGER_DASHBOARD_TASK_DETAIL, '/:taskId'),
])

export const censusRouter = Router([
  Route(CASE_MANAGER_DASHBOARD_INPATIENT_CENSUS, '/inpatient'),
  Route(CASE_MANAGER_DASHBOARD_DISCHARGE_CENSUS, '/discharge'),
  Route(CASE_MANAGER_DASHBOARD_SNF_CENSUS, '/snf'),
  Route(CASE_MANAGER_DASHBOARD_ER_CENSUS, '/er'),
  Redirect(CASE_MANAGER_DASHBOARD_INPATIENT_CENSUS),
])

export const patientListRouter = Router([
  Route(CASE_MANAGER_DASHBOARD_PATIENT_LIST, '/patient-list'),
  Route(CASE_MANAGER_DASHBOARD_HIGH_RISK_HUDDLE, '/high-risk-huddle'),
  Route(CASE_MANAGER_DASHBOARD_MY_LISTS, '/my-lists'),
  Redirect(CASE_MANAGER_DASHBOARD_PATIENT_LIST),
])

export default Router([
  Scope('/:cmId?/tasks', tasksRouter),
  Scope('/:cmId?/census', censusRouter),
  Scope('/:cmId?/patient', patientListRouter),
  Route(CASE_MANAGER_DASHBOARD_HOME, '/:cmId?/home'),
  Route(CASE_MANAGER_DASHBOARD_CALENDAR, '/:cmId?/calendar'),
  Route(CASE_MANAGER_DASHBOARD_EPISODES_OF_CARE, '/:cmId?/episodes-of-care'),
  Route(CASE_MANAGER_DASHBOARD_INTERVENTIONS, '/:cmId?/interventions'),
  Redirect(CASE_MANAGER_DASHBOARD_HOME),
])
