import React from 'react'
import DevXGrid from '~/components/DevXGrid'
import DisplayDateTime from '~/components/DisplayDateTime'
import { useAction } from '~/hooks'
import PropTypes from '~/utils/propTypes'
import { removeUnderscores, titleCase } from '~/utils/stringManipulation'
import { Call, fetchCall } from '../data/calls'
import HistoricalCallForm from './HistoricalCallForm'

const CallGrid = ({ calls }) => {
  const onToggle = useAction(fetchCall.requested)

  return (
    <DevXGrid
      rows={calls}
      columns={columns}
      defaultSorting={[{ columnName: 'completedAt', direction: 'desc' }]}
      detailComponent={({ row }) => <HistoricalCallForm form={row.form} />}
      pageable
      sortable
      filterable
      hideColumnsWhenGrouped
      defaultPageSize={15}
      pageSizes={[15, 25, 50]}
      indentColumnWidth={18}
      onToggle={({ id }) => onToggle(id)}
    />
  )
}

const columns = [
  {
    name: 'type',
    title: 'Call Type',
    getCellValue: row =>
      row.type === 'cmrn_call'
        ? 'CMRN Call'
        : titleCase(removeUnderscores(row.type)),
  },
  {
    name: 'completedAt',
    title: 'Completed At',
    displayComponent: DisplayDateTime,
  },
  {
    name: 'user',
    title: 'Completed By',
    displayComponent: ({ row: { user } }) => user.name,
  },
]

CallGrid.propTypes = {
  calls: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.instanceOf(Call)]))
    .isRequired,
}

export default CallGrid
