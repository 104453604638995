import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  Method,
  AxiosResponse,
  AxiosError,
} from 'axios'
import UnauthenticatedRequestError from '~/errors/UnauthenticatedRequestError'
import {
  allowOverride,
  getId as getUserOverrideId,
  header as userOverrideHeader,
} from '~/features/userOverride'
import keycloak from '~/utils/security/keycloak'
// @ts-expect-error not yet addressed
import telemetry from '~/utils/telemetry'
import { KeycloakProvider } from '~/utils/security/keycloak/provider'

type Opts = AxiosRequestConfig & { body?: any }

class Resource {
  _baseURL: string
  _resource: AxiosInstance
  interceptors: AxiosInstance['interceptors']

  constructor(baseURL: string) {
    this._baseURL = /\/$/.test(baseURL) ? baseURL : baseURL + '/'
    this._resource = axios.create({
      baseURL: this._baseURL,
    })
    this._resource.interceptors.request.use(this._injectHeaders)
    this._resource.interceptors.response.use(
      this._transformResponse,
      this._logError
    )

    this.interceptors = this._resource.interceptors
  }

  /**
   * Injects standard headers into a request, including the user's id and
   * authorization token.
   *
   * @private
   */
  _injectHeaders = (
    requestConfig: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> =>
    Promise.all([keycloak.user, keycloak.token, getUserOverrideId()]).then(
      ([user, token, userOverrideId]) => {
        requestConfig.headers['userId'] = user ? user.id : ''
        requestConfig.headers['Authorization'] = `Bearer ${token}`
        if (allowOverride && userOverrideId) {
          requestConfig.headers[userOverrideHeader] = userOverrideId
        }

        return requestConfig
      }
    )

  /**
   * Checks whether the current authorization token is still valid.
   *
   * @private
   */
  _validateToken(): ReturnType<KeycloakProvider['refreshToken']> {
    return keycloak.refreshToken()
  }

  /**
   * The central method through which all requests are dispatched. Ensures that
   * the session is stil valid before initiating network requests.
   *
   * @private
   * @param {object} [opts] - optional request configuration
   * @returns {Promise} the result of the network request
   */
  _request(method: Method, uri: string, opts?: Opts) {
    return this._validateToken()
      .catch(() => {
        throw new UnauthenticatedRequestError(
          'Attempted to make an unauthenticated request'
        )
      })
      .then(() => {
        switch (method) {
          case 'get':
          case 'delete':
            return this._resource[method](uri, opts)
          case 'patch':
          case 'post':
          case 'put':
            return this._resource[method](uri, opts?.body, opts)
          default:
            throw new Error(`Unknown HTTP method: "${method}"`)
        }
      })
  }

  /**
   * Extracts the response body from the axios response wrapper so that it is
   * consistent with how existing applications consume responses.
   *
   * @private
   */
  _transformResponse(res: AxiosResponse) {
    return res.data
  }

  /**
   * Logs error.
   *
   * @private
   */
  _logError(error: AxiosError) {
    telemetry.error(error)
    return Promise.reject(error)
  }

  get(uri: string, opts?: Opts) {
    return this._request('get', uri, opts)
  }

  post(uri: string, body?: Record<string, unknown>, opts?: Opts) {
    return this._request('post', uri, { ...opts, body })
  }

  put(uri: string, body?: Record<string, unknown>, opts?: Opts) {
    return this._request('put', uri, { ...opts, body })
  }

  patch(uri: string, body?: Record<string, unknown>, opts?: Opts) {
    return this._request('patch', uri, { ...opts, body })
  }

  delete(uri: string, opts?: Opts) {
    return this._request('delete', uri, opts)
  }
}

export const createResource = (baseURL: string) => new Resource(baseURL)
