/* eslint-disable no-console */
import { Map } from 'immutable'

/**
 * Responsible for all application logging, timing, error monitoring, etc.
 * Persists contextual information such as the current user and build information,
 * and can attach it to events forwarded to external services.
 */
export class Telemetry {
  verbose = false

  constructor(context = new Map()) {
    this.context = context
  }

  setContext(key, value) {
    this.context = this.context.set(key, value)
  }

  reportContext() {
    if (this.verbose) {
      console.groupCollapsed('Application context')
      console.table(
        [...this.context.entries()].reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: { value },
          }),
          {}
        )
      )
      console.groupEnd()
    }
  }

  _report(level, message, ctx) {
    if (this.verbose) {
      ctx ? console[level](message, ctx) : console[level](message)
    }
  }

  info(message, ctx) {
    this._report('info', message, ctx)
  }

  warn(message, ctx) {
    this._report('warn', message, ctx)
  }

  error(error, ctx) {
    this._report('error', error, ctx)
  }
}

export default new Telemetry(new Map(Object.entries(__DEBUG_INFO__)))
