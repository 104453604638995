import { List, Record, fromJS } from 'immutable'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { creator, get, type } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../key'
import { getRoot } from './common/shared'

// RECORDS
export const Action = Record({
  name: null,
  description: null,
})

// KEY
const key = 'providers'

// ACTIONS
export const FETCHED_PROVIDERS = type(rootKey, 'FETCHED_PROVIDER')
export const REQUEST_FETCH_PROVIDERS = type(rootKey, 'REQUEST_FETCH_PROVIDER')

// ACTION CREATORS
export const fetchedProviders = creator(FETCHED_PROVIDERS, 'providers')
export const requestProviders = creator(REQUEST_FETCH_PROVIDERS, 'payload')

// REDUCER
const initState = List()

export default createReducer(key, initState, {
  // @ts-expect-error ts-migrate(6133) FIXME: 'state' is declared but its value is never read.
  [FETCHED_PROVIDERS]: (state: any, { payload }: any) =>
    fromJS(payload.providers || []),
})

// SELECTORS
export const getProviders = pipe(getRoot, get(key))

// ASYNC
export const fetchPatientProviders = (user_id: any) =>
  AspireAPI.get('dashboard/cd_provider_list', { params: { user_id } })
