import { List } from 'immutable'
import { transformCareTeam } from '~/features/careTeamManagement'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import { type } from '~/utils/data'
import {
  Contact,
  Disposition,
  PreferredPharmacy,
  PrimaryCarePhysician,
  Stakeholder,
  Vendor,
  typePrefixCreator,
} from './common/shared'

const CONFIG = 'config'
const typePrefix = typePrefixCreator(CONFIG)

const formatPhone = number =>
  number.replace(/(\w{3})(\w{3})(\w{4})/, '($1) $2-$3')

const transformConfig = ({
  contacts,
  pcp,
  stakeholders,
  vendors,
  patientDispositions,
  stakeholderDispositions,
  careTeam,
  extensivists,
  preferredPharmacy,
}) => {
  return {
    contacts: List(
      contacts.map(c => Contact({ ...c, phone: formatPhone(c.phone) }))
    ),
    pcp: List(pcp.map(PrimaryCarePhysician)),
    stakeholders: List(stakeholders.map(Stakeholder)),
    vendors: List(vendors.map(Vendor)),
    patientDispositions: List(patientDispositions.map(Disposition)),
    stakeholderDispositions: List(stakeholderDispositions.map(Disposition)),
    careTeam: transformCareTeam(careTeam),
    extensivists: extensivists,
    preferredPharmacy: List(
      preferredPharmacy.map(preffPharmacy =>
        PreferredPharmacy({
          ...preffPharmacy,
          phone: formatPhone(preffPharmacy.phone),
        })
      )
    ),
  }
}

export const fetchConfig = Request({
  typePrefix,
  typeBase: 'FETCH_CONFIG',
  type: type('FETCH_CONFIG'),
  requestParams: ['patientId'],
  operation: patientId =>
    AspireAPI.get(`call_logs/patient/${patientId}/quick_call_log/config`),
  transform: transformConfig,
  messages: {
    failed: 'There was an issue fetching call logger configuration data',
  },
})
