import { Map, OrderedMap, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import patientRecordKey from '../key'
import { getVitals } from './root'
const key = 'vitals'

export const getPatientVitals = get(key)

export const Vitals = Record({
  associatedActivitySob: null,
  bmi: null,
  dateOfService: null,
  diastolic: null,
  id: null,
  midArmCircumference: null,
  ppsScore: null,
  pulse: null,
  pulseOximetryScore: null,
  severityCurrentPain: null,
  systolic: null,
  weight: null,
  height: null,
  respiratoryRate: null,
})

export const transform = into(Vitals, 'id', OrderedMap)

/* FETCH PATIENT VITALS */
export const fetchVitals = Request({
  typePrefix: patientRecordKey,
  typeBase: 'FETCH_VITALS',
  requestParams: ['patientId', 'count'],
  operation: (patientId, count) =>
    AspireAPI.get(`patient_record/${patientId}/vitals`, {
      params: { count },
    }),
  transform,
  messages: { failed: 'There was a problem fetching patient vitals' },
})

export default createReducer(key, Map(), {
  [fetchVitals.SUCCEEDED]: (_state, { payload }) => payload,
})

export const getPatientVitalsMap = pipe(getVitals, get(key))
