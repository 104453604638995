import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import DevXTitleHeader from '~/components/DevXTitleHeader'
import InvisibleDivider from '~/components/InvisibleDivider'
import { getIsCaremoreBrand, getPatientId } from '~/features/patientInfo'
import PropTypes from '~/utils/propTypes'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Coverages from '../components/Coverages'
import { coverageSelectedForCreation } from '../data/dialogs'
import { getInsuranceCoveragesArray } from '../data/insuranceCoverages'
import { getValidationCheckResult } from '../data/validation'
import CoverageCreationDialog from './CoverageCreationDialog'
import CoverageEditDialog from './CoverageEditDialog'

const styles = ({ palette, spacing }) => ({
  errorText: {
    backgroundColor: palette.severity.error.static,
    borderRadius: 4,
    color: palette.common.white,
    marginBottom: spacing(3),
    marginTop: spacing(3),
    padding: [spacing(1), spacing(1.5)],
    textAlign: 'center',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: spacing(2),
    marginTop: spacing(3),
  },
})

const PatientInsurance = props => {
  const {
    classes,
    insuranceCoverages,
    coverageSelectedForCreation,
    patientId,
    valid,
    isCaremoreBrand,
  } = props

  const activeCoverage = coverages => coverages.filter(v => v.priority === 1)

  return (
    <React.Fragment>
      {!valid && (
        <Typography className={classes.errorText} variant="body1">
          The patient&apos;s current insurance coverage does not match common
          patterns. Please review the effectivity dates of each insurance
          coverage.
        </Typography>
      )}
      {valid && <InvisibleDivider />}
      <DevXTitleHeader
        action={coverageSelectedForCreation}
        buttonText={isCaremoreBrand ? '' : 'Create New Coverage'}
        title="Coverages"
      />
      <Coverages coverages={insuranceCoverages} />
      {!isCaremoreBrand && (
        <React.Fragment>
          <CoverageCreationDialog
            patientId={patientId}
            activeCoverage={activeCoverage(insuranceCoverages)}
          />
          <CoverageEditDialog />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

PatientInsurance.propTypes = {
  classes: PropTypes.object.isRequired,
  insuranceCoverages: PropTypes.array.isRequired,
  coverageSelectedForCreation: PropTypes.func,
  patientId: PropTypes.string,
  valid: PropTypes.bool,
  isCaremoreBrand: PropTypes.bool,
}

const mapStateToProps = state => ({
  insuranceCoverages: getInsuranceCoveragesArray(state),
  valid: getValidationCheckResult(state),
  patientId: getPatientId(state),
  isCaremoreBrand: getIsCaremoreBrand(state),
})

const mapDispatchToProps = {
  coverageSelectedForCreation: () => coverageSelectedForCreation(true),
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(PatientInsurance)
