import { Redirect, Route, Router } from 'redux-routable'
import key from './key'

export const NURSE_DASHBOARD_CALENDAR = `${key}.calendar`
export const NURSE_DASHBOARD_EPISODES_OF_CARE = `${key}.episodes_of_care`
export const NURSE_DASHBOARD_HOME = `${key}.home`
export const NURSE_DASHBOARD_TASKS_BY_USER = `${key}.tasksByUser`
export const NURSE_DASHBOARD_TASK_DETAIL = `${key}.taskDetail`
export const NURSE_DASHBOARD_APP_SCHEDULING_LIST = `${key}.appSchedulingList`
export const NURSE_DASHBOARD_CENSUS = `${key}.census`
export const NURSE_DASHBOARD_HIGH_RISK_HUDDLE = `${key}.highRiskHuddle`
export const NURSE_DASHBOARD_MY_LISTS = `${key}.myLists`
export const NURSE_DASHBOARD_ORDERS_INDEX = `${key}.ordersIndex`
export const NURSE_DASHBOARD_ORDER_DETAIL = `${key}.orderDetail`
export const NURSE_DASHBOARD_USER_TIMELINE = `${key}.userTimeline`

export const NURSE_DASHBOARD_TASKS_ROUTES = [
  NURSE_DASHBOARD_TASKS_BY_USER,
  NURSE_DASHBOARD_TASK_DETAIL,
]

export const NURSE_DASHBOARD_ORDERS_ROUTES = [
  NURSE_DASHBOARD_ORDERS_INDEX,
  NURSE_DASHBOARD_ORDER_DETAIL,
]

export default Router([
  Route(NURSE_DASHBOARD_HOME, '/:appId?/home'),
  Route(NURSE_DASHBOARD_CALENDAR, '/:userId?/calendar'),
  Route(NURSE_DASHBOARD_EPISODES_OF_CARE, '/:userId?/episodes-of-care'),
  Route(NURSE_DASHBOARD_ORDERS_INDEX, '/:userId?/orders'),
  Route(NURSE_DASHBOARD_ORDER_DETAIL, '/:userId?/orders/:orderId'),
  Route(NURSE_DASHBOARD_TASKS_BY_USER, '/:userId?/tasks'),
  Route(NURSE_DASHBOARD_TASK_DETAIL, '/:userId?/tasks/:taskId'),
  Route(NURSE_DASHBOARD_APP_SCHEDULING_LIST, '/:userId?/app-list'),
  Route(NURSE_DASHBOARD_CENSUS, '/:userId?/census'),
  Route(NURSE_DASHBOARD_HIGH_RISK_HUDDLE, '/:userId?/high-risk-huddle'),
  Route(NURSE_DASHBOARD_MY_LISTS, '/:userId?/my-lists'),
  Route(NURSE_DASHBOARD_USER_TIMELINE, '/:userId?/user-timeline'),
  Redirect(NURSE_DASHBOARD_HOME),
])
