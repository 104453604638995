import React from 'react'
import DevXTitleHeader from '~/components/DevXTitleHeader'
import JsonForm from '~/components/JsonForm'
import PropTypes from '~/utils/propTypes'
import { removeUnderscores, titleCase } from '~/utils/stringManipulation'
import {
  Button,
  Icon,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Call } from '../data/calls'
import { Form, getFormDataByTag } from '../data/form'
import CallGrid from './CallGrid'
import * as fields from './fields'

const styles = ({ spacing }) => ({
  button: {
    marginLeft: spacing(1),
  },
  formPaper: {
    marginBottom: spacing(2),
    padding: spacing(2),
  },
  gridPaper: {
    padding: spacing(2),
  },
  icon: { marginRight: spacing(1) },
  panelButton: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

class Calls extends React.PureComponent {
  onCancel = () => this.props.formCancelled()

  onChange = ({ errors, formData }) =>
    this.props.formUpdated(formData, Boolean(errors.length))

  onError = errors => this.props.formErrored(Boolean(errors.length))

  onSubmit = ({ formData }) =>
    this.props.formCompletionRequested(
      {
        data: formData,
        context: this.props.form.context,
        type: this.props.form.type,
      },
      this.props.patientId
    )

  displayTitle = () =>
    this.props.form.type === 'cmrn_call'
      ? 'CMRN Call'
      : titleCase(removeUnderscores(this.props.form.type))

  render() {
    const { classes, form, onPanelOpen, submitting, calls } = this.props

    return (
      <React.Fragment>
        <DevXTitleHeader title="Call Events">
          <Tooltip title="Open Panel" placement="bottom">
            <IconButton onClick={onPanelOpen}>
              <Icon>chrome_reader_mode</Icon>
            </IconButton>
          </Tooltip>
        </DevXTitleHeader>
        {form && (
          <Paper className={classes.formPaper}>
            <Typography variant="h5">New {this.displayTitle()} Form</Typography>
            <JsonForm
              debounce
              context={form.context}
              formData={form.formData}
              getFormDataByTag={getFormDataByTag}
              onChange={this.onChange}
              onError={this.onError}
              onSubmit={this.onSubmit}
              fields={fields}
              schema={form.schema}
              uiSchema={{
                ...form.uiSchema,
                'ui:disabled': submitting,
              }}
            >
              {form.errored && (
                <Typography color="error" variant="body1">
                  Errors exist on this form.
                </Typography>
              )}
              <Button
                className={classes.button}
                onClick={this.onCancel}
                variant="outlined"
              >
                <Icon className={classes.icon}>cancel</Icon> Cancel
              </Button>
              <Button
                className={classes.button}
                color="primary"
                disabled={form.errored || submitting}
                type="submit"
                variant="contained"
              >
                <Icon className={classes.icon}>done</Icon>
                Complete
              </Button>
            </JsonForm>
          </Paper>
        )}
        <Paper className={classes.gridPaper}>
          <CallGrid calls={calls} />
        </Paper>
      </React.Fragment>
    )
  }
}

Calls.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.instanceOf(Form),
  formCancelled: PropTypes.func.isRequired,
  formErrored: PropTypes.func.isRequired,
  formCompletionRequested: PropTypes.func.isRequired,
  formUpdated: PropTypes.func.isRequired,
  onPanelOpen: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  calls: PropTypes.arrayOf(Call).isRequired,
}

export default withStyles(styles)(Calls)
