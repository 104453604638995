// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/da... Remove this comment to see the full error message
import { getUserId } from '~/data/session'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '~/ut... Remove this comment to see the full error message
import createReducer from '~/utils/createReducer'
import { type } from '~/utils/data'
import rootKey from '../key'
import { getRoot } from './common/shared'

// KEY
const key = 'communityHWDashUserId'

// ACTIONS
export const DASHBOARD_REFRESH_REQUESTED = type(
  rootKey,
  'DASHBOARD_REFRESH_REQUESTED'
)

// ACTION CREATORS
export const communityHWDashRefreshRequested = (userId: any) => ({
  type: DASHBOARD_REFRESH_REQUESTED,
  payload: { userId },
})

// REDUCER
export default createReducer(key, null, {
  // @ts-expect-error ts-migrate(6133) FIXME: 'state' is declared but its value is never read.
  [DASHBOARD_REFRESH_REQUESTED]: (state: any, { payload: { userId } }: any) =>
    userId,
})

// SELECTORS
export const getSelectedUserId = (state: any) =>
  // @ts-expect-error Object is unknown
  getRoot(state).get(key) || getUserId(state)
