import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import fuzzySort from 'fuzzysort'
import { isEqual } from 'lodash'
import React from 'react'
import Autosuggest from 'react-autosuggest'
import PropTypes from '~/utils/propTypes'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import zIndex from '@material-ui/core/styles/zIndex'

const styles = ({ palette, spacing }) => ({
  container: {
    position: 'relative',
    flex: 1,
    width: '100%',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: spacing(1),
    marginBottom: spacing(3),
    left: 0,
    right: 0,
    zIndex: zIndex.modal,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  textField: {
    width: '100%',
  },
  warningText: {
    color: 'red',
  },
  highlightText: {
    color: palette.severity.error.dynamic,
    fontWeight: 300,
  },
})

const defaultFilterFunction = val => suggestion => {
  const parsedVal = val.toString().toLowerCase()
  const parsedSuggestion = (suggestion.label || suggestion).toLowerCase()
  return parsedSuggestion.includes(parsedVal)
}

const fuzzyFilter = val => suggestion => {
  const parsedVal = val.toLowerCase()
  const parsedSuggestion = (suggestion.label || suggestion).toLowerCase()

  const result = fuzzySort.single(parsedVal, parsedSuggestion)

  return result && !(result.score === null)
}

const isNumber = x => typeof x == 'number'
const isString = x => typeof x == 'string'

const defaultSortFunction = () => (a, b) => {
  if ((isNumber(a) && isNumber(b)) || (isString(a) && isString(b))) {
    if (a > b) {
      return -1
    } else if (a === b) {
      return 0
    } else {
      return 1
    }
  }
}

export const fuzzyRank = value => (a, b) => {
  const parsedA = (a.label || a).toLowerCase()
  const parsedB = (b.label || b).toLowerCase()
  const parsedValue = value.toLowerCase()

  const aScore = fuzzySort.single(parsedValue, parsedA).score
  const bScore = fuzzySort.single(parsedValue, parsedB).score

  return defaultSortFunction(value)(aScore, bScore)
}

class AutoComplete extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      input: props.value,
      suggestions: [],
    }
  }

  componentDidMount() {
    this.props.value && this.setState({ input: this.props.value })
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({ input: newProps.value })
    if (
      !isEqual(this.props.availableSuggestions, newProps.availableSuggestions)
    ) {
      this.setState(({ input }) => ({
        suggestions: this.getSuggestions(input, newProps.availableSuggestions),
      }))
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(this.props.availableSuggestions, prevProps.availableSuggestions)
    ) {
      // Ensures suggestions loaded asynchronously still pick up the value of suggestion's label/value pairs
      this.checkForSuggestionValue(this.state.input)
    }
  }

  renderSuggestionsContainer = ({ containerProps, children }) => (
    <Paper {...containerProps} style={{ position: 'inherit' }} square>
      {children}
    </Paper>
  )

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const suggestionText =
      suggestion[this.props.labelKey] || suggestion.label || suggestion
    const matches = match(suggestionText, query)
    const parts = parse(suggestionText, matches)

    return (
      <MenuItem
        data-track-content
        data-content-name="Autocomplete"
        dense
        selected={isHighlighted}
        component="div"
      >
        <div>
          {parts.map((part, index) =>
            part.highlight ? (
              <span
                className={this.props.classes.highlightText}
                data-content-piece={part.text}
                key={index}
              >
                {part.text}
              </span>
            ) : (
              <strong
                data-content-piece={part.text}
                key={index}
                style={{ fontWeight: 500 }}
              >
                {part.text}
              </strong>
            )
          )}
        </div>
      </MenuItem>
    )
  }

  renderInput = inputProps => {
    const {
      classes,
      autoFocus,
      value,
      ref,
      helperText,
      inputLabel,
      error,
      required,
      ...other
    } = inputProps

    return (
      <TextField
        autoFocus={autoFocus}
        className={classes.textField}
        label={inputLabel}
        required={required}
        value={value}
        inputRef={ref}
        helperText={helperText || null}
        error={error || false}
        FormHelperTextProps={{ className: classes.warningText }}
        InputProps={{
          classes: {
            input: classes.input,
          },
          ...other,
        }}
      />
    )
  }

  getSuggestionValue = suggestion =>
    suggestion[this.props.labelKey] ||
    suggestion[this.props.valueKey] ||
    suggestion.label ||
    suggestion

  checkForSuggestionValue = input => {
    // THIS Is to make sure that whether a user actually clicks the suggestion or not we detect an alternate 'value' and save as directed.
    // Use case: name, id combinations where the id should be persisted to state
    const { labelKey, valueKey } = this.props
    if (!input && this.state.suggestions && this.props.onValueSelected) {
      this.props.onValueSelected(null, input)
    }
    if (!!input && this.state.suggestions && this.props.onValueSelected) {
      let value = null
      this.state.suggestions.forEach(x => {
        if (
          (x[valueKey] || x.value) &&
          (x[labelKey] === input || x.label === input || x === input)
        ) {
          value = x.value
        }
      })
      value && this.props.onValueSelected(value, input)
    }
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    const { availableSuggestions } = this.props
    this.setState({
      suggestions: this.getSuggestions(value, availableSuggestions),
    })
  }

  getSuggestions(value, availableSuggestions = []) {
    const { filterFunc, sortFunc, fuzzy } = this.props
    let filterFunction = filterFunc || defaultFilterFunction
    let sortFunction = sortFunc || defaultSortFunction

    if (fuzzy) {
      filterFunction = fuzzyFilter
      sortFunction = fuzzyRank
    }
    const results = availableSuggestions.filter(filterFunction(value))
    return results.sort(sortFunction(value)).slice(0, 10)
  }

  handleSuggestionsClearRequested = () => this.setState({ suggestions: [] })

  updateInput = (event, selection) => {
    // need to ignore up and down arrows to avoid losing current suggestions
    if (event.key == 'ArrowUp' || event.key == 'ArrowDown') {
      return null
    } else {
      const { newValue } = selection
      this.setState({ input: newValue })
      this.props.onChange && this.props.onChange(newValue) // onChange is called with the label
      this.props.requestSuggestions && this.props.requestSuggestions(newValue) // requests for additional async suggestions are called with the visible text
      this.checkForSuggestionValue(newValue)
    } // checks if the suggestion has a value property distinct from the 'label' property and calls onValueSelected
  }

  render() {
    const {
      classes,
      label,
      inputLabel,
      disabled,
      helperText,
      error,
      required = false,
    } = this.props

    return (
      <Autosuggest
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderInputComponent={this.renderInput}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={{
          classes,
          disabled: disabled,
          value: this.state.input,
          inputLabel: inputLabel || label,
          onChange: this.updateInput,
          helperText: helperText,
          error: error,
          required: required,
        }}
      />
    )
  }
}

AutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  onValueSelected: PropTypes.func,
  availableSuggestions: PropTypes.array,
  filterFunc: PropTypes.func,
  sortFunc: PropTypes.func,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  fuzzy: PropTypes.bool,
  required: PropTypes.bool,
  inputLabel: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  sheet: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  requestSuggestions: PropTypes.func,
}

export default withStyles(styles)(AutoComplete)
