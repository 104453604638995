import React, { PureComponent } from 'react'
import { PATIENT_RECORD_ATTACHMENTS } from '~/apps/patientRecord/router'
import DevXGrid from '~/components/DevXGrid'
import PatientLink from '~/components/PatientLink'
import { OpenLink } from '~/components/links'
import { formatDate } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import { removeUnderscores, titleCase } from '~/utils/stringManipulation'
import { Button, Icon, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import OrderDetail from '../containers/OrderDetail'

const styles = {
  buttonWrapper: { display: 'inline-block' },
  allowTextWrap: { whiteSpace: 'normal' },
}

// Ideally this list could be pulled from the server, but it shouldn't change much. - Allen
// #shipIt
const substatuses = [
  'new',
  'app_declined',
  'nss_declined',
  'declined',
  'fax_failed',
  'hospice_nss_declined',
  'hospice_revoked',
  'md_declined',
  'needs_approval_via_matrix',
  'needs_approval_app',
  'needs_review_hospice_rn',
  'needs_review_nss',
  'needs_approval_md',
  'other',
  'patient_declined',
  'pending_automated_vendor_fax',
  'submitted_to_vendor',
  'delivery_date_confirmed',
  'notified_patient',
  'success',
  'vendor_declined',
  'vendor_received',
  'change_vendor',
]

const expandedSubstatuses = substatuses.map(sub =>
  titleCase(removeUnderscores(sub))
)

const settings = {
  defaultPageSize: 20,
  pageSizes: [10, 20, 50],
  defaultExpandedGroups: expandedSubstatuses,
  defaultGrouping: [{ columnName: 'substatus' }],
  pageable: true,
  groupable: true,
  filterable: true,
  sortable: true,
  refreshable: true,
  columnHideable: true,
  columnReorderable: true,
  localSettingsKey: 'newOrdersTableView',
}

class OrderTable extends PureComponent {
  patientColumns = [
    {
      name: 'patientFullName',
      title: 'Patient',
      groupByComponent: ({ value, collapsedRowCount }) =>
        collapsedRowCount > 0 ? `${value} (${collapsedRowCount})` : value,
      displayComponent: ({ row: { patientId, patientFullName } }) => (
        <PatientLink patientId={patientId}>{patientFullName}</PatientLink>
      ),
    },
    {
      name: 'patientDob',
      title: 'Patient DOB',
      getCellValue: ({ patientDob }) => formatDate(patientDob),
    },
    {
      name: 'patientContactId',
      title: 'Contact ID',
    },
    { name: 'program', title: 'Program' },
  ]

  defaultColumns = [
    { name: 'appName', title: 'APP', wrapText: true },
    {
      name: 'status',
      title: 'Status',
      getCellValue: row => titleCase(row.status),
      wrapText: true,
    },
    {
      name: 'substatus',
      title: 'Substatus',
      getCellValue: row => titleCase(removeUnderscores(row.substatus || 'New')),
      wrapText: true,
    },
    { name: 'description', title: 'Subtype', wrapText: true },
    { name: 'vendorName', title: 'Vendor', wrapText: true },
    {
      name: 'note',
      title: 'Internal Note',
      getCellValue: row =>
        row.note && row.note.length > 120
          ? row.note.slice(0, 120) + '...'
          : row.note,
      wrapText: true,
    },

    {
      name: 'expectedFulfillmentDate',
      title: 'Next Action Date',
      getCellValue: row =>
        formatDate(row.expectedFulfillmentDate || row.dueDate) || '',
    },
    {
      name: 'CreatedAt',
      title: 'Created Date',
      getCellValue: row => (row.createdAt ? formatDate(row.createdAt) : ''),
    },
  ]

  state = {
    key: 0, // Key change forces a full re-render on refresh
    expandedRowIds: [],
  }
  refresh = () => {
    this.setState(({ key }) => ({
      key: key + 1, // Force a re-render
    }))
    if (this.props.ordersContext == 'patient') {
      this.props.refresh(null, this.props.patientId)
    } else {
      this.props.refresh(this.props.userId)
    }
  }

  userRowActions = ({ row }) => {
    const isLegacy = row.substatus === 'Legacy'

    const contextParam =
      this.props.ordersContext == 'patient'
        ? { patientId: row.patientId }
        : { userId: this.props.userId }

    return [
      <Tooltip
        title={isLegacy ? 'Go To Attachments' : 'Open Full View'}
        key="orderView"
      >
        <Button
          onClick={() => {
            !isLegacy &&
              this.props.onPanelOpen &&
              row.patientId &&
              this.props.onPanelOpen(row.patientId)
          }}
          label=""
          params={{ ...contextParam, orderId: row.id }}
          variant="text"
          color="primary"
          component={OpenLink}
          route={isLegacy ? PATIENT_RECORD_ATTACHMENTS : this.props.route}
          icon="open_in_new"
        >
          <Icon>open_in_new</Icon>
        </Button>
      </Tooltip>,
      <Tooltip title="Preview PDF" key="orderPdf">
        {/* Why the div? "A disabled element does not fire events. Tooltip needs to listen to the child element's events to display the title." */}
        <div className={this.props.classes.buttonWrapper}>
          <Button
            onClick={() => {
              this.props.previewOrderPdf(row.id)
            }}
            label=""
            variant="text"
            color="primary"
            icon="file_copy"
            disabled={['closed', 'cancelled'].includes(row.status) || isLegacy}
          >
            <Icon>file_copy</Icon>
          </Button>
        </div>
      </Tooltip>,
    ]
  }

  onToggle = (row, expanded, tableRowId) => {
    this.props.onPanelOpen(row.patientId)
    if (!expanded) {
      this.props.selectOrder(row.id)
      this.setState({ expandedRowIds: [tableRowId] })
    } else {
      this.setState({ expandedRowIds: [] })
    }
  }

  detailComponentWrapper = ({ row }) => (
    <OrderDetail orderId={row.id} row={row} />
  )

  render() {
    const { orders, ordersContext } = this.props

    const inPatientContext = ordersContext == 'patient'

    const columns = inPatientContext
      ? this.defaultColumns
      : this.patientColumns.concat(this.defaultColumns)

    return (
      <DevXGrid
        columns={columns}
        hideExpansionArrow={row => row.substatus === 'Legacy'}
        key={this.state.key}
        rows={orders}
        onToggle={this.onToggle}
        detailComponent={this.detailComponentWrapper}
        totalCount={orders.size}
        rowActions={this.userRowActions}
        onRefresh={this.refresh}
        expandedRowIds={this.state.expandedRowIds}
        {...settings}
      />
    )
  }
}

// TODO: maybe add these back to the grid
// defaultOrder={defaultOrder}
OrderTable.propTypes = {
  orders: PropTypes.array,
  selectOrder: PropTypes.func,
  onPanelOpen: PropTypes.func,
  route: PropTypes.string,
  refresh: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  patientId: PropTypes.string, // Not required, Anderson
  ordersContext: PropTypes.string.isRequired,
  previewOrderPdf: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(OrderTable)
