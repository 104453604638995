import React, { PureComponent } from 'react'
import PropTypes from '~/utils/propTypes'
import TextField from './TextField'

const inputLabelProps = { shrink: true }

class DateField extends PureComponent {
  onChange = value => {
    if (value.slice(0, 1) !== '0') {
      const year = value.slice(0, 4)
      const monthAndDay = value.substring(value.length - 6, value.length)
      const newValue = `${year}${monthAndDay}`
      return this.props.onChange(newValue)
    }
  }

  render() {
    const { onChange } = this.props

    return (
      <TextField
        {...this.props}
        type="date"
        InputLabelProps={inputLabelProps}
        onChange={onChange && this.onChange}
      />
    )
  }
}

DateField.propTypes = {
  onChange: PropTypes.func,
}

export default DateField
