import { Map, Record } from 'immutable'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import rootKey from '../../key'
import { getEventTypes } from './root'

const PATIENT_SEARCH_STATUSES = [
  'Active',
  'Referred',
  'Scheduled',
  'Assigned',
  'Engaged',
  'Inactive',
]
const APP_SEARCH_LIMIT = 50
const PATIENT_SEARCH_LIMIT = 25

const PATIENT_SEARCH_RESULTS = 'patientSearchResults'

const PatientSearchResult = Record({
  id: null,
  status: null,
  npId: null,
  name: null,
  market: null,
  dob: null,
  contactId: null,
  ownerName: null,
})

export const searchPatients = Request({
  typePrefix: rootKey,
  typeBase: 'SEARCH_PATIENTS',
  requestParams: ['nameSearch', 'appId', 'market'],
  operation: (nameSearch, appId, market) =>
    AspireAPI.get('v1/search/patient', {
      params: {
        name_search: nameSearch,
        np_ids: appId,
        markets: market,
        statuses: PATIENT_SEARCH_STATUSES.join(','),
        limit: appId ? APP_SEARCH_LIMIT : PATIENT_SEARCH_LIMIT,
      },
    }),
  transform: into(PatientSearchResult, 'id'),
  messages: {
    failed: 'There was an issue searching for patients',
  },
})

const creator = scopedCreator(rootKey)
export const patientSearchChanged = creator('PATIENT_SEARCH_CHANGED', [
  'query',
  'appId',
  'market',
])
export const patientSearchCleared = creator('PATIENT_SEARCH_CLEARED')

const initState = Map()
export default createReducer(PATIENT_SEARCH_RESULTS, initState, {
  [searchPatients.SUCCEEDED]: (_state, { payload }) => payload,
  [patientSearchCleared]: () => initState,
})

export const getPatientSearchResults = pipe(
  getEventTypes,
  get(PATIENT_SEARCH_RESULTS)
)
