import { isAfter, isValid, parseISO } from 'date-fns'
import React from 'react'
// @ts-expect-error no export
import { formatDate } from '~/utils/format'
// @ts-expect-error ts-migrate(6133) FIXME: Could not find declaration file for module... Remove this comment to see the full error message
import PropTypes from '~/utils/propTypes'
import { Box, Icon, Link, Tooltip, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  boxRightMargin: { marginRight: theme.spacing(1) },
  boxLeftMargin: { marginLeft: theme.spacing(1) },
  strikeThrough: { textDecoration: 'line-through' },
}))

interface PrimaryPhonePropTypes {
  primaryPhone: PropTypes.object.isRequired
}

export const PrimaryPhone = ({ primaryPhone }: PrimaryPhonePropTypes) => {
  const classes = useStyles()
  const expirationMessage = `${'Expiration: '}${formatDate(
    primaryPhone ? primaryPhone.hipaaAuthExpiration : ''
  )}`

  const valid =
    isValid(parseISO(primaryPhone ? primaryPhone?.hipaaAuthExpiration : '')) &&
    isAfter(
      parseISO(primaryPhone ? primaryPhone?.hipaaAuthExpiration : ''),
      new Date()
    )

  return (
    <>
      {primaryPhone ? (
        <Box flex="1" display="flex" alignItems="center">
          <Box clone={true} className={classes.boxRightMargin}>
            <Icon fontSize="inherit"> phone </Icon>
          </Box>

          <Link
            href={'tel:' + primaryPhone.phone}
            color="inherit"
            className={primaryPhone.active ? '' : classes.strikeThrough}
          >
            {primaryPhone.phone}
          </Link>
          <Box className={classes.boxLeftMargin}>
            {primaryPhone.hipaaAuthExpiration ? (
              <Tooltip title={expirationMessage}>
                <Icon color={valid ? 'primary' : 'disabled'} fontSize="inherit">
                  security
                </Icon>
              </Tooltip>
            ) : (
              <Icon />
            )}
          </Box>
          {primaryPhone.phiVoicemailApproved ? (
            <Box className={classes.boxLeftMargin}>
              <Icon fontSize="inherit"> voicemail </Icon>
            </Box>
          ) : null}
          {primaryPhone.textingApproved ? (
            <Box className={classes.boxLeftMargin}>
              <Icon fontSize="inherit"> chat </Icon>
            </Box>
          ) : null}
        </Box>
      ) : (
        <Box flex="1 1 0%">&nbsp;</Box>
      )}
    </>
  )
}
