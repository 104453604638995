import { Map, Record } from 'immutable'
import { get as getIn } from 'lodash'
import AspireAPI from '~/resources/aspire'
// @ts-expect-error no export
import Request, { flattenErrors } from '~/utils/Request'
// @ts-expect-error no export
import createReducer from '~/utils/createReducer'
import { get } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'

const key = 'telehealthOS'

export const TelehealthOSCallSession = Record({
  contactDescription: null,
  phoneNumber: null,
  status: null,
  twilioCallSid: null,
})

export const dialOut = Request({
  typePrefix: key,
  typeBase: 'DIAL_OUT',
  requestParams: ['encounterId', 'phoneNumber', 'contactDescription'],
  operation: (
    encounterId: number,
    phoneNumber: string,
    contactDescription: string
  ) =>
    AspireAPI.post(`telehealth_os/dial_out`, {
      encounterId,
      phoneNumber,
      contactDescription,
    }),
  messages: {
    failed: 'There was a problem initiating the call.',
  },
})

export const endCall = Request({
  typePrefix: key,
  typeBase: 'END_CALL',
  requestParams: ['twilioCallSid', 'phoneNumber'],
  operation: (twilioCallSid: string) =>
    AspireAPI.post('telehealth_os/cancel_dial_out', {
      twilioCallSid,
    }),
  messages: {
    failed: 'Cannot end call.',
  },
})

export const updateCall = Request({
  typePrefix: key,
  typeBase: 'UPDATE_CALL',
  requestParams: ['phoneNumber', 'twilioCallSid', 'digit'],
  operation: (phoneNumber: string, twilioCallSid: string, digit: string) =>
    AspireAPI.post('telehealth_os/update_dial_out', {
      phoneNumber,
      twilioCallSid,
      digit,
    }),
  messages: {
    failed:
      'Cannot preform this action. It is possible that the user ended the call.',
  },
})

export const resendAppointmentInvite = Request({
  typePrefix: key,
  typeBase: 'RESEND_APPOINTMENT_INVITE',
  requestParams: ['encounterId'],
  operation: (encounterId: number) =>
    AspireAPI.post('telehealth_os/resend_appointment_invite', { encounterId }),
  messages: {
    succeeded: 'Invitation successfully sent to patient.',
    failed: (e: any) =>
      flattenErrors(getIn(e, 'response.data.message')) ||
      'Failed to send invitation.',
  },
})

export const addContactToVideo = Request({
  typePrefix: key,
  typeBase: 'ADD_USER_TO_VIDEO',
  requestParams: ['encounterId', 'contactId', 'manualEmail', 'manualUserName'],
  operation: (encounterId: number, contactId: number) =>
    AspireAPI.post('telehealth_os/add_contact_to_video', {
      encounterId,
      contactId,
    }),
  messages: {
    failed: (e: any) =>
      flattenErrors(getIn(e, 'response.data.message')) ||
      'Cannot Add User to Video',
    succeeded: 'Invitation Successfully Sent',
  },
})

export const addManualUserToVideo = Request({
  typePrefix: key,
  typeBase: 'ADD_USER_TO_VIDEO',
  requestParams: ['encounterId', 'contactId', 'manualEmail', 'manualUserName'],
  operation: (encounterId: number, email: string, userName: string) =>
    AspireAPI.post('telehealth_os/add_manual_user_to_video', {
      encounterId,
      email,
      userName,
    }),
  messages: {
    failed: (e: any) =>
      flattenErrors(getIn(e, 'response.data.message')) ||
      'Cannot Add User to Video',
    succeeded: 'Invitation Successfully Sent',
  },
})

export default createReducer(key, Map(), {
  [dialOut.REQUESTED]: (state: any, { payload }: any) =>
    state.set(
      payload.phoneNumber,
      TelehealthOSCallSession({ status: 'initated', ...payload })
    ),
  [dialOut.SUCCEEDED]: (state: any, { payload }: any) =>
    state
      .setIn([payload.phoneNumber, 'status'], 'active')
      .setIn([payload.phoneNumber, 'twilioCallSid'], payload.sid),
  [dialOut.FAILED]: (state: any, { meta }: any) =>
    state.setIn([meta.request.payload.phoneNumber, 'status'], 'failed'),
  [endCall.SUCCEEDED]: (state: any, { meta }: any) =>
    state.setIn([meta.request.payload.phoneNumber, 'status'], 'inactive'),
})

export const getTelehealthOSCallSessions = pipe(get('assessment'), get(key))
