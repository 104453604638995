import { format } from 'date-fns'
import React from 'react'
import CopyPhoneNumber from '~/components/CopyPhoneNumber'
import PropTypes from '~/utils/propTypes'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PHIContacts from './PHIContacts'

const useStyles = makeStyles(({ spacing }) => ({
  phoneNumber: {
    display: 'flex',
    alignItems: 'center',
  },
  phoneInfo: {
    marginLeft: spacing(0.5),
  },
  hipaaContact: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const ContactInfo = ({ contact, attempted, skipped }) => {
  const classes = useStyles()

  let color
  if (attempted) color = 'primary'
  else if (skipped) color = 'secondary'

  return (
    <React.Fragment>
      <div className={classes.phoneNumber}>
        <CopyPhoneNumber color={color} phoneNumber={contact.phoneNumber} />

        {contact.active && contact.active !== 'Active' && (
          <Typography
            className={classes.phoneInfo}
            variant="body2"
            color="secondary"
          >
            (Inactive)
          </Typography>
        )}

        {contact.isPreferred && (
          <Typography
            className={classes.phoneInfo}
            variant="body2"
            color="primary"
          >
            (Preferred)
          </Typography>
        )}
      </div>

      {contact.phiContact && (
        <div className={classes.hipaaContact}>
          <Typography variant="body2">
            HIPAA Authorized Contact
            {contact.hipaaAuthExpired && ' (Form Expired)'}
          </Typography>

          <PHIContacts />
        </div>
      )}

      {contact.phiMessageApproved && (
        <Typography variant="body2">Approved for PHI Messages</Typography>
      )}

      {contact.name && (
        <Typography variant="body2">Name: {contact.name}</Typography>
      )}

      {contact.type && (
        <Typography variant="body2">Type: {contact.type}</Typography>
      )}

      {contact.relationship && (
        <Typography variant="body2">
          Relationship: {contact.relationship}
        </Typography>
      )}

      {contact.effectiveEndDate && (
        <Typography variant="body2" color="secondary">
          Expired: {format(contact.effectiveEndDate, 'M/d/yyyy')}
        </Typography>
      )}

      {contact.phoneDescription && (
        <Typography variant="body2">
          Description: {contact.phoneDescription}
        </Typography>
      )}

      {contact.lastModifiedDate && (
        <Typography variant="body2">
          Last Modified: {format(contact.lastModifiedDate, 'M/d/yyyy')}
        </Typography>
      )}
    </React.Fragment>
  )
}

ContactInfo.propTypes = {
  contact: PropTypes.record.isRequired,
  attempted: PropTypes.bool,
  skipped: PropTypes.bool,
}

export default ContactInfo
