import React from 'react'
import { Authorized, actions, objects } from '~/features/authorization'
import { useAction, useToggle } from '~/hooks'
// @ts-expect-error ts-migrate(6133) FIXME: Could not find declaration file for module... Remove this comment to see the full error message
import PropTypes from '~/utils/propTypes'
import {
  AccordionActions,
  Box,
  Button,
  Divider,
  Tooltip,
} from '@material-ui/core'
import { selectedContact } from '../../../data/patientContactData'
import {
  addContactDialogOpened,
  editContactDialogType,
} from '../../../data/patientContactDialog'
import {
  fetchDecisionMaker,
  fetchPreferred,
} from '../../../data/patientContactsContext'
import { DeleteConfirmationDialog } from './DeleteConfirmationDialog'

interface ContactDetailsPropTypes {
  contact: PropTypes.object.isRequired
  sourceIsAssessment: PropTypes.boolean
  onChange: PropTypes.func
}

export const ContactEntryButtons = ({
  contact,
  sourceIsAssessment,
  onChange,
}: ContactDetailsPropTypes) => {
  const [open, toggleOpenTrue, toggleOpenFalse] = useToggle()

  const setDecisionMaker = useAction(fetchDecisionMaker.requested)
  const setPreferred = useAction(fetchPreferred.requested)
  const openEditContactDialog = useAction(addContactDialogOpened)
  const openEditContactAction = useAction(editContactDialogType)
  const setSelectedContact = useAction(selectedContact)

  const handleDecisionMaker = () => {
    if (sourceIsAssessment) {
      onChange(contact.id, 'decisionMaker')
    } else {
      const data = {
        decisionMaker: !contact.decisionMaker,
      }
      setDecisionMaker(contact.patientId, contact.id, data)
    }
  }

  const onEdit = () => {
    const editingContact = {
      id: contact.id,
      patientId: contact.patientId,
      type: contact.type,
      name: contact.name,
      relationship: contact.relationship,
      telehealthOsUserId: contact.telehealthOsUserId,
      notes: contact.notes,
      preferred: contact.preferred,
      decisionMaker: contact.decisionMaker,
      scheduling: contact.scheduling,
      phones: contact.phones,
      emails: contact.emails,
      addresses: contact.addresses,
      active: contact.active,
      emailAsked: contact.emailAsked,
      delete: contact.delete,
    }

    setSelectedContact(editingContact)
    openEditContactDialog()
    openEditContactAction()
  }
  const handlePreferred = () => {
    if (sourceIsAssessment) {
      onChange(contact.id, 'setPreferred')
    } else {
      const data = {
        preferred: true,
      }
      setPreferred(contact.patientId, contact.id, data)
    }
  }

  const onDelete = () => {
    sourceIsAssessment ? onChange(contact.id, 'delete') : toggleOpenTrue()
  }

  return (
    <>
      <Divider />
      <AccordionActions>
        <Box flex="1">
          <Button disabled={contact.preferred} onClick={handlePreferred}>
            {contact.preferred ? 'Preferred Contact' : 'Set Preferred'}
          </Button>
          <Button onClick={handleDecisionMaker}>
            {contact.decisionMaker
              ? 'Unset Decision Maker'
              : 'Set Decision Maker'}
          </Button>
        </Box>

        <Authorized object={objects.PATIENT_CONTACTS} action={actions.DELETE}>
          {contact.preferred ? (
            <Tooltip title="Cannot delete preferred contact">
              <span>
                <Button color="primary" disabled={true}>
                  Delete
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Button color="primary" onClick={onDelete}>
              Delete
            </Button>
          )}
        </Authorized>

        <Button color="primary" onClick={onEdit}>
          Edit
        </Button>
      </AccordionActions>
      <DeleteConfirmationDialog
        contact={contact}
        open={open}
        onClose={toggleOpenFalse}
      />
    </>
  )
}
