import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { PATIENT_RECORD_DEMOGRAPHICS } from '~/apps/patientRecord/router'
import { OpenLink } from '~/components/links'
import {
  useAction,
  useFieldValuesOld,
  useMountEffect,
  useUpdateEffect,
} from '~/hooks'
import { localToUtc, utcToLocal } from '~/utils/dates'
import { DATE_PICKER_DATETIME_FORMAT } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import {
  Avatar,
  Button,
  Chip,
  FormControl,
  Icon,
  IconButton,
  Input,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getTimeZone } from '../../../data/calendar'
import { requiredRescheduleReasonSet } from '../../../data/eventTypes'
import {
  confirmedChanged,
  descriptionChanged,
  encounterTypeChanged,
  fetchPatientInfo,
  getEncounterTypes,
  getServiceLocations,
  guestsChanged,
  isTelehealthOSVisitChanged,
  patientInfoCleared,
  serviceLocationChanged,
  visitDurationChanged,
  visitStartChanged,
} from '../../../data/events'
import {
  getPatientInfo,
  getPatientVirtualCapableError,
} from '../../../data/events/patientInfo'
import { fetchServiceLocations } from '../../../data/events/serviceLocations'
import {
  fetchContacts,
  getPatientContacts,
} from '../../../data/telehealthOS/patientContacts'
import {
  BaseField,
  CheckboxField,
  CreatedModifiedField,
  DateTimeField,
  DropDownField,
  LocationField,
  NumberField,
  PatientSearchField,
  TextField,
  TextLongField,
} from '../../EventFields'

const useStyles = makeStyles(({ spacing }) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  left: {
    display: 'flex',
    marginRight: spacing(1),
    justifyContent: 'space-between',
    width: '8%',
  },
  selectChips: {
    width: '100%',
  },
  menuIcon: {
    marginRight: spacing(1),
  },
}))

const VisitEventDetail = ({
  event,
  editing,
  creating,
  virtualVisitMissingContactInfo,
  isVirtualVisit,
}) => {
  const classes = useStyles()
  const encounterStatuses = useFieldValuesOld('encounter_assessment_status')
  const timeZone = useSelector(getTimeZone)
  const encounterTypes = useSelector(getEncounterTypes)
  const changeEncounterType = useAction(encounterTypeChanged)
  const onVisitStartChanged = useAction(visitStartChanged)
  const onGuestsChanged = useAction(guestsChanged)
  const fetchPatientInfoRequested = useAction(fetchPatientInfo.requested)
  const fetchPatientContacts = useAction(fetchContacts.requested)
  const [selectedContacts, setSelectedContacts] = useState([])
  const patientContacts = useSelector(getPatientContacts)
  const onConfirmedChange = useAction(confirmedChanged)
  const onDescriptionChange = useAction(descriptionChanged)
  const onVisitDurationChanged = useAction(visitDurationChanged)
  const clearPatientInfo = useAction(patientInfoCleared)
  const setRequireRescheduleReason = useAction(requiredRescheduleReasonSet)
  const encounterStatus = encounterStatuses.get(event.encounterStatus)
  const patientInfo = useSelector(getPatientInfo)
  const fetchServiceLocationsRequested = useAction(
    fetchServiceLocations.requested
  )
  const onServiceLocationChanged = useAction(serviceLocationChanged)
  const onIsTelehealthOSVisitRequested = useAction(isTelehealthOSVisitChanged)
  const serviceLocations = useSelector(getServiceLocations)

  const [initStart] = useState(event.start)

  const duration = event.duration

  const defaultDuration = encounterTypes.getIn([
    event.encounterType,
    'defaultVisitDuration',
  ])

  useMountEffect(
    () => {
      if (event.patientId) {
        fetchPatientInfoRequested(event.patientId, event.ownerId)
        fetchServiceLocationsRequested(event.patientId)
      }
    },
    () => {
      clearPatientInfo()
      setRequireRescheduleReason(false)
    }
  )

  useEffect(() => {
    onDurationChange(duration)
  }, [duration])

  useEffect(() => {
    onGuestsChanged([selectedContacts.map(c => c.get('id'))])
  }, [selectedContacts])

  useUpdateEffect(() => {
    if (patientInfo.id != null) {
      fetchServiceLocationsRequested(patientInfo.id)
    }
  }, [patientInfo.id])

  useUpdateEffect(() => {
    if (!creating) {
      const reasonRequired = !utcToLocal(event.start, timeZone).isSame(
        utcToLocal(initStart, timeZone),
        'day'
      )
      setRequireRescheduleReason(reasonRequired)
    }
  }, [creating, event.start, initStart, setRequireRescheduleReason])

  useUpdateEffect(() => {
    if (isVirtualVisit && patientInfo.virtualCapable) {
      onIsTelehealthOSVisitRequested(true)
    } else {
      onIsTelehealthOSVisitRequested(false)
    }
  }, [patientInfo.virtualCapable, isVirtualVisit])

  const start = utcToLocal(event.start, timeZone).format(
    DATE_PICKER_DATETIME_FORMAT
  )
  const end = utcToLocal(event.end, timeZone).format(
    DATE_PICKER_DATETIME_FORMAT
  )
  const isEditing = editing && !event.cancelled

  const [startDatetime, setStartDatetime] = useState(start)
  const [endDatetime, setEndDatetime] = useState(end)
  const [startTimeInPast, setStartTimeInPast] = useState(false)

  const onBlurStart = useCallback(
    localStart => {
      if (localStart) {
        const localEnd = moment(localStart).add(event.duration, 'minutes')
        const startNew = localToUtc(localStart, timeZone)
        const endNew = localToUtc(localEnd, timeZone)
        const startDate = moment(localStart).format('YYYY-MM-DD')
        const endDate = moment(localEnd).format('YYYY-MM-DD')

        setStartDatetime(localStart.format(DATE_PICKER_DATETIME_FORMAT))
        setEndDatetime(localEnd.format(DATE_PICKER_DATETIME_FORMAT))
        setStartTimeInPast(startNew <= moment())
        onVisitStartChanged(startNew, startDate, endNew, endDate)
      }
    },
    [event.duration, timeZone, onVisitStartChanged]
  )
  const onDurationChange = useCallback(
    duration => {
      const localEnd = moment(start).add(duration, 'minutes')
      const endNew = localToUtc(localEnd, timeZone)
      const endDate = localEnd.format('YYYY-MM-DD')

      setEndDatetime(localEnd.format(DATE_PICKER_DATETIME_FORMAT))
      onVisitDurationChanged(duration, endNew, endDate)
    },
    [start, timeZone, onVisitDurationChanged]
  )

  const virtualEncounterTypeSelected = event.encounterType?.includes('virtual')

  useUpdateEffect(() => {
    if (virtualEncounterTypeSelected) {
      fetchPatientContacts(patientInfo.id)
    }
  }, [virtualEncounterTypeSelected])

  const durationDefault = defaultDuration ? (
    <Button
      onClick={() => {
        onDurationChange(defaultDuration)
      }}
    >{`Default: ${defaultDuration}`}</Button>
  ) : (
    'minutes'
  )

  const getAppointmentReminderModality = () => {
    const email = event.patientEmail
    const phone = event.patientPhone && patientInfo.textingApproved
    if (email && phone) {
      return 'Patient will receive appointment reminders via email and text.'
    } else if (email && !phone) {
      return 'Patient will receive appointment reminders via email'
    } else if (!email && phone) {
      return 'Patient will receive appointment reminders via text'
    } else null
  }

  const validVirtualContacts = useMemo(
    () =>
      patientContacts.filter(c => c.active && c.preferredEmail).toIndexedSeq(),
    [patientContacts]
  )

  const patientId = patientInfo.id

  const virtualCapableFilter = assessmentTypes => {
    return patientInfo.virtualCapable
      ? assessmentTypes
      : assessmentTypes.filter(types => !types.type.includes('virtual'))
  }
  const assessmentsOptions = useMemo(
    () =>
      virtualCapableFilter(encounterTypes)
        .toList()
        .sortBy(({ label }) => label),
    [encounterTypes]
  )

  useEffect(() => {
    if (assessmentsOptions.size === 1) {
      changeEncounterType(assessmentsOptions.get(0).type)
    }
  }, [assessmentsOptions])
  return (
    <React.Fragment>
      <PatientSearchField event={event} editing={isEditing && creating} />
      {encounterStatus && (
        <TextField label="Assessment Status" value={encounterStatus.label} />
      )}
      <BaseField label="Virtual Capable">
        <div className={classes.row}>
          <div className={classes.left}>
            <Typography variant="subtitle1">
              {patientInfo.virtualCapable ? 'Yes' : 'No'}
            </Typography>
            {!patientInfo.virtualCapable && (
              <Tooltip title={getPatientVirtualCapableError(patientInfo)}>
                <Icon color="error" fontSize="small">
                  info
                </Icon>
              </Tooltip>
            )}
          </div>
          <div>
            {!patientInfo.virtualCapable && patientId && (
              <Tooltip title="Update Virtual Capability Fields">
                <IconButton
                  size="small"
                  component={OpenLink}
                  route={PATIENT_RECORD_DEMOGRAPHICS}
                  params={{ patientId }}
                >
                  <Icon>link</Icon>
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
      </BaseField>
      <TextLongField
        label="Notes"
        value={event.description}
        editing={isEditing}
        onChange={onDescriptionChange}
      />
      {isEditing && creating ? (
        <DropDownField
          editing
          label="Encounter Type"
          error={!event.encounterType}
          value={event.encounterType}
          values={assessmentsOptions}
          getValue={encounterType => encounterType.type}
          getLabel={encounterType => encounterType.label}
          onChange={changeEncounterType}
          allowNone={false}
        />
      ) : (
        <TextField label="Encounter Type" value={event.encounterTypeLabel} />
      )}
      {virtualVisitMissingContactInfo ? (
        <Typography color="error" variant="subtitle1">
          Patient does not have preferred email/texting approved phone number.
          Cannot start virtual visit without email/phone number.
        </Typography>
      ) : null}
      {isVirtualVisit &&
      event.isTelehealthOSVisit &&
      patientInfo.virtualCapable &&
      !virtualVisitMissingContactInfo ? (
        <Typography color="error" variant="subtitle1">
          {getAppointmentReminderModality()}
        </Typography>
      ) : null}
      {virtualEncounterTypeSelected &&
      patientInfo.virtualCapable &&
      validVirtualContacts.size > 0 ? (
        <div>
          <BaseField label="Additional Attendees">
            <FormControl className={classes.selectChips}>
              <Select
                multiple
                value={selectedContacts}
                onChange={e => {
                  const { value } = e.target
                  return setSelectedContacts(
                    value.includes(null) ? null : value,
                    e
                  )
                }}
                input={<Input />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(contact => (
                      <Chip
                        key={contact.id}
                        label={contact.name}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
              >
                {validVirtualContacts.map(contact => (
                  <MenuItem key={contact.id} value={contact}>
                    <Avatar className={classes.menuIcon}>
                      <Icon>person</Icon>
                    </Avatar>
                    {contact.name} - {contact.relationship} (
                    {contact.preferredEmail})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </BaseField>
          <Typography>
            Selected Attendees will receive an invite to join the virtual visit
            via email
          </Typography>
        </div>
      ) : null}
      {isVirtualVisit ? (
        <CheckboxField
          label="Create TelehealthOS Visit"
          value={event.isTelehealthOSVisit}
          onChange={onIsTelehealthOSVisitRequested}
          editing={isEditing}
        />
      ) : null}
      <NumberField
        label="Duration"
        value={event.duration}
        onChange={onDurationChange}
        editing={isEditing}
        after={durationDefault}
      />
      <DateTimeField
        label="Start"
        value={startDatetime}
        onBlur={onBlurStart}
        onChange={(v, _e) => setStartDatetime(v)}
        editing={isEditing}
      />
      {startTimeInPast ? (
        <Typography color="error" variant="subtitle1">
          This assessment is in the past, are you sure you want to proceed?
        </Typography>
      ) : null}
      <DateTimeField label="End" value={endDatetime} editing={false} />
      <CheckboxField
        label="Confirmed"
        value={event.confirmed}
        onChange={onConfirmedChange}
        editing={isEditing}
      />
      {patientInfo.isCaseMgmt && serviceLocations.size == 0 ? (
        <Typography color="error" variant="subtitle1">
          There is no service location in the patient’s home state. Please add
          note.
        </Typography>
      ) : null}
      {patientInfo.isCaseMgmt && serviceLocations.size !== 0 ? (
        <DropDownField
          label="Service Location"
          values={serviceLocations}
          editing={isEditing}
          onChange={onServiceLocationChanged}
          error={!event.serviceLocationId}
          value={event.serviceLocationId}
          getValue={serviceLocation => serviceLocation.id}
          getLabel={serviceLocation => serviceLocation.name}
          allowNone={false}
        />
      ) : (
        <LocationField value={event.location} editing={isEditing} />
      )}
      <CreatedModifiedField event={event} />
    </React.Fragment>
  )
}

VisitEventDetail.propTypes = {
  event: PropTypes.record.isRequired,
  editing: PropTypes.bool,
  creating: PropTypes.bool,
  virtualVisitMissingContactInfo: PropTypes.bool,
  isVirtualVisit: PropTypes.bool,
}

export default VisitEventDetail
