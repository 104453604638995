import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'redux-routable-react'
import DevXTitleHeader from '~/components/DevXTitleHeader'
import PropTypes from '~/utils/propTypes'
import { Button } from '@material-ui/core'
import CareTeamsGrid from '../components/CareTeamsGrid'
import { getCareTeamsWithUsers, getRoles, getTypes } from '../data/careTeams'
import {
  CARE_TEAM_MANAGEMENT_MEMBER_REPLACEMENT,
  CARE_TEAM_MANAGEMENT_NEW_CARE_TEAM,
  CARE_TEAM_POD_MANAGER,
} from '../routes'
import CTDetail from './CTDetail'

const GridDetail = ({ row }) => <CTDetail careTeamDetails={row} />

GridDetail.propTypes = {
  row: PropTypes.object.isRequired,
}

const CareTeamsList = () => {
  const careTeams = useSelector(getCareTeamsWithUsers)
  const roles = useSelector(getRoles)
  const types = useSelector(getTypes)

  return (
    <React.Fragment>
      <DevXTitleHeader>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          route={CARE_TEAM_MANAGEMENT_NEW_CARE_TEAM}
        >
          Add New Care Team
        </Button>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          route={CARE_TEAM_MANAGEMENT_MEMBER_REPLACEMENT}
        >
          Care Team Member Replacement
        </Button>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          route={CARE_TEAM_POD_MANAGER}
        >
          Care Team Pods
        </Button>
      </DevXTitleHeader>
      <CareTeamsGrid
        displayComponent={GridDetail}
        careTeams={careTeams}
        roles={roles}
        types={types}
      />
    </React.Fragment>
  )
}

export default CareTeamsList
