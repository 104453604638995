import { Map, Record } from 'immutable'
import { combineReducers } from 'redux-immutable'
import { createSelector } from 'reselect'
import AspireAPI from '~/resources/aspire'
import Request from '~/utils/Request'
import createReducer from '~/utils/createReducer'
import { get, into, scopedCreator } from '~/utils/data'
import { pipe } from '~/utils/functionalHelpers'
import { medAssessmentSet } from './assessment'
import { getMedications, handleMedMessage } from './root'

const PATIENT_MEDICATION = 'patientMedications'
const SAVED = 'saved'
const UNSAVED = 'unsaved'

const creator = scopedCreator(PATIENT_MEDICATION)

export const clearPatientMedications = creator('CLEAR_PATIENT_MEDICATIONS')
export const discardChangesForUnsavedMeds = creator(
  'DISCARD_CHANGES_FOR_UNSAVED_MEDS',
  ['ids']
)
export const saveOffline = creator('SAVE_OFFLINE')
export const savePatientMedication = creator('SUBMIT_MED', [
  'medication',
  'mdtSingleSignOnRequest',
])

export const Prescription = Record({
  adherence: null,
  compound: null,
  csmdAttestationDate: null,
  daysSupply: null,
  directions: null,
  drug: null,
  drugId: null,
  eKit: null,
  genericDrugId: null,
  id: null,
  internalNote: null,
  locationId: null,
  modifiedAt: null,
  modifiedBy: null,
  modifiedByName: null,
  ndc: null,
  overrideReason: null,
  partnerMedId: null,
  patientId: null,
  pharmacyNcpdpId: null,
  pharmacyNote: null,
  prescriberId: null,
  prescriptionDate: null,
  prevId: null,
  primaryIndication: null,
  primaryIndicationOther: null,
  providerName: null,
  qty: null,
  qtyQual: null,
  recentlyUpdated: null,
  refills: null,
  routeDetail: null,
  rxnorm: null,
  schedule: null,
  secondaryIndication: null,
  secondaryIndicationOther: null,
  startDate: null,
  status: null,
  stopDate: null,
  substitutionAllowedFlag: null,
  supply: null,
  source: null,
})

const transformPrescription = ({ modifiedBy, ...data }) => {
  return Prescription({
    modifiedByName: modifiedBy?.name || null,
    ...data,
  })
}

/* FETCH PATIENT RX */
const fetchPatientRxApi = (patientId, options = {}) =>
  AspireAPI.get('medications/prescriptions/', {
    params: { patient_id: patientId, ...options },
  })

export const fetchPatientRx = Request({
  typePrefix: PATIENT_MEDICATION,
  typeBase: 'FETCH',
  requestParams: ['patientId', 'options'],
  operation: fetchPatientRxApi,
  transform: into(transformPrescription, 'id'),
  messages: {
    failed: handleMedMessage(
      "There was a problem fetching patient's prescriptions"
    ),
  },
})

/* UPDATE PATIENT RX */
const putPatientRxApi = (medication, wait) => {
  return AspireAPI.put(
    `medications/prescriptions/${medication.get('id')}`,
    medication,
    { params: { wait } }
  )
}

export const putPatientRx = Request({
  typePrefix: PATIENT_MEDICATION,
  typeBase: 'UPDATE',
  requestParams: ['medication', 'mdtSingleSignOnRequest'],
  operation: putPatientRxApi,
  transform: p =>
    Prescription({
      ...p,
      ...(p.status !== 'Deleted' && {
        recentlyUpdated: true,
        modifiedByName: p.modifiedBy.name,
      }),
    }),
  messages: {
    requested: 'Saving prescription',
    failed: handleMedMessage(
      "There was a problem updating the patient's prescriptions"
    ),
  },
})

/* SYNC PATIENT RX WITH MDTOOLBOX */
const syncPatientRxApi = pid =>
  AspireAPI.get('medications/mdt_sync/', { params: { pid } })

export const syncPatientRx = Request({
  typePrefix: PATIENT_MEDICATION,
  typeBase: 'SYNC',
  requestParams: ['patientId'],
  operation: syncPatientRxApi,
  transform: into(Prescription, 'id'),
  messages: {
    failed: handleMedMessage(
      "There was a problem syncing the patient's prescriptions"
    ),
  },
})

const patientMedicaitons = createReducer(SAVED, Map(), {
  [clearPatientMedications]: () => Map(),
  [medAssessmentSet]: (_state, { payload }) =>
    into(Prescription, 'id')(payload.patientRx),
  [fetchPatientRx.SUCCEEDED]: (state, { payload }) => state.merge(payload),
  [putPatientRx.SUCCEEDED]: (state, { payload }) =>
    state.set(payload.id, payload),
  [syncPatientRx.SUCCEEDED]: (state, { payload }) => state.merge(payload),
})

const patientUnsavedMedications = createReducer(UNSAVED, Map(), {
  [discardChangesForUnsavedMeds]: (state, { payload: { ids } }) =>
    Array.isArray(ids)
      ? ids.reduce((state, id) => state.delete(id), state)
      : state,
  [medAssessmentSet]: (_state, { payload }) =>
    into(Prescription, 'id')(payload.patientUnsavedRx),
  [putPatientRx.SUCCEEDED]: (state, { payload }) => state.delete(payload.id),
  [saveOffline]: (state, { payload }) => state.set(payload.get('id'), payload),
})

const combinedReducer = combineReducers({
  [patientMedicaitons.key]: patientMedicaitons,
  [patientUnsavedMedications.key]: patientUnsavedMedications,
})

combinedReducer.key = PATIENT_MEDICATION
export default combinedReducer

/* SELECTORS */
const getPatientRxMap = rxMapKey =>
  pipe(getMedications, get(PATIENT_MEDICATION), get(rxMapKey))

const createMedicationSelector = returnFunction => rxMapKey => {
  const getRxMap = getPatientRxMap(rxMapKey)
  return createSelector([getRxMap], returnFunction)
}

const getMedicationArrayByKey = createMedicationSelector(patientRxMap =>
  patientRxMap.toIndexedSeq().toJS()
)

const getPatientRxFromKeyById = createMedicationSelector(patientRxMap => id =>
  patientRxMap.get(id)
)

export const getPatientRx = getPatientRxMap(SAVED)
export const getPatientUnsavedRx = getPatientRxMap(UNSAVED)

export const getPatientUnsavedRxArray = getMedicationArrayByKey(UNSAVED)
export const getPatientRxArray = createSelector(
  [getPatientRx, getPatientUnsavedRxArray],
  (savedRxMap, unsavedRxArray) => {
    const unsavedIdArray = unsavedRxArray.map(({ id }) => id)

    return savedRxMap
      .filter((_value, key) => !unsavedIdArray.includes(key))
      .toIndexedSeq()
      .toJS()
  }
)

/**
 * Returns the medications that as saved on the Aspire EMR
 * which are the ones that were manually added
 */
export const getInternalPatientRXArray = createSelector(
  [getPatientRxArray],
  rxArray => rxArray.filter(item => item.source === 'manually_added')
)

/**
 * Returns the medications that come from sources different than
 * Aspire, like Nextgen or Claims.
 */
export const getExternalPatientRxArray = createSelector(
  [getPatientRxArray],
  rxArray => rxArray.filter(item => item.source !== 'manually_added')
)

export const getPatientRxById = getPatientRxFromKeyById(SAVED)
export const getPatientUnsavedRxById = getPatientRxFromKeyById(UNSAVED)

export const getGenericDrugIdArray = createSelector(
  [getPatientRx],
  patientRxMap =>
    patientRxMap
      .filter(rx => Boolean(rx.genericDrugId))
      .toList()
      .map(rx => rx.genericDrugId)
)
