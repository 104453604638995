import { entered } from 'redux-routable'
import { filter, mapTo } from 'rxjs/operators'
import { fetchDistinctFieldValues } from '~/data/fieldValues'
import { PATIENT_RECORD_DEMOGRAPHICS } from '../router'

export default action$ =>
  action$.pipe(
    filter(entered(PATIENT_RECORD_DEMOGRAPHICS)),
    mapTo(
      fetchDistinctFieldValues([
        'contact_type',
        'contact_address_type',
        'contact_phone_type',
        'contact_email_type',
      ])
    )
  )
