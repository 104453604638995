import classnames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, change, formValueSelector, reduxForm } from 'redux-form'
import HasOpenAdmitsDialog from '~/components/dialogs/HasOpenAdmitsDialog'
import { DateField, SelectField, TextField } from '~/components/formFields'
import { getFieldValues } from '~/data/fieldValues'
import formValuesTransform from '~/utils/formValuesTransform'
import PropTypes from '~/utils/propTypes'
import Divider from '@material-ui/core/Divider'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import {
  getHospitalizationFromValues,
  getValuesFromHospitalization,
} from '../utils/selectors'
import validate from '../utils/validators'
import HospitalSearch from './HospitalSearch'

const styles = ({ spacing }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    padding: spacing(0.5),
    margin: [0, spacing(0.5)],
  },
  marginRight: {
    marginRight: spacing(2),
  },
  field: {
    marginBottom: spacing(1),
  },
  divider: {
    margin: [spacing(1), 0],
  },
  width20: {
    width: spacing(20),
  },
  width25: {
    width: spacing(25),
  },
  width30: {
    width: spacing(30),
  },
  width40: {
    width: spacing(40),
  },
})

const getMenuItems = items =>
  items.toIndexedSeq().map(({ value, label }) => (
    <MenuItem key={value} value={label}>
      {label}
    </MenuItem>
  ))

class HospitalizationForm extends React.PureComponent {
  state = { searchOpen: false }

  onOpenSearch = () => this.setState({ searchOpen: true })
  onCloseSearch = () => this.setState({ searchOpen: false })

  onSetHospital = ({ id, name }) => {
    const { form, changeValue } = this.props
    changeValue(form, 'hospitalId', id)
    changeValue(form, 'hospitalName', name)
  }

  onCheckUnknownHospital = () => {
    const { form, changeValue } = this.props
    changeValue(form, 'hospitalName', 'Unknown Hospital')
  }

  onClearHospital = () => {
    const { form, changeValue } = this.props
    changeValue(form, 'hospitalId', null)
    changeValue(form, 'hospitalName', null)
  }

  handleSubmit = hospitalization => {
    const { handleSubmit } = this.props
    const failed = handleSubmit(hospitalization)
    if (!failed) this.onClose()
  }

  onClose = () => {
    const { reset, onClose } = this.props
    onClose()
    reset()
  }

  titleLabel = this.props.mode === 'add' ? 'Add' : 'Save'

  render() {
    const { searchOpen } = this.state
    const {
      classes,
      mode,
      pristine,
      bedTypes,
      hospitalizationReasons,
      notifiedByParties,
      dischargedToLocations,
      hospitalName,
      hospitalizationReason,
      notifiedBy,
      dischargedFromHospitalTo,
      aspireContactedPrior,
    } = this.props

    return (
      <React.Fragment>
        <HasOpenAdmitsDialog
          open={true}
          title={`${this.titleLabel} Hospitalization`}
          mode={mode}
          onAdd={this.handleSubmit}
          onSave={this.handleSubmit}
          onClose={this.onClose}
          disableAction={pristine}
          maxWidth="md"
          fullWidth
        >
          <form>
            <div className={classes.container}>
              {/* Hospital */}
              <Field
                component={TextField}
                name="hospitalName"
                label={hospitalName ? 'Hospital:' : 'No Hospital Selected'}
                className={classnames(
                  classes.field,
                  classes.width40,
                  classes.marginRight
                )}
                disabled={true}
              />

              <Tooltip title="Search Hospitals">
                <IconButton
                  onClick={this.onOpenSearch}
                  className={classes.icon}
                >
                  <Icon>search</Icon>
                </IconButton>
              </Tooltip>

              {hospitalName && (
                <Tooltip title="Clear Selected Hospital">
                  <IconButton
                    onClick={this.onClearHospital}
                    className={classes.icon}
                  >
                    <Icon>clear</Icon>
                  </IconButton>
                </Tooltip>
              )}
            </div>

            <div className={classes.container}>
              {/* Bed Type */}
              <Field
                component={SelectField}
                name="bedType"
                label="Bed Type"
                className={classnames(
                  classes.field,
                  classes.width25,
                  classes.marginRight
                )}
              >
                {getMenuItems(bedTypes)}
              </Field>

              {/* Admission Date */}
              <Field
                component={DateField}
                name="admissionDate"
                label="Admission Date"
                className={classnames(
                  classes.field,
                  classes.marginRight,
                  classes.width20
                )}
              />

              {/* Discharge Date */}
              <Field
                component={DateField}
                name="dischargeDate"
                label="Discharge Date"
                className={classnames(
                  classes.field,
                  classes.width20,
                  classes.marginRight
                )}
              />

              {/* Was Hospitalization Avoidable */}
              <Field
                component={SelectField}
                name="wasHospitalizationAvoidable"
                label="Was Hospitalization Avoidable"
                className={classnames(
                  classes.field,
                  classes.width30,
                  classes.marginRight
                )}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="Indeterminate">Indeterminate</MenuItem>
              </Field>
            </div>

            <div className={classes.container}>
              {/* Hospitalization Reasons */}
              <Field
                component={SelectField}
                name="reason"
                label="Hospitalization Reason"
                className={classnames(
                  classes.field,
                  classes.width25,
                  classes.marginRight
                )}
              >
                {getMenuItems(hospitalizationReasons)}
              </Field>

              {/* Notified By */}
              <Field
                component={SelectField}
                name="notifiedBy"
                label="Notified By"
                className={classnames(
                  classes.field,
                  classes.width20,
                  classes.marginRight
                )}
              >
                {getMenuItems(notifiedByParties)}
              </Field>

              {/* Discharged To Locations */}
              <Field
                component={SelectField}
                name="dischargedFromHospitalTo"
                label="Discharged To"
                className={classnames(
                  classes.field,
                  classes.width20,
                  classes.marginRight
                )}
              >
                {getMenuItems(dischargedToLocations)}
              </Field>

              {/* Aspire Contacted Prior */}
              <Field
                component={SelectField}
                name="aspireContactedPrior"
                label="Aspire Contacted Prior"
                className={classnames(
                  classes.field,
                  classes.width30,
                  classes.marginRight
                )}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="Unknown">Unknown</MenuItem>
              </Field>
            </div>

            {/* Hospitalization Reason - Other */}
            {hospitalizationReason === 'Other' && (
              <Field
                component={TextField}
                name="otherHospitalizationReason"
                label="Hospitalization Reason - Other"
                fullWidth
                inputProps={{ maxLength: 255 }}
                className={classes.field}
              />
            )}

            {/* Notified By - Other */}
            {notifiedBy === 'Other' && (
              <Field
                component={TextField}
                name="notifiedByOther"
                label="Notified By - Other"
                fullWidth
                className={classes.field}
              />
            )}

            {/* Discharged To - Other */}
            {dischargedFromHospitalTo === 'Other' && (
              <Field
                component={TextField}
                name="dischargedFromHospitalToOther"
                label="Discharged To - Other"
                fullWidth
                className={classes.field}
              />
            )}

            {/* Aspire Contacted Prior - No/Unknown */}
            {(aspireContactedPrior === 'No' ||
              aspireContactedPrior === 'Unknown') && (
              <Field
                component={TextField}
                inputProps={{ maxLength: 255 }}
                name="aspireContactedPriorNo"
                label="Aspire Contacted Prior - No/Unknown"
                fullWidth
                multiline
                rowsMax={6}
                className={classes.field}
              />
            )}

            {/* Notes */}
            <Field
              component={TextField}
              name="otherHospitalizationInfo"
              label="Hospitalization Notes"
              fullWidth
              multiline
              rowsMax={6}
              inputProps={{ maxLength: 255 }}
              className={classes.field}
            />

            <div className={classes.divider}>
              <Typography variant="subtitle1">
                Other Hospitalization Information
              </Typography>
              <Divider />
            </div>

            <div className={classes.container}>
              {/* Authorization Number */}
              <Field
                component={TextField}
                name="authorizationNumber"
                label="Authorization Number"
                className={classnames(
                  classes.field,
                  classes.width25,
                  classes.marginRight
                )}
              />

              {/* Included Observation Stay */}
              <Field
                component={SelectField}
                name="includedObservationStay"
                label="Included Observation Stay"
                className={classnames(
                  classes.field,
                  classes.width30,
                  classes.marginRight
                )}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Field>
            </div>

            {/* Admission Diagnoses */}
            <Field
              component={TextField}
              name="admissionDiagnoses"
              label="Admission Diagnoses"
              fullWidth
              multiline
              rowsMax={6}
              inputProps={{ maxLength: 255 }}
              className={classes.field}
            />

            {/* Discharge Diagnoses */}
            <Field
              component={TextField}
              name="dischargeDiagnoses"
              label="Discharge Diagnoses"
              fullWidth
              multiline
              rowsMax={6}
              className={classes.field}
            />
          </form>
        </HasOpenAdmitsDialog>

        <HospitalSearch
          open={searchOpen}
          onSelect={this.onSetHospital}
          onClose={this.onCloseSearch}
          onChange={this.onCheckUnknownHospital}
        />
      </React.Fragment>
    )
  }
}

HospitalizationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  hospitalization: PropTypes.record, // eslint-disable-line react/no-unused-prop-types
  pristine: PropTypes.bool.isRequired,
  bedTypes: PropTypes.map,
  hospitalizationReasons: PropTypes.map,
  notifiedByParties: PropTypes.map,
  dischargedToLocations: PropTypes.map,
  hospitalName: PropTypes.string,
  hospitalizationReason: PropTypes.string,
  notifiedBy: PropTypes.string,
  dischargedFromHospitalTo: PropTypes.string,
  aspireContactedPrior: PropTypes.string,
  reset: PropTypes.func.isRequired,
  changeValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  onClose: PropTypes.func.isRequired,
}

export default compose(
  connect(
    (state, { form }) => {
      const selector = formValueSelector(form)

      return {
        bedTypes: getFieldValues('hospitalization_bed_type')(state),
        hospitalizationReasons: getFieldValues('hospitalization_reason')(state),
        notifiedByParties: getFieldValues('hospitalization_notified_by')(state),
        dischargedToLocations: getFieldValues('hospitalization_discharged_to')(
          state
        ),
        hospitalName: selector(state, 'hospitalName'),
        hospitalizationReason: selector(state, 'reason'),
        notifiedBy: selector(state, 'notifiedBy'),
        dischargedFromHospitalTo: selector(state, 'dischargedFromHospitalTo'),
        aspireContactedPrior: selector(state, 'aspireContactedPrior'),
      }
    },
    { changeValue: change }
  ),
  formValuesTransform(
    'hospitalization',
    getValuesFromHospitalization,
    getHospitalizationFromValues
  ),
  reduxForm({ enableReinitialize: true, validate }),
  withStyles(styles)
)(HospitalizationForm)
