import { Map, OrderedMap } from 'immutable'
import conditions from './conditions'

const createValues = values =>
  values.reduce(
    (values, value) =>
      values.set(value.text, Map({ ...value, id: value.text })),
    OrderedMap()
  )

const values = {
  cancellationType: createValues([
    { text: 'Unsuccessful Visit', isActive: conditions.alwaysTrue },
    { text: 'Cancellation Prior To Visit', isActive: conditions.alwaysTrue },
  ]),
  cancelledBy: createValues([
    { text: 'Patient Driven', isActive: conditions.anyType },
    { text: 'Aspire Driven', isActive: conditions.priorToVisit },
  ]),
  cancellationReason: createValues([
    {
      text: 'No Answer - Did Not Leave Door Hanger',
      isActive: conditions.unsuccessfulPatientDriven,
    },
    {
      text: 'No Answer - Left Door Hanger',
      isActive: conditions.unsuccessfulPatientDriven,
    },
    {
      text: 'PT Not Home - Living in LTC Facility',
      isActive: conditions.unsuccessfulPatientDriven,
    },
    {
      text: 'PT Not Home - Living in SNF',
      isActive: conditions.unsuccessfulPatientDriven,
    },
    {
      text: 'PT Not Home - In Hospital',
      isActive: conditions.unsuccessfulPatientDriven,
    },
    {
      text: 'PT Not Home - In Hospice',
      isActive: conditions.unsuccessfulPatientDriven,
    },
    {
      text: 'PT Home - Declined Visit',
      isActive: conditions.unsuccessfulPatientDriven,
    },
    {
      text: 'PT Not Home - Unavailable',
      isActive: conditions.unsuccessfulPatientDriven,
    },
    {
      text: 'Caregiver Refuses Service',
      isActive: conditions.unsuccessfulPatientDriven,
    },
    { text: 'APP Requested - Illness', isActive: conditions.priorAspireDriven },
    {
      text: 'APP Requested - Car Trouble',
      isActive: conditions.priorAspireDriven,
    },
    {
      text: 'APP Requested - Urgent Visit',
      isActive: conditions.priorAspireDriven,
    },
    {
      text: 'APP Requested - Personal',
      isActive: conditions.priorAspireDriven,
    },
    { text: 'APP Requested - Weather', isActive: conditions.priorAspireDriven },
    { text: 'Management Request', isActive: conditions.priorAspireDriven },
    {
      text: 'Patient Discharged from Service',
      isActive: conditions.priorAspireDriven,
    },
    { text: 'Changed to Urgent Visit', isActive: conditions.priorAspireDriven },
    {
      text: 'Administrative - Over Scheduled',
      isActive: conditions.priorAspireDriven,
    },
    {
      text: 'Administrative - APP Left Company',
      isActive: conditions.priorAspireDriven,
    },
    {
      text: 'Administrative - APP Reassigned',
      isActive: conditions.priorAspireDriven,
    },
    {
      text: 'Administrative - Pull Forward',
      isActive: conditions.priorAspireDriven,
    },
    {
      text: 'Administrative - Unrealistic drive time',
      isActive: conditions.priorAspireDriven,
    },
    { text: 'Administrative - Error', isActive: conditions.priorAspireDriven },
    {
      text: 'Has Another Appointment',
      isActive: conditions.priorPatientDriven,
    },
    {
      text: 'Refuses the Service - Requests Discharge',
      isActive: conditions.priorPatientDriven,
    },
    {
      text: 'Preference Not to Have Visit that Day',
      isActive: conditions.priorPatientDriven,
    },
    {
      text: 'Living in Long Term Care Facility',
      isActive: conditions.priorPatientDriven,
    },
    {
      text: 'In Skilled Nursing Facility Temporarily',
      isActive: conditions.priorPatientDriven,
    },
    { text: 'In Hospital', isActive: conditions.priorPatientDriven },
    { text: 'In Hospice', isActive: conditions.priorPatientDriven },
    {
      text: 'Caregiver Availability/ Preference',
      isActive: conditions.priorPatientDriven,
    },
    { text: 'Patient Deceased', isActive: conditions.patientDriven },
    {
      text: 'Ineligible Insurance',
      isActive: conditions.patientDriven,
    },
  ]),
  refusedServiceReason: createValues([
    { text: 'Patient Feels "Too Well"', isActive: conditions.defaultRefusal },
    {
      text: 'Too Many Providers in the Home',
      isActive: conditions.defaultRefusal,
    },
    {
      text: 'Hospice Scheduled or Transitioned',
      isActive: conditions.defaultRefusal,
    },
    {
      text: 'Content with Current Medical Care',
      isActive: conditions.defaultRefusal,
    },
    { text: "Provider Didn't Know Aspire", isActive: conditions.priorRefusal },
    { text: "PCP Didn't Want Service", isActive: conditions.priorRefusal },
    { text: "Didn't Like Aspire Provider", isActive: conditions.priorRefusal },
    {
      text: 'Patient Wanted to be Rescheduled',
      isActive: conditions.unsuccessfulRefusal,
    },
    {
      text: 'Unable to Obtain Consent',
      isActive: conditions.unsuccessfulRefusal,
    },
    {
      text: 'Insurance / Financial Concern',
      isActive: conditions.unsuccessfulRefusal,
    },
    {
      text: 'Considering Service - Needs to Research',
      isActive: conditions.unsuccessfulRefusal,
    },
    {
      text: 'Too Sick or Tired for Visit',
      isActive: conditions.unsuccessfulRefusal,
    },
    {
      text: 'Served by Another Palliative Care Company',
      isActive: conditions.unsuccessfulRefusal,
    },
    { text: 'Anxiety About Service', isActive: conditions.unsuccessfulRefusal },
    { text: 'Too Frequent Visits', isActive: conditions.unsuccessfulRefusal },
    {
      text: 'Does Not Want to be Bothered',
      isActive: conditions.unsuccessfulRefusal,
    },
    {
      text: "Patient/Caregiver does not want Aspire's service",
      isActive: conditions.unsuccessfulRefusal,
    },
    {
      text: 'Caregiver had an unexpected conflict',
      isActive: conditions.unsuccessfulRefusal,
    },
    {
      text: 'Patient did not want to be seen today - wishes to be rescheduled',
      isActive: conditions.declinedVisitRefusal,
    },
    {
      text: 'Patient was confused about appointment day/time',
      isActive: conditions.declinedVisitRefusal,
    },
    {
      text: 'Patient forgot appointment',
      isActive: conditions.declinedVisitRefusal,
    },
    {
      text: 'Patient had an unexpected conflict',
      isActive: conditions.declinedVisitRefusal,
    },
    {
      text: 'Patient/Caregiver attempted to cancel/change appointment',
      isActive: conditions.declinedVisitRefusal,
    },
    {
      text: 'Patient In Home – on hospice',
      isActive: conditions.declinedVisitRefusal,
    },
    {
      text: 'Patient lacks capacity – Needs Caregiver present',
      isActive: conditions.declinedVisitRefusal,
    },
    { text: 'Other', isActive: conditions.defaultRefusal },
  ]),
}

export default values
