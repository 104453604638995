import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createSelector } from 'reselect'
import {
  CONTACT_MANAGEMENT_CASE_MANAGER_RECORD,
  CONTACT_MANAGEMENT_PHYSICIAN_RECORD,
} from '~/apps/contactManagement/router'
import DevXGrid from '~/components/DevXGrid'
import DevXTitleHeader from '~/components/DevXTitleHeader'
import PhoneLink from '~/components/PhoneLink'
import TypographyLink from '~/components/TypographyLink'
import EditRowActions from '~/components/rowActions/EditRowActions'
import ViewRowActions from '~/components/rowActions/ViewRowActions'
import { FieldValue, getFieldValues } from '~/data/fieldValues'
import { formatAddress, formatDateTime } from '~/utils/format'
import PropTypes from '~/utils/propTypes'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import { Stakeholder } from '../../data/common/shared'
import { getStakeholders, saveStakeholder } from '../../data/stakeholders'
import AddStakeholder from './AddStakeholder'
import DeleteStakeholder from './DeleteStakeholder'
import EditStakeholderRow from './EditStakeholderRow'

const styles = () => ({
  reportTypes: {
    width: '100%',
  },
})

const toArray = createSelector([stakeholders => stakeholders], stakeholders =>
  stakeholders.toArray()
)

const columnExtensions = [
  { columnName: 'phone', width: 150 },
  { columnName: 'fullAddress', width: 300 },
  { columnName: 'createdAt', width: 175 },
]

const defaultSorting = [{ columnName: 'name', direction: 'asc' }]

class Stakeholders extends React.PureComponent {
  state = {
    selectedReportTypes: [],
  }

  setMode = (mode, stakeholder) => {
    const reportTypes = stakeholder ? stakeholder.reportTypes : []

    this.setState({
      mode,
      selectedStakeholder: stakeholder,
      selectedReportTypes: reportTypes,
    })
  }
  addMode = () => this.setMode('add')
  editMode = stakeholder => this.setMode('edit', stakeholder)
  deleteMode = stakeholder => this.setMode('delete', stakeholder)
  clearMode = () => this.setMode(null)

  onSetReportTypes = reportTypes => {
    this.setState({ selectedReportTypes: reportTypes })
  }

  onSaveReportTypes = () => {
    const { selectedStakeholder, selectedReportTypes } = this.state
    const { saveStakeholder } = this.props

    saveStakeholder(selectedStakeholder.id, selectedReportTypes)
    this.clearMode()
  }

  editDisplayComponent = ({ row }) => {
    const { mode, selectedStakeholder } = this.state
    const { classes, reportTypes: reportTypeItems } = this.props

    return mode === 'edit' && selectedStakeholder.id === row.id ? (
      <EditStakeholderRow
        className={classes.reportTypes}
        items={reportTypeItems}
        onChange={this.onSetReportTypes}
        value={this.state.selectedReportTypes}
      />
    ) : (
      row.reportTypes.join(', ')
    )
  }

  columns = [
    {
      name: 'name',
      title: 'Contact Name',
      getCellValue: ({ type, name, contactId }) => (
        <TypographyLink
          action="open"
          route={
            {
              Physician: CONTACT_MANAGEMENT_PHYSICIAN_RECORD,
              Non_Physician_Stakeholder: CONTACT_MANAGEMENT_CASE_MANAGER_RECORD,
            }[type]
          }
          params={{
            [{
              Physician: 'physicianId',
              Non_Physician_Stakeholder: 'caseManagerId',
            }[type]]: contactId,
          }}
        >
          {name}
        </TypographyLink>
      ),
    },
    {
      name: 'specialty',
      title: 'Specialty',
    },
    {
      name: 'phone',
      title: 'Business Phone',
      displayComponent: PhoneLink,
    },
    {
      name: 'reportTypes',
      title: 'Report Types',
      displayComponent: this.editDisplayComponent,
    },
    {
      name: 'fullAddress',
      title: 'Primary Address',
      getCellValue: formatAddress,
    },
    {
      name: 'createdBy',
      title: 'Created By',
    },
    {
      name: 'createdAt',
      title: 'Created At',
      getCellValue: ({ createdAt }) => formatDateTime(createdAt),
    },
  ]

  renderActions = ({ row }) => {
    const { mode, selectedStakeholder, selectedReportTypes } = this.state

    return mode === 'edit' && selectedStakeholder.id === row.id ? (
      <EditRowActions
        disabled={selectedReportTypes.length === 0}
        onSave={this.onSaveReportTypes}
        onCancel={this.clearMode}
      />
    ) : (
      <ViewRowActions
        row={row}
        onEdit={this.editMode}
        onDelete={this.deleteMode}
      />
    )
  }

  render() {
    const { mode, selectedStakeholder } = this.state
    const { stakeholders, patientId, hideTitle } = this.props

    return (
      <React.Fragment>
        <DevXTitleHeader
          action={this.addMode}
          buttonText="Add Stakeholder"
          hideTitle={hideTitle}
          title="Stakeholders"
        />

        <Paper>
          <DevXGrid
            rows={toArray(stakeholders)}
            columns={this.columns}
            columnExtensions={columnExtensions}
            sortable
            rowActions={this.renderActions}
            rowActionsCount={2}
            defaultSorting={defaultSorting}
          />
        </Paper>

        <AddStakeholder
          open={mode === 'add'}
          patientId={patientId}
          onClose={this.clearMode}
        />

        <DeleteStakeholder
          open={mode === 'delete'}
          row={selectedStakeholder}
          onClose={this.clearMode}
        />
      </React.Fragment>
    )
  }
}

Stakeholders.propTypes = {
  classes: PropTypes.object.isRequired,
  patientId: PropTypes.string.isRequired,
  hideTitle: PropTypes.bool,
  stakeholders: PropTypes.mapOf(Stakeholder).isRequired,
  reportTypes: PropTypes.mapOf(FieldValue),
  saveStakeholder: PropTypes.func.isRequired,
}

export default compose(
  connect(
    state => ({
      stakeholders: getStakeholders(state),
      reportTypes: getFieldValues('stakeholder_report_type')(state),
    }),
    { saveStakeholder: saveStakeholder.requested }
  ),
  withStyles(styles)
)(Stakeholders)
