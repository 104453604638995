import React from 'react'
import { useSelector } from 'react-redux'
import Columns, { Column } from '~/apps/patientRecord/components/Columns'
import { Hidden, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getPatientCareTeamDetails } from '../data/careTeams'
import CTMembers from './CTMembers'

const useStyles = makeStyles(({ palette, spacing }) => ({
  sectionHeader: {
    color: palette.primary.main,
    marginBottom: spacing(2),
  },
}))

const PatientCareTeam = () => {
  const classes = useStyles()
  const careTeamDetails = useSelector(getPatientCareTeamDetails)

  const members = careTeamDetails && careTeamDetails.members

  return (
    <React.Fragment>
      {careTeamDetails ? (
        <React.Fragment>
          <Typography variant="h5" className={classes.sectionHeader}>
            {careTeamDetails.label}
          </Typography>
          <Columns>
            <Hidden smUp>
              <Column>
                <div>
                  <CTMembers
                    members={members}
                    careTeamType={careTeamDetails.type}
                  />
                </div>
              </Column>
            </Hidden>
            <Hidden xsDown>
              <Column>
                <div>
                  <CTMembers
                    members={members.slice(0, Math.ceil(members.length / 2))}
                    careTeamType={careTeamDetails.type}
                  />
                </div>
              </Column>
              <Column>
                <div>
                  <CTMembers
                    members={members.slice(Math.ceil(members.length / 2))}
                    careTeamType={careTeamDetails.type}
                  />
                </div>
              </Column>
            </Hidden>
          </Columns>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  )
}

export default PatientCareTeam
